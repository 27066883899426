<template>
  <v-main>
    <v-container style="max-width: 640px;">
      <h3 class="py-4" ref="top">神様のサイコロ - お問い合わせ</h3>
      <v-form v-model="valid">
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="name" label="お名前" :rules="nameRules" counter="30"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="email" label="メールアドレス" :rules="emailRules" counter="255"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea v-model="content" label="お問い合わせ内容" rows="10" :rules="contentRules" counter="1000"></v-textarea>
          </v-col>
        </v-row>
        <br>
        <v-row>
          <v-col cols="12" align="center">
            <v-btn @click="submit">送信</v-btn>
          </v-col>
        </v-row>
      </v-form>
      <br>
      <br>
      <br>
      <br>
      <br>
    </v-container>
  </v-main>
</template>

<script>
  import Vue from 'vue'
  import { VueReCaptcha } from 'vue-recaptcha-v3'
  import mixin from '@/util/mixin'

  // For more options see below
  Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY })


  export default {
    mixins: [mixin],
    data() {
      return {
        valid: false,
        name: '',
        nameRules: [
          v => !!v || 'お名前を入力してください。',
          v => v.length <= 30 || 'お名前は30文字以下で入力してください。',
        ],
        email: '',
        emailRules: [
          v => !!v || 'メールアドレスを入力してください。',
          v => v.length <= 255 || 'メールアドレスは255文字以下で入力してください。',
          v => /.+@.+/.test(v) || 'メールアドレスの形式で入力してください。',
        ],
        content: '',
        contentRules: [
          v => !!v || 'お問い合わせ内容を入力してください。',
          v => v.length <= 1000 || 'お問い合わせ内容は1000文字以下で入力してください。',
        ]
      }
    },
    methods: {
      async recaptcha() {
        // (optional) Wait until recaptcha has been loaded.
        await this.$recaptchaLoaded()

        // Execute reCAPTCHA with action "send".
        const token = await this.$recaptcha('send')

        // Do stuff with the received token.
        console.log(`token: ${token}`)
      },
      submit() {
        if (!this.valid) return
        this.recaptcha()
        this.db = this.$fb.firestore
        this.$fb.addDoc(this.$fb.collection(this.db, 'inquiry'), {
          name: this.name,
          email: this.email,
          content: this.content,
          created_at: new Date(),
        })
        .then(() => {
          this.$router.push({ path: '/inquiry-complete' })
        })
        .catch(error => {
          console.log(error)
        })
      }
    },
  }
</script>

<style scoped>
</style>