<template>
  <v-app-bar app flat color="transparent">
    <v-container fill-height class="d-flex" style="max-width: 1140px;">
      <v-toolbar-title>
        <a href="#" class="black--text text-decoration-none" @click.prevent="push">
          <v-icon class="mr-1">mdi-dice-6-outline</v-icon>
          神様のサイコロ
        </a>
      </v-toolbar-title>
    </v-container>
  </v-app-bar>
</template>

<script>
  export default {
    components: {
    },
    methods: {
      push () {
        if (this.$route.fullPath !== '/') {
          this.$router.push({ path: '/' })
        }
      }
    }
}
</script>
