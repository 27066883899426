<template>
  <v-main class="background">
    <v-container fluid>
      <v-row justify="center" align="center">
        <v-col cols="12">
          <p>月間ランキング<span class="ml-2 mr-2">-</span>2020年4月</p>
          <Ranking/>
          <br/>
          <Ads2/>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
  import Ranking from '@/components/Ranking.vue'
  import Ads2 from '@/components/Ads2.vue'
  
  export default {
    components: {
      Ranking,
      Ads2,
    },
    mounted () {
      this.$vuetify.goTo(0)
    },
  }
</script>
