var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',{staticClass:"background"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('p',[_vm._v("予想成績")]),_c('v-card',[_c('v-card-title',[_vm._v(" チップランキング - 2020年5月31日(金) ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":"","disable-sort":"","headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-5"},[_vm._v(_vm._s(item.category.icon))]),_vm._v(_vm._s(item.category.text)+" ")]}},{key:"item.prevRatio",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.prevRatio.color}},[_vm._v(_vm._s(item.prevRatio.icon))])]}},{key:"item.hittingRatio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.hittingRatio.hittingCount)+" / "+_vm._s(item.hittingRatio.predictionCount)+" ("+_vm._s(item.hittingRatio.value)+") ")]}}],null,true)})],1)],1),_c('br'),_c('Ads1'),_c('br'),_c('v-card',[_c('v-card-title',[_vm._v(" デイリーランキング - 2020年5月31日(金) ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":"","disable-sort":"","headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-5"},[_vm._v(_vm._s(item.category.icon))]),_vm._v(_vm._s(item.category.text)+" ")]}},{key:"item.prevRatio",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.prevRatio.color}},[_vm._v(_vm._s(item.prevRatio.icon))])]}},{key:"item.hittingRatio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.hittingRatio.hittingCount)+" / "+_vm._s(item.hittingRatio.predictionCount)+" ("+_vm._s(item.hittingRatio.value)+") ")]}}],null,true)})],1)],1),_c('br'),_c('v-card',[_c('v-card-title',[_vm._v(" 週間ランキング - 2020年5月第3週 ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":"","disable-sort":"","headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-5"},[_vm._v(_vm._s(item.category.icon))]),_vm._v(_vm._s(item.category.text)+" ")]}},{key:"item.prevRatio",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.prevRatio.color}},[_vm._v(_vm._s(item.prevRatio.icon))])]}},{key:"item.hittingRatio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.hittingRatio.hittingCount)+" / "+_vm._s(item.hittingRatio.predictionCount)+" ("+_vm._s(item.hittingRatio.value)+") ")]}}],null,true)})],1)],1),_c('br'),_c('v-card',[_c('v-card-title',[_vm._v(" 月間ランキング - 2020年4月 ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":"","disable-sort":"","headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-5"},[_vm._v(_vm._s(item.category.icon))]),_vm._v(_vm._s(item.category.text)+" ")]}},{key:"item.prevRatio",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.prevRatio.color}},[_vm._v(_vm._s(item.prevRatio.icon))])]}},{key:"item.hittingRatio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.hittingRatio.hittingCount)+" / "+_vm._s(item.hittingRatio.predictionCount)+" ("+_vm._s(item.hittingRatio.value)+") ")]}}],null,true)})],1)],1),_c('br'),_c('v-card',[_c('v-card-title',[_vm._v(" 年間ランキング - 2019年 ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":"","disable-sort":"","headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-5"},[_vm._v(_vm._s(item.category.icon))]),_vm._v(_vm._s(item.category.text)+" ")]}},{key:"item.prevRatio",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.prevRatio.color}},[_vm._v(_vm._s(item.prevRatio.icon))])]}},{key:"item.hittingRatio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.hittingRatio.hittingCount)+" / "+_vm._s(item.hittingRatio.predictionCount)+" ("+_vm._s(item.hittingRatio.value)+") ")]}}],null,true)})],1)],1),_c('br'),_c('Ads2')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }