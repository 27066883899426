<template>
  <v-main class="background">
    <v-container fluid>
      <v-row justify="center" align="center">
        <v-col cols="12">
          <p>検索結果</p>
          <template v-if="$route.params.id">
            <Prediction 
              :prediction-id-promise="predictionIdPromise" 
              :now="now"
            />
            <br/>
            <Ads1/>
            <br/>
          </template>
          <OtherPredictions 
            :is-search="true" 
            :prediction-id-promise="predictionIdPromise" 
            :now="now"
          />
          <br/>
          <Ads2/>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
  import mixin from '@/util/mixin'
  import Prediction from '@/components/Prediction.vue'
  import OtherPredictions from '@/components/OtherPredictions.vue'
  import Ads1 from '@/components/Ads1.vue'
  import Ads2 from '@/components/Ads2.vue'

  export default {
    mixins: [mixin],
    components: {
      Prediction,
      OtherPredictions,
      Ads1,
      Ads2,
    },
    data: () => ({
      now: new Date(),
      db: null,
      predictionIdPromise: null,
    }),
    created () {
      this.db = this.$fb.firestore
      this.predictionIdPromise = this.getPredictionIdPromise()
    },
    mounted () {
      this.$vuetify.goTo(0)
    },
    methods: {
      getPredictionIdPromise () {
        let searchResults = []
        const eoa = this.$store.state.eth.eoa
        let predictionId = this.$route.params.id
        if (predictionId) return Promise.resolve(predictionId)
        const predictionRef = this.$fb.collection(this.db, 'prediction').withConverter(this.predictionConverter)
        const args = []
        for (const element of Object.keys(this.bigram(this.$route.query.q))) {
          args.push(this.$fb.where(`index.${element}`, '==', true))
        }
        args.push(this.$fb.where('is_invalid', '==', false))
        args.push(this.$fb.limit(1000))
        return this.$fb.getDocs(this.$fb.query(predictionRef, ...args))
          .then(predictionSnap => {
            predictionSnap.forEach(predictionDoc => {
              searchResults.push(predictionDoc.id)
            })
            if (searchResults.length == 0) {
              return Promise.reject(new Error('検索条件に一致する予想が見つかりませんでした。'))
            } else {
              const index = Math.floor(Math.random() * searchResults.length)
              return Promise.resolve(searchResults[index])
            }
          }).catch(error => {
            return Promise.reject(error)
          })
      },
    },
  }
</script>
