<template>
  <v-main class="background">
    <v-container fluid>
      <v-row justify="center" align="center">
        <v-col cols="12">
          <p>マイ予想</p>
          <Prediction 
            :prediction-id-promise="predictionIdPromise" 
            :now="now"
          />
          <br/>
          <Ads1/>
          <br/>
          <p>結果が確定した予想（参加）</p>
          <OtherPredictions 
            :is-finalized="true" 
            :is-trader="true"
            :prediction-id-promise="predictionIdPromise" 
            :now="now"
          />
          <br/>
          <Ads1/>
          <br/>
          <p>結果が確定した予想（作成）</p>
          <OtherPredictions 
            :is-finalized="true" 
            :is-creator="true"
            :prediction-id-promise="predictionIdPromise" 
            :now="now"
          />
          <br/>
          <Ads1/>
          <br/>
          <p>結果が確定した予想（投票）</p>
          <OtherPredictions 
            :is-finalized="true" 
            :is-reporter="true"
            :prediction-id-promise="predictionIdPromise" 
            :now="now"
          />
          <br/>
          <Ads1/>
          <br/>
          <p>参加中の予想</p>
          <OtherPredictions 
            :is-finalized="false" 
            :is-trader="true"
            :prediction-id-promise="predictionIdPromise" 
            :now="now"
          />
          <br/>
          <Ads1/>
          <br/>
          <p>投票中の予想</p>
          <OtherPredictions 
            :is-finalized="false" 
            :is-reporter="true"
            :prediction-id-promise="predictionIdPromise" 
            :now="now"
          />
          <br/>
          <Ads1/>
          <br/>
          <p>再投票中の予想</p>
          <OtherPredictions 
            :is-finalized="false" 
            :is-second-reporter="true"
            :prediction-id-promise="predictionIdPromise" 
            :now="now"
          />
          <br/>
          <Ads1/>
          <br/>
          <p>作成した予想</p>
          <OtherPredictions 
            :is-finalized="false" 
            :is-creator="true" 
            :prediction-id-promise="predictionIdPromise" 
            :now="now"
          />
          <br/>
          <Ads2/>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
  import mixin from '@/util/mixin'
  import Prediction from '@/components/Prediction.vue'
  import OtherPredictions from '@/components/OtherPredictions.vue'
  import Ads1 from '@/components/Ads1.vue'
  import Ads2 from '@/components/Ads2.vue'

  export default {
    mixins: [mixin],
    components: {
      Prediction,
      OtherPredictions,
      Ads1,
      Ads2,
    },
    data: () => ({
      now: new Date(),
      db: null,
      predictionIdPromise: null,
    }),
    created () {
      this.db = this.$fb.firestore
      this.predictionIdPromise = this.getPreditionIdPromise()
    },
    mounted () {
      this.$vuetify.goTo(0)
    },
    methods: {
      getPreditionIdPromise () {
        const eoa = this.$route.params.userId ? this.$route.params.userId : this.$store.state.eth.eoa
        let predictionId = this.$route.params.id
        if (predictionId) return Promise.resolve(predictionId)
        const answerRef = this.$fb.collection(this.db, 'answer').withConverter(this.answerConverter)
        const answerPromise = this.$fb.getDocs(this.$fb.query(answerRef,
          this.$fb.where('user_id', '==', eoa),
          this.$fb.where('is_finalized', '==', true),
          this.$fb.orderBy('close_at', 'desc')))
        const reportRef = this.$fb.collection(this.db, 'report').withConverter(this.reportConverter)
        const reportPromise = this.$fb.getDocs(this.$fb.query(reportRef,
          this.$fb.where('reporter_id', '==', eoa),
          this.$fb.where('is_finalized', '==', true),
          this.$fb.orderBy('reporting_to', 'desc')))
        const predictionRef = this.$fb.collection(this.db, 'prediction').withConverter(this.predictionConverter)
        const predictionPromise = this.$fb.getDocs(this.$fb.query(predictionRef,
          this.$fb.where('creator_id', '==', eoa),
          this.$fb.where('is_finalized', '==', true),
          this.$fb.orderBy('finalized_at', 'desc')))
        const promiseArray = [
          answerPromise, 
          reportPromise, 
          predictionPromise
        ]
        return Promise.all(promiseArray).then(resultArray => {
          const answerSnap = resultArray[0]
          const reportSnap = resultArray[1]
          const predictionSnap = resultArray[2]
          let myPredictionIds = []
          answerSnap.forEach(answerDoc => {
            myPredictionIds.push(answerDoc.data().predictionId)
          })
          reportSnap.forEach(reportDoc => {
            myPredictionIds.push(reportDoc.data().predictionId)
          })
          predictionSnap.forEach(predictionDoc => {
            myPredictionIds.push(predictionDoc.id)
          })
          if (myPredictionIds.length == 0) {
            // return Promise.reject(new Error('予想が見つかりませんでした。'))
            return Promise.resolve()
          } else {
            const index = Math.floor(Math.random() * myPredictionIds.length)
            return Promise.resolve(myPredictionIds[index])
          }
        }).catch(error => {
          return Promise.reject(error)
        })
      },
    },
  }
</script>
