<template>
  <div>
    <v-snackbar color="success" v-model="snackbarSuccess">
      <v-list-item-title>
        <v-icon color="white" class="mr-2">mdi-check-circle-outline</v-icon>
        予想の作成に成功しました。
      </v-list-item-title>
    </v-snackbar>
    <v-snackbar color="error" v-model="snackbarError">
      <v-list-item-title>
        <v-icon color="white" class="mr-2">mdi-alert-circle-outline</v-icon>
        {{ errorMessage }}
      </v-list-item-title>
    </v-snackbar>
    <v-dialog
      v-model="dialog"
      width="820px"
      scrollable
      persistent
    >
      <v-card>
        <v-card-title class="primary white--text">
          {{ editPredictionTitle }} ({{ window + 1 }}/4)
        </v-card-title>
        <v-card-text style="height: 650px">
          <v-window v-model="window">
            <v-window-item>
              <v-form ref="createPredictionForm1">
                <v-container>
                  <v-row class="ma-2">
                    <v-col cols="12" class="pt-0 pb-0">
                      はじめに予想問題を入力してください。
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0 d-flex">
                      <v-icon class="pr-5">mdi-calendar</v-icon>
                      <v-select
                        v-model="categoryId"
                        item-text="label"
                        item-value="value"
                        :items="items"
                        label="カテゴリー"
                        :rules="categoryIdRules"
                      />
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0 d-flex">
                      <v-icon class="pr-5">mdi-label</v-icon>
                      <v-text-field
                        v-model="predictionTitle"
                        label="タイトル"
                        :rules="predictionTitleRules"
                      />
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0 d-flex">
                      <v-icon class="pr-5">mdi-label-variant</v-icon>
                      <quill-editor
                        v-model="predictionBody"
                        ref="quillEditor"
                        style="min-height: 400px;"
                        :options="editorOption"
                      ></quill-editor>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-window-item>
            <v-window-item>
              <v-form ref="createPredictionForm2">
                <v-container>
                  <v-row class="ma-2">
                    <v-col cols="12" class="pt-0 pb-0">
                      次に予想の選択肢を入力してください。（最大10個まで）
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0 d-flex" v-for="choice in choices" v-bind:key="choice.label">
                      <v-icon class="pr-5">{{ choice.icon }}</v-icon>
                      <v-text-field
                        v-model="choice.value"
                        :label="choice.label"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-window-item>
            <v-window-item>
              <v-form ref="createPredictionForm3">
                <v-container>
                  <v-row class="ma-2">
                    <v-col cols="12" class="pt-0 pb-0">
                      最後に予想の日程と報酬率を入力してください。
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="pt-0 pb-0 d-flex">
                      <v-icon class="pr-5">mdi-calendar-arrow-right</v-icon>
                      <v-dialog
                        v-model="openDateDialog"
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="openDate"
                            label="開催日"
                            readonly
                            v-on="on"
                            :rules="openDateRules"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="openDate" @input="openDateDialog = false; openTimeDialog = true"></v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="4" sm="4" md="2" lg="2" class="pt-0 pb-0 d-flex">
                      <v-dialog
                        v-model="openTimeDialog"
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="openTime"
                            label="開催時刻"
                            readonly
                            v-on="on"
                            :rules="openTimeRules"
                          ></v-text-field>
                        </template>
                        <v-time-picker 
                          v-model="openTime" 
                          format="24hr"
                          :allowed-minutes="allowedMinutes"
                          @click:hour="setOpenMinute"
                        ></v-time-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="pt-0 pb-0 d-flex">
                      <v-icon class="pr-5">mdi-calendar-arrow-left</v-icon>
                      <v-dialog
                        v-model="closeDateDialog"
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="closeDate"
                            label="締切日"
                            readonly
                            v-on="on"
                            :rules="closeDateRules"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="closeDate" @input="closeDateDialog = false; closeTimeDialog = true"></v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="4" sm="4" md="2" lg="2" class="pt-0 pb-0 d-flex">
                      <v-dialog
                        v-model="closeTimeDialog"
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="closeTime"
                            label="締切時刻"
                            readonly
                            v-on="on"
                            :rules="closeTimeRules"
                          ></v-text-field>
                        </template>
                        <v-time-picker 
                          v-model="closeTime" 
                          format="24hr"
                          :allowed-minutes="allowedMinutes"
                          @click:hour="setCloseMinute"
                        ></v-time-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="pt-0 pb-0 d-flex">
                      <v-icon class="pr-5">mdi-calendar-arrow-right</v-icon>
                      <v-dialog
                        v-model="reportingStartDateDialog"
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="reportingStartDate"
                            label="結果投票開始日"
                            readonly
                            v-on="on"
                            :rules="reportingStartDateRules"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="reportingStartDate" @input="reportingStartDateDialog = false; reportingStartTimeDialog = true"></v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="4" sm="4" md="2" lg="2" class="pt-0 pb-0 d-flex">
                      <v-dialog
                        v-model="reportingStartTimeDialog"
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="reportingStartTime"
                            label="開始時刻"
                            readonly
                            v-on="on"
                            :rules="reportingStartTimeRules"
                          ></v-text-field>
                        </template>
                        <v-time-picker 
                          v-model="reportingStartTime" 
                          format="24hr"
                          :allowed-minutes="allowedMinutes"
                          @click:hour="setReportingStartMinute"
                        ></v-time-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="pt-0 pb-0 d-flex">
                      <v-icon class="pr-5">mdi-calendar-arrow-left</v-icon>
                      <v-dialog
                        v-model="reportingEndDateDialog"
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="reportingEndDate"
                            label="結果投票終了日"
                            readonly
                            v-on="on"
                            :rules="reportingEndDateRules"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="reportingEndDate" @input="reportingEndDateDialog = false; reportingEndTimeDialog = true"></v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="4" sm="4" md="2" lg="2" class="pt-0 pb-0 d-flex">
                      <v-dialog
                        v-model="reportingEndTimeDialog"
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="reportingEndTime"
                            label="終了時刻"
                            readonly
                            v-on="on"
                            :rules="reportingEndTimeRules"
                          ></v-text-field>
                        </template>
                        <v-time-picker 
                          v-model="reportingEndTime" 
                          format="24hr"
                          :allowed-minutes="allowedMinutes"
                          @click:hour="setReportingEndMinute"
                        ></v-time-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6" class="pt-0 pb-0 d-flex">
                      <v-icon class="pr-5">mdi-bitcoin</v-icon>
                      <v-text-field
                        v-model="creatorRewardRate"
                        type="number"
                        label="出題者報酬率(%)"
                        :rules="creatorRewardRateRules"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6" class="pt-0 pb-0 d-flex">
                      <v-icon class="pr-5">mdi-bitcoin</v-icon>
                      <v-text-field
                        v-model="reporterRewardRate"
                        type="number"
                        label="結果投票報酬率(%)"
                        :rules="reporterRewardRateRules"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-window-item>
            <v-window-item>
              <v-form ref="createPredictionForm4">
                <v-container>
                  <v-row class="ma-2">
                    <v-col cols="12" class="pt-0 pb-0">
                      この内容で予想を作成します。よろしいですか？
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0 d-flex">
                      <v-card class="mt-4">
                        <v-card-title>
                          <v-icon class="pr-2">{{ categoryIcon }}</v-icon>
                          {{ predictionTitle }}
                        </v-card-title>
                        <v-card-subtitle>
                          <span v-html="predictionBody"></span>
                        </v-card-subtitle>
                        <v-card-text>
                          <v-container>
                            <v-row no-gutters align="center" class="mr-0">
                              <v-col cols="12">
                                開催期間：{{ openAt ? formatPeriod(openAt, closeAt) : '' }}
                              </v-col>
                              <v-col cols="12">
                                結果投票期間：{{ reportingFrom ? formatPeriod(reportingFrom, reportingTo) : '' }}
                              </v-col>
                              <v-col cols="6" lg="6" md="6" sm="6">
                                出題者報酬率：{{ creatorRewardRate }}%
                              </v-col>
                              <v-col cols="6" lg="6" md="6" sm="6">
                                結果投票報酬率：{{ reporterRewardRate }}%
                              </v-col>
                            </v-row>
                          </v-container>
                          <v-data-table
                            dense
                            hide-default-header
                            hide-default-footer
                            disable-sort
                            class="mt-5 mb-5"
                            :headers="headers"
                            :items="validChoices"
                          ></v-data-table>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-window-item>
          </v-window>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="secondary"
            @click="window = 0; $emit('close-dialog')"
          >キャンセル</v-btn>
          <template v-if="window > 0">
            <v-btn
              color="secondary"
              @click="window = window - 1"
            >戻る</v-btn>
          </template>
          <v-btn
            color="primary"
            @click="next"
          >{{ nextLabel }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
  .z-top {
    z-index: 100;
  }
</style>

<script>
  import mixin from '@/util/mixin'

  export default {
    mixins: [mixin],
    props: {
      predictionDocPromise: Promise,
      choiceSnapPromise: Promise,
      dialog: Boolean,
    },
    watch: {
      predictionDocPromise () {
        if (this.predictionDocPromise) {
          this.init()
        }
      }
    },
    created () {
      this.items = []
      this.$fb.getDocs(this.$fb.collection(this.$fb.firestore, 'category'))
      .then(categorySnap => {
        categorySnap.forEach(categoryDoc => {
          if (categoryDoc.data().label !== '総合') {
            let data = categoryDoc.data()
            data.value = categoryDoc.id
            this.items.push(data)
          }
        })
      })
      if (this.predictionDocPromise) {
        this.init()
      } else {
        this.creatorId = this.$store.state.eth.eoa
      }
    },    
    data: () => ({
      editorOption: {
        theme: 'snow',
      },
      editPredictionTitle: '新しい予想',
      window: 0,
      predictionId: null,
      categoryId: null,
      categoryIcon: null,
      categoryIdRules: [
        v => !!v || 'カテゴリーは必須です。',
      ],
      items: null,
      predictionTitle: null,
      predictionTitleRules: [
        v => !!v || 'タイトルは必須です。',
      ],
      predictionBody: null,
      predictionBodyRules: [
        v => !!v || '説明は必須です。',
      ],
      choices: [
        { icon: 'mdi-numeric-1-circle-outline', value: null, label: '選択肢１', },
        { icon: 'mdi-numeric-2-circle-outline', value: null, label: '選択肢２', },
        { icon: 'mdi-numeric-3-circle-outline', value: null, label: '選択肢３', },
        { icon: 'mdi-numeric-4-circle-outline', value: null, label: '選択肢４', },
        { icon: 'mdi-numeric-5-circle-outline', value: null, label: '選択肢５', },
        { icon: 'mdi-numeric-6-circle-outline', value: null, label: '選択肢６', },
        { icon: 'mdi-numeric-7-circle-outline', value: null, label: '選択肢７', },
        { icon: 'mdi-numeric-8-circle-outline', value: null, label: '選択肢８', },
        { icon: 'mdi-numeric-9-circle-outline', value: null, label: '選択肢９', },
        { icon: 'mdi-numeric-10-circle-outline', value: null, label: '選択肢１０', },
      ],
      extended: false,
      openDateDialog: false,
      openDate: null,
      openDateRules: [
        v => !!v || '開催日は必須です。',
      ],
      openTimeDialog: false,
      openTime: null,
      openTimeRules: [
        v => !!v || '開催時刻は必須です。',
      ],
      closeDateDialog: false,
      closeDate: null,
      closeDateRules: [
        v => !!v || '締切日は必須です。',
      ],
      closeTimeDialog: false,
      closeTime: null,
      closeTimeRules: [
        v => !!v || '締切時刻は必須です。',
      ],
      reportingStartDateDialog: false,
      reportingStartDate: null,
      reportingStartDateRules: [
        v => !!v || '結果投票開始日は必須です。',
      ],
      reportingStartTimeDialog: false,
      reportingStartTime: null,
      reportingStartTimeRules: [
        v => !!v || '結果投票開始時刻は必須です。',
      ],
      reportingEndDateDialog: false,
      reportingEndDate: null,
      reportingEndDateRules: [
        v => !!v || '結果投票終了日は必須です。',
      ],
      reportingEndTimeDialog: false,
      reportingEndTime: null,
      reportingEndTimeRules: [
        v => !!v || '結果投票終了時刻は必須です。',
      ],
      allowedMinutes: [0],
      creatorRewardRate: null,
      creatorRewardRateRules: [
        v => !!v || '出題者報酬率は必須です。',
        v => (!v || parseFloat(v) <= 10) || '出題者報酬は10%以内に設定してください。',
      ],
      reporterRewardRate: null,
      reporterRewardRateRules: [
        v => !!v || '結果投票報酬率は必須です。',
        v => (!v || parseFloat(v) <= 30) || '結果投票報酬率は30%以内に設定してください。',
      ],
      snackbarSuccess: false,
      snackbarError: false,
      errorMessage: null,
      openAt: null,
      closeAt: null,
      reportingFrom: null,
      reportingTo: null,
      creatorId: null,
      headers: [{text: '選択肢', value: 'choice' }]
    }),
    computed : {
      nextLabel () {
        return this.window == 3 ? '作成' : '次へ'
      },
      validChoices () {
        const validChoices = []
        let no = 1
        this.choices.forEach(choice => {
          if (choice.value) {
            const json = { 'choice': String(no) + '. ' + choice.value }
            validChoices.push(json)
            no = no + 1
          }
        })
        return validChoices
      },
    },
    methods: {
      init () {
        this.predictionDocPromise
        .then(predictionDoc => {
          const prediction = predictionDoc.data()
          if (!prediction) return
          this.editPredictionTitle = '予想を編集'
          this.predictionId = predictionDoc.id
          this.categoryId = prediction.categoryId
          this.predictionTitle = prediction.predictionTitle
          this.predictionBody = prediction.predictionBody
          this.openDate = this.formatDate(prediction.openAt)
          this.openTime = this.formatTime(prediction.openAt)
          this.closeDate = this.formatDate(prediction.closeAt)
          this.closeTime = this.formatTime(prediction.closeAt)
          this.reportingStartDate = this.formatDate(prediction.reportingFrom)
          this.reportingStartTime = this.formatTime(prediction.reportingFrom)
          this.reportingEndDate = this.formatDate(prediction.reportingTo)
          this.reportingEndTime = this.formatTime(prediction.reportingTo)
          this.creatorRewardRate = prediction.creatorRewardRate
          this.reporterRewardRate = prediction.reporterRewardRate
          this.creatorId = prediction.creatorId
          this.choiceSnapPromise.then(choiceSnap => {
            let index = 0
            choiceSnap.forEach(choiceDoc => {
              this.choices[index].value = choiceDoc.data().choice
              index = index + 1
            })
          })
        })
        .catch(() => {}) // 予想がないだけなので、何もしない。
      },
      setOpenMinute (hour) {
        this.openTime = hour < 10 ? `0${hour}:00` : `${hour}:00`
        this.openTimeDialog = false
      },
      setCloseMinute (hour) {
        this.closeTime = hour < 10 ? `0${hour}:00` : `${hour}:00`
        this.closeTimeDialog = false
      },
      setReportingStartMinute (hour) {
        this.reportingStartTime = hour < 10 ? `0${hour}:00` : `${hour}:00`
        this.reportingStartTimeDialog = false
      },
      setReportingEndMinute (hour) {
        this.reportingEndTime = hour < 10 ? `0${hour}:00` : `${hour}:00`
        this.reportingEndTimeDialog = false
      },
      next () {
        if (this.window == 0) {
          if (this.$refs.createPredictionForm1.validate()) {
            this.$store.dispatch('registerCategory').then(() => {
              this.categoryIcon = this.$store.state.category[this.categoryId].icon
            })
            this.window = this.window + 1
          }
        } else if (this.window == 1) {
          if (this.$refs.createPredictionForm2.validate()) {
            if (this.validChoices.length < 2) {
              this.errorMessage = '選択肢は2つ以上設定してください。'
              this.snackbarError = true
              return
            }
            this.window = this.window + 1
          }
        } else if (this.window == 2) {
          if (this.$refs.createPredictionForm3.validate()) {
            const now = new Date()
            this.openAt = new Date(this.openDate + ' ' + this.openTime)
            this.closeAt = new Date(this.closeDate + ' ' + this.closeTime)
            this.reportingFrom = new Date(this.reportingStartDate + ' ' + this.reportingStartTime)
            this.reportingTo = new Date(this.reportingEndDate + ' ' + this.reportingEndTime)
            if (this.openAt - now < 1000 * 60 * 60 || this.openAt >= this.closeAt || this.closeAt > this.reportingFrom || this.reportingFrom >= this.reportingTo) {
              if (this.openAt - now < 1000 * 60 * 60) {
                this.errorMessage = '開催日時は1時間以上未来の日時を設定してください。'
              } else if (this.openAt >= this.closeAt) {
                this.errorMessage = '締切日時は開催日時より後に設定してください。'
              } else if (this.closeAt > this.reportingFrom) {
                this.errorMessage = '結果投票開始日時は締切日時以降に設定してください。'
              } else {
                this.errorMessage = '結果投票終了日時は結果投票開始日時より後に設定してください。'
              }
              this.snackbarError = true
              return
            }
            this.window = this.window + 1
          }
        } else {
          const db = this.$fb.firestore
          const batch = this.$fb.writeBatch(db)
          const self = this
          const deletePromise = new Promise(function (resolve, reject) {
            if (self.predictionId) {
              const predictionRef = self.$fb.collection(db, 'prediction')
              const deletePrediction = self.$fb.doc(db, 'prediction', self.predictionId)
              self.$fb.getDocs(self.$fb.collection(predictionRef, self.predictionId, 'choice'))
              .then(choiceSnap => {
                choiceSnap.forEach(choiceDoc => {
                  batch.delete(choiceDoc.ref)
                })
                batch.delete(deletePrediction)
                resolve()
              })
              .catch (error => {
                reject(error)
              })
            } else {
              resolve()
            }
          })
          deletePromise.then(() => {
            return self.$fb.addDoc(this.$fb.collection(db, 'prediction'), {
              creator_id: self.creatorId
            })
          }).then(newPrediction => {
            const now = new Date()
            batch.set(newPrediction, {
              created_at: now,
              updated_at: now,
              creator_id: self.creatorId,
              category_id: self.categoryId,
              prediction_title: self.predictionTitle,
              prediction_body: self.predictionBody,
              open_at: self.openAt,
              close_at: self.closeAt,
              reporting_from: self.reportingFrom,
              reporting_to: self.reportingTo,
              creator_reward_rate: self.creatorRewardRate,
              reporter_reward_rate: self.reporterRewardRate,
              is_published: false,
              answer_count: 0,
              comment_count: 0,
              is_finalized: false,
              is_invalid: false,
              index: this.bigram(self.predictionTitle)
            })
            let no = 1
            self.choices.forEach((choice) => {
              if (choice.value) {
                const predictionRef = self.$fb.collection(db, 'prediction')
                let newChoice = self.$fb.doc(predictionRef, newPrediction.id, 'choice', String(no))
                batch.set(newChoice, {
                  choice: choice.value,
                  answer_count: 0,
                  odds: 1.0,
                  report_count: 0,
                  second_report_count: 0,
                })
                no = no + 1
              }
            })
            batch.commit()
            .then(() => {
              self.window = 0
              self.$emit('close-dialog')
              self.snackbarSuccess = true
            })
            .catch(error => {
              console.log(error.message)
              self.errorMessage = '予想の作成に失敗しました。'
              self.snackbarDanger = true
            })
          })
        }
      },
    },
  }
</script>
