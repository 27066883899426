<template>
  <v-main>
    <v-container style="max-width: 640px;">
      <h3 class="py-4" ref="top">お問い合わせありがとうございました。</h3>
      <a href="#" @click.prevent="top">TOPに戻る</a>
    </v-container>
  </v-main>
</template>

<script>
  import mixin from '@/util/mixin'

  export default {
    mixins: [mixin],
    methods: {
      top() {
        this.$router.push({ path: '/' })
      },
    }
  }
</script>

<style scoped>
</style>