<template>
  <v-list dense>
    <v-list-item @click="push(`/profile/${userId}`)">
      <v-list-item-action>
        <v-icon>mdi-account</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>プロフィール</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item @click="push(`/profile/${creator.id}/predictions`)">
      <v-list-item-action>
        <v-icon>mdi-calendar-account</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>予想</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item @click="$emit('showReportDialog')">
      <v-list-item-action>
        <v-icon>mdi-flag-outline</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>報告</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
  import mixin from '@/util/mixin'

  export default {
    mixins: [mixin],
    props: {
      userId: String,
    },
    data: () => ({
    }),
    methods: {
      push (path) {
        this.$router.push({ path: path });
      },
    },
  }
</script>
