let state = {
    category: null,
    wallet: null,
    eth: {
        eoa: null,
        tokenBalance: null,
    },
    user: {
        avatarUrl: null,
        message: null,
        nickname: null,
    }
}
export default state