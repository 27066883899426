import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'
import { Ripple } from 'vuetify/lib/directives'

Vue.use(Vuetify, {
  directives: {
    Ripple
  }
});

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.red.darken4,
        secondary: colors.red.darken1,
        accent: colors.yellow.darken2,
        anchor: colors.blue.darken3,
        background: colors.grey.lighten3,
      },
      dark: {
        primary: colors.red.darken4,
        secondary: colors.red.darken1,
        accent: colors.yellow.darken2,
        anchor: colors.blue.darken3,
        background: colors.grey.darken3,
      },
    },
  },
});
