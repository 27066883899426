import Vue from 'vue'
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth'
import { getFirestore, collection, getDocs, doc, getDoc, query, where, orderBy, limit, writeBatch, addDoc, setDoc, deleteDoc, updateDoc, documentId } from "firebase/firestore"
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage'
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check"

// Your web app's Firebase configuration
let firebaseConfig = {
    apiKey: process.env.VUE_APP_API_KEY,
    authDomain: process.env.VUE_APP_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_PROJECT_ID,
    storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_APP_ID,
    measurementId: process.env.VUE_APP_MEASUREMENT_ID
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app)
const auth = getAuth(app)
const firestore = getFirestore(app)
const storage = getStorage(app)
if (process.env.NODE_ENV === 'development') {
    console.log('debug mode')
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = '1760c621-5d24-45c9-a91f-16074d233bad'
}
initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(process.env.VUE_APP_RECAPTCHA_SITE_KEY),

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true
})

const fb = {
    app,
    analytics,
    auth,
    signInWithEmailAndPassword,
    onAuthStateChanged,
    firestore,
    collection,
    getDocs,
    doc,
    getDoc,
    query,
    where,
    orderBy,
    limit,
    writeBatch,
    addDoc,
    setDoc,
    deleteDoc,
    updateDoc,
    documentId,
    storage,
    ref,
    uploadBytesResumable,
    getDownloadURL,
    deleteObject,
}
Vue.prototype.$fb = fb

const email = process.env.VUE_APP_EMAIL
const password = process.env.VUE_APP_PASSWORD
signInWithEmailAndPassword(auth, email, password)
.catch(function(error) {
    console.error(error)
})

export default fb
