var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',{staticClass:"background"},[_c('v-snackbar',{attrs:{"color":"success"},model:{value:(_vm.snackbarSuccess),callback:function ($$v) {_vm.snackbarSuccess=$$v},expression:"snackbarSuccess"}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-check-circle-outline")]),_vm._v(" "+_vm._s(_vm.snackbarSuccessText)+" ")],1)],1),_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.reportDialog),callback:function ($$v) {_vm.reportDialog=$$v},expression:"reportDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("ユーザーの報告")]),_c('v-card-text',[_vm._v("悪質なユーザーとして報告しますか？")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"indigo"},on:{"click":function($event){_vm.reportDialog=false}}},[_vm._v("キャンセル")]),_c('v-btn',{attrs:{"text":"","color":"indigo"},on:{"click":_vm.reportUser}},[_vm._v("報告")])],1)],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('p',[_vm._v("フォロワーリスト")]),_c('v-card',[_c('v-data-table',{attrs:{"hide-default-header":"","headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item.following",fn:function(ref){
var item = ref.item;
return [_c('v-list',[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-avatar',{attrs:{"size":"32px","item":""}},[_c('v-img',{attrs:{"src":item.avatarDownloadUrl,"alt":"avatar"}})],1)],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({},'a',attrs,false),on),[_vm._v(_vm._s(item.nickname))])]}}],null,true)},[_c('UserContextMenu',{attrs:{"user-id":item.id},on:{"showReportDialog":function($event){_vm.reportDialog=true}}})],1)],1),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.message))])],1)],1)],1)]}},{key:"item.isFollowing",fn:function(ref){
var item = ref.item;
return [(item.isFollowing)?[_c('v-btn',{on:{"click":function($event){return _vm.unfollow(item)}}},[_vm._v("フォロー解除")])]:[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.follow(item)}}},[_vm._v("フォロー")])]]}}],null,true)})],1),_c('br'),_c('Ads2')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }