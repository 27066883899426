<template>
  <div>
    <v-snackbar color="success" v-model="snackbarSuccess">
      <v-icon class="mr-2">mdi-check-circle-outline</v-icon>
      <v-list-item-title>40TIPを手に入れました！</v-list-item-title>
    </v-snackbar>
    <v-snackbar v-model="snackbarFail">
      フリーチップがもらえるのは1日1回までです。
      <v-btn text @click="snackbarFail = false">
        <v-icon>mdi-close-circle-outline</v-icon>
      </v-btn>
    </v-snackbar>
    <v-snackbar v-model="snackbar">
      {{ snackbarText }}
      <v-btn text @click="snackbar = false">
        <v-icon>mdi-close-circle-outline</v-icon>
      </v-btn>
    </v-snackbar>
    <v-navigation-drawer
      v-model="drawerRight"
      app
      clipped
      right
      width="246px"
    >
      <v-list dense>
        <template v-if="isLoggedIn">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <span class="text-h5 font-weight-bold">{{ eth.tokenBalance }}</span>&nbsp;&nbsp;TIP
              </v-list-item-title>
              <v-list-item-title>ランク：<span class="text-body-1 font-weight-bold">192</span>&nbsp;位 / 5,144人中</v-list-item-title>
              <v-list-item-title>的中率：<span class="text-body-1 font-weight-bold">35</span>&nbsp;% (28 / 81)</v-list-item-title>
              <v-list-item-subtitle>アカウント：<span class="text-caption">{{ eth.eoa }}</span></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="ml-3 mr-3"/>
          <v-list-item @click="getTip()">
            <v-list-item-action>
              <v-icon>mdi-arrow-down-bold-box</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>フリーチップをもらう</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="ml-3 mr-3"/>
          <v-list-item @click="notificationClicked">
            <v-list-item-action>
              <v-icon>mdi-inbox</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <v-badge :content="notificationCount" color="secondary" :value="notificationCount !== '0'">
                  お知らせ
                </v-badge>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="push('/watch-list')">
            <v-list-item-action>
              <v-icon>mdi-calendar-star</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>ウォッチリスト</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="push('/my-prediction')">
            <v-list-item-action>
              <v-icon>mdi-calendar-account</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>マイ予想</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="push('/prediction-score')">
            <v-list-item-action>
              <v-icon>mdi-chart-bar</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>予想成績</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="push('/following-list')">
            <v-list-item-action>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>フォローリスト</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="push('/follower-list')">
            <v-list-item-action>
              <v-icon>mdi-account-supervisor</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>フォロワーリスト</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="push('/profile')">
            <v-list-item-action>
              <v-icon>mdi-account-edit</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>プロフィール</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mx-3"/>
          <v-list-item @click="logout">
            <v-list-item-action>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>サインアウト</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-else>
          <v-list-item>
            <v-list-item-content>
              <p>MetaMaskがインストールされていないか、Ropstenテストネットに接続されていません。</p>
              <p>MetaMaskをインストールしてRopstenテストネットに接続するには、次のリンク先の記事を参考にしてください。</p>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <p>
                <a target="_blank" href="https://qiita.com/tmikada/items/cdc5a3871f655cb7b67d">
                  Ethereum testnet (Ropsten)のアカウントを作成して1 ETH取得する手順
                </a>
              </p>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <p>Ropstenテストネットに接続したら、MetaMaskの設定から「Connections」を選択し、本サイトのアドレス「dice-of-god.com」を入力して「Connect」ボタンをクリックしてください。</p>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      clipped-right
      color="primary"
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-spacer />
      <v-text-field
        v-model="searchCondition"
        flat
        solo-inverted
        hide-details
        prepend-inner-icon="mdi-magnify"
        label="検索"
        @keydown.enter="search"
      />
      <v-toolbar
        color="primary"
        flat
      >
        <v-spacer />
        <v-switch
          v-model="$vuetify.theme.dark"
          hide-details
          inset
          color="secondary"
        ></v-switch>
        <v-btn icon
          large
          @click.stop="drawerRight = !drawerRight">
          <template v-if="isLoggedIn">
            <v-avatar
              size="32px"
              item
            >
              <v-img
                :src="$store.state.user.avatarDownloadUrl ? $store.state.user.avatarDownloadUrl : defaultAvatar"
                alt="avatar"
              />
            </v-avatar>
          </template>
          <template v-else>
            <v-icon large>mdi-account-circle</v-icon>
          </template>
        </v-btn>
      </v-toolbar>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      app
      width="246px"
    >
      <v-list dense>
        <v-list-item>
          <v-img src="@/assets/eneos_200_200.gif" width="200"/>
        </v-list-item>
        <v-divider class="mt-2 ml-3 mr-3"/>
        <v-list-item @click.stop="left = !left">
          <v-list-item-action>
            <v-icon>mdi-calendar</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>カテゴリ - 総合</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="ml-3 mr-3"/>
        <v-list-item @click="push('/open-prediction')">
          <v-list-item-action>
            <v-icon>mdi-calendar-clock</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>開催中の予想</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="push('/reporting-prediction')">
          <v-list-item-action>
            <v-icon>mdi-calendar-import</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>結果投票中の予想</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="push('/finalized-prediction')">
          <v-list-item-action>
            <v-icon>mdi-calendar-check</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>結果確定済の予想</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="push('/all-prediction')">
          <v-list-item-action>
            <v-icon>mdi-calendar-multiple</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>すべての予想</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="ml-3 mr-3"/>
        <v-list-item @click="push('/tip-ranking')">
          <v-list-item-action>
            <v-icon>mdi-podium</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>チップランキング</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="push('/daily-ranking')">
          <v-list-item-action>
            <v-icon>mdi-view-agenda</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>デイリーランキング</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="push('/weekly-ranking')">
          <v-list-item-action>
            <v-icon>mdi-view-week</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>週間ランキング</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="push('/monthly-ranking')">
          <v-list-item-action>
            <v-icon>mdi-view-module</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>月間ランキング</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="push('/yearly-ranking')">
          <v-list-item-action>
            <v-icon>mdi-view-comfy</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>年間ランキング</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-navigation-drawer
      v-model="left"
      fixed
      temporary
      width="246px"
    >
      <v-list dense>
        <v-list-item>
          <v-img src="@/assets/otona_200_200.gif" width="200"/>
        </v-list-item>
        <v-divider class="mt-2 ml-3 mr-3"/>
        <v-list-item @click="push('/open-prediction')">
          <v-list-item-action>
            <v-icon>mdi-calendar</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>総合</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="push('/open-prediction')">
          <v-list-item-action>
            <v-icon>mdi-baseball-bat</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>野球</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="push('/open-prediction')">
          <v-list-item-action>
            <v-icon>mdi-soccer</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>サッカー</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="push('/open-prediction')">
          <v-list-item-action>
            <v-icon>mdi-tennis</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>その他スポーツ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="push('/open-prediction')">
          <v-list-item-action>
            <v-icon>mdi-emoticon-excited-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>エンタメ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="push('/open-prediction')">
          <v-list-item-action>
            <v-icon>mdi-chart-line</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>政治・経済</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="push('/open-prediction')">
          <v-list-item-action>
            <v-icon>mdi-shoe-print</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>その他</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <template v-if="isLoggedIn">
      <v-btn
        bottom
        color="accent"
        dark
        fab
        fixed
        right
        @click="dialog = true"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <EditPrediction :dialog="dialog" v-on:close-dialog="closeDialog"/>
    </template>
  </div>
</template>

<script>
  import Fortmatic from 'fortmatic'
  import WalletConnectProvider from '@walletconnect/web3-provider'
  import Web3 from 'web3'
  import EditPrediction from '@/components/EditPrediction'

  export default {
    components: {
      EditPrediction,
    },
    beforeCreate () {
      switch (this.$store.state.wallet) {
        case 'metamask':
          break
        case 'fortmatic': {
          const fm = new Fortmatic('pk_test_80A024EED2ABD1BA', 'ropsten')
          window.web3 = new Web3(fm.getProvider())
          break
        }
        case 'walletconnect': {
          const provider = new WalletConnectProvider({
            infuraId: '24d82d84987243c5ada6ab58d75acd4b'
          })
          provider.enable().then(() => {
            window.web3 = new Web3(provider)
          })
          break
        }
        default:
          throw new Error('Wallet not found.')
      }
    },
    created () {
      if (!this.isLoggedIn) this.logout()
      const user = this.$store.state.user
      if (user.avatar_url) {
        const imageRef = this.$fb.ref(this.$fb.storage, user.avatar_url)
        this.$fb.getDownloadURL(imageRef)
          .then(url => { 
            this.avatarUrl = url 
          })
      }
      // ログイン時と毎時10分にお知らせの新着件数を更新
      this.updateActivity()
      const waitTime = 60 * 1000 * ((29 - new Date().getMinutes()) % 60)
      setTimeout(this.startUpdateActivity, waitTime)
    },
    methods: {
      startUpdateActivity () {
        this.updateActivity()
        setInterval(this.updateActivity, 60 * 60 * 1000)
      },
      updateActivity () {
        this.$store.dispatch('updateBalance', this.$store.state.eth.eoa)
        const notificationRef = this.$fb.collection(this.$fb.firestore, 'notification').withConverter(this.notificationConverter)
        this.$fb.getDocs(this.$fb.query(notificationRef,
          this.$fb.where('user_id', '==', this.$store.state.eth.eoa),
          this.$fb.where('created_at', '>=', new Date(this.$store.state.user.last_view_at)),
          this.$fb.orderBy('created_at', 'desc'),
          this.$fb.limit(100)))
          .then(notificationSnap => {
            if (notificationSnap.size === 100) {
              this.notificationCount = '99+'
            } else {
              this.notificationCount = notificationSnap.size.toString()
            }
          })
      },
      closeDialog () {
        this.dialog = false
      },
      search () {
        if (this.searchCondition && this.searchCondition.length >= 2 ) {
          this.push(`/search-result?q=${encodeURI(this.searchCondition)}`)        
        } else {
          this.snackbarText = '2文字以上入力してください'
          this.snackbar = true
        }
      },
      push (path) {
        if (this.$route.fullPath !== path) {
          this.$router.push({ path: path });
        }
      },
      notificationClicked () {
        this.notificationCount = '0'
        this.push('/inbox')
      },
      getTip () {
        let store = this.$store
        let eth = store.state.eth
        let faucet = window.faucet
        faucet.setProvider(window.web3.currentProvider)
        faucet.autoGas = false
        faucet.defaults({
          from: eth.eoa,
          gasLimit: 750000,
        })
        faucet.deployed().then(faucetInstance => {
          faucetInstance.withdraw()
          .then(() => {
            this.snackbarSuccess = true
            store.dispatch('updateBalance', eth.eoa)
          })
          .catch(error => {
            console.error(error)
            if (error.reason == '引き出し間隔は20時間以上空けてください。') {
              this.snackbarFail = true
            }
          })
        })
      },
      logout () {
        this.$router.push({ path: '/' });
      }
    },
    data: () => ({
      dialog: false,
      drawer: null,
      drawerRight: null,
      left: false,
      defaultAvatar: require('@/assets/user_icon.png'),
      snackbarSuccess: false,
      snackbarFail: false,
      snackbar: false,
      snackbarText: null,
      searchCondition: null,
      notificationCount: '0',
    }),
    computed: {
      eth () {
        return this.$store.state.eth
      },
      isLoggedIn () {
        return this.$store.state.eth.eoa != null
      },
      tokenBalance () {
        const formatter = new Intl.NumberFormat('ja-JP');
        return formatter.format(this.$store.state.eth.tokenBalance)
      },
    },
}
</script>
