<template>
  <v-card>
    <v-data-table
      :headers="header"
      :items="items"
    >
      <template v-slot:[`item.title`]="{ item }">
        <v-icon class="mr-5">{{ item.title.category }}</v-icon>
        <a @click="push(item.title.url)">{{ item.title.text }}</a>
      </template>
      <template v-slot:[`item.result`]="{ item }">
        <template v-if="item.result.traderOrReporter">
          <template v-if="item.result.isCorrect">
            <v-chip color="red darken-4" class="white--text">{{ item.result.text }}</v-chip>
          </template>
          <template v-else>
            <v-chip color="blue darken-4" class="white--text">{{ item.result.text }}</v-chip>
          </template>
        </template>
        <template v-else>
          {{ item.result.text }}
        </template>
      </template>
      <template v-slot:[`item.reward`]="{ item }">
        <template v-if="item.result.flag">
          <strong class="red--text">{{ item.reward }}</strong>
        </template>
        <template v-else>
          {{ item.reward }}
        </template>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  import mixin from '@/util/mixin'

  export default {
    mixins: [mixin],
    props: {
      isWatchList: Boolean,
      isSearch: Boolean,
      isOpen: Boolean,
      isReporting: Boolean,
      isSecondReporting: Boolean,
      isFinalized: Boolean,
      isAll: Boolean,
      isTrader: Boolean,
      isCreator: Boolean,
      isReporter: Boolean,
      isSecondReporter: Boolean,
      predictionIdPromise: Promise,
      now: Date,
    },
    data: () => ({
      header: [],
      finalizedHeader: [
        { text: '予想タイトル', value: 'title' },
        { text: '結果', value: 'result' },
        { text: '使用チップ', value: 'answerAmount' },
        { text: '獲得チップ', value: 'reward' },
      ],
      notFinalizedHeader: [
        { text: '予想タイトル', value: 'title' },
        { text: '締め切りまで', value: 'remainingDatetime' },
      ],
      items: [],
      finalizedItems: [
        { title: {category: 'mdi-soccer', text: 'グランパス vs ジュビロの勝者は？'}, result: { traderOrReporter: true, isCorrect: true }, answerAmount: 100, reward: '+170', },
        { title: {category: 'mdi-chart-line', text: '明日の日経平均株価はどうなる？'}, result: { traderOrReporter: true, isCorrect: false }, answerAmount: 50, reward: '-', },
        { title: {category: 'mdi-chart-line', text: '次の東京都知事になるのは誰？'}, result: { traderOrReporter: true, isCorrect: true }, answerAmount: 50, reward: '+120', },
        { title: {category: 'mdi-emoticon-excited-outline', text: '次の月9ドラマのヒロインは誰？'}, result: { traderOrReporter: true, isCorrect: false }, answerAmount: 200, reward: '-', },
        { title: {category: 'mdi-emoticon-excited-outline', text: '今年のM-1グランプリで優勝するのは誰？'}, result: { traderOrReporter: true, isCorrect: true }, answerAmount: 300, reward: '+330', },
        { title: {category: 'mdi-chart-line', text: 'ビットコインは今週800万円を超える？'}, result: { traderOrReporter: true, isCorrect: false }, answerAmount: 300, reward: '-', },
        { title: {category: 'mdi-emoticon-excited-outline', text: '来週の大河「麒麟がくる」の視聴率は？', url: '#'}, result: { traderOrReporter: false, isCorrect: false }, answerAmount: '-', reward: '-', },
        { title: {category: 'mdi-emoticon-excited-outline', text: '乃木坂46の新曲「夜明けまで強がらなくていい」のオリコン初登場順位は？', url: '#'}, result: { traderOrReporter: false, isCorrect: false }, answerAmount: '-', reward: '-', },
        { title: {category: 'mdi-chart-line', text: '次の東京都知事になるのは誰？', url: '#'}, result: { traderOrReporter: false, isCorrect: false }, answerAmount: '-', reward: '-', },
        { title: {category: 'mdi-emoticon-excited-outline', text: 'サンドウィッチマンは好感度ランキングで連覇する？', url: '#'}, result: { traderOrReporter: false, isCorrect: false }, answerAmount: '-', reward: '-', },
        { title: {category: 'mdi-tennis', text: '第33回ダイキンオーキッドレディスゴルフトーナメントの渋野選手の結果は？', url: '#'}, result: { traderOrReporter: false, isCorrect: false }, answerAmount: '-', reward: '-', },
      ],
      notFinalizedItems: [
        { title: {category: 'mdi-soccer', text: 'グランパス vs ジュビロの勝者は？', url: '#'}, remainingDatetime: '残り15時間', },
        { title: {category: 'mdi-tennis', text: '錦織選手のウィンブルドン選手権の結果は？', url: '#'}, remainingDatetime: '残り2日間', },
        { title: {category: 'mdi-chart-line', text: '明日の日経平均株価はどうなる？', url: '#'}, remainingDatetime: '残り4時間', },
        { title: {category: 'mdi-emoticon-excited-outline', text: '次の月9ドラマのヒロインは誰？', url: '#'}, remainingDatetime: '残り1日間', },
        { title: {category: 'mdi-emoticon-excited-outline', text: '今年のM-1グランプリで優勝するのは誰？', url: '#'}, remainingDatetime: '残り3日間', },
        { title: {category: 'mdi-chart-line', text: 'ビットコインは今週800万円を超える？', url: '#'}, remainingDatetime: '残り5時間', },
        { title: {category: 'mdi-emoticon-excited-outline', text: '来週の大河「麒麟がくる」の視聴率は？', url: '#'}, remainingDatetime: '残り23時間', },
        { title: {category: 'mdi-emoticon-excited-outline', text: '乃木坂46の新曲「夜明けまで強がらなくていい」のオリコン初登場順位は？', url: '#'}, remainingDatetime: '残り2日間', },
        { title: {category: 'mdi-chart-line', text: '次の東京都知事になるのは誰？', url: '#'}, remainingDatetime: '残り6日間', },
        { title: {category: 'mdi-emoticon-excited-outline', text: 'サンドウィッチマンは好感度ランキングで連覇する？', url: '#'}, remainingDatetime: '残り2日間', },
        { title: {category: 'mdi-tennis', text: '第33回ダイキンオーキッドレディスゴルフトーナメントの渋野選手の結果は？', url: '#'}, remainingDatetime: '残り2日間', },
      ],
      routeName: null,
      db: null,
      eth: null
    }),
    created () {
      this.routeName = this.$route.name == 'home' ? 'open-prediction' : this.$route.name.replace('/id', '').replace('userId', this.$route.params.userId)
      this.db = this.$fb.firestore
      this.eth = {...this.$store.state.eth}
      if (this.$route.params.userId) {
        this.eth.eoa = this.$route.params.userId
      }
      if (this.isFinalized) {
        this.header = this.finalizedHeader
        this.items = this.finalizedItems
      } else {
        this.header = this.notFinalizedHeader
        this.items = this.notFinalizedItems
      }
      this.init()
    },
    methods: {
      init () {
        if (this.isTrader) {
          if (this.isFinalized) {
            this.getTraderFinalizedPredictions()
          } else {
            this.getTraderPredictions()
          }
        } else if (this.isCreator) {
          if (this.isFinalized) {
            this.getCreatorFinalizedPredictions()
          } else {
            this.getCreatorPredictions()
          }
        } else if (this.isReporter) {
          if (this.isFinalized) {
            this.getReporterFinalizedPredictions()
          } else {
            this.getReporterPredictions()
          }
        } else if (this.isSecondReporter) {
          this.getSecondReporterPredictions()
        } else {
          if (this.isWatchList) {
            this.getWatchListPredictions()
          } else if (this.isSearch) {
            this.getSearchPredictions()
          } else if (this.isOpen) {
            this.getOpenPredictions()
          } else if (this.isReporting) {
            this.getReportingPredictions()
          } else if (this.isSecondReporting) {
            this.getSecondReportingPredictions()
          } else if (this.isFinalized) {
            this.getFinalizedPredictions()
          } else if (this.isAll) {
            this.getAllPredictions()
          }
        }
      },
      initNotFinalizedItems (predictions) {
        this.items = []
        this.$store.dispatch('registerCategory').then(category => {
          for (const prediction of predictions) {
            let remainingTime = '-'
            if (prediction.closeAt >= this.now) {
              remainingTime = '残り' + this.getRemaining(prediction.closeAt)
            } else if (prediction.reportingFrom < this.now && this.now <= prediction.reportingTo) {
              remainingTime = '残り' + this.getRemaining(prediction.reportingTo)
            } else if (prediction.secondReportingFrom && prediction.secondReportingFrom < this.now && this.now <= prediction.secondReportingTo) {
              remainingTime = '残り' + this.getRemaining(prediction.secondReportingTo)
            }
            let item = {
              title: {
                category: category[prediction.categoryId].icon,
                text: prediction.predictionTitle,
                url: `/${this.routeName}/${prediction.id}`
              },
              remainingDatetime: remainingTime
            }
            this.items.push(item)
          }

        })
      },
      initFinalizedItems (predictionId, predictionSnap, results) {
        const predictions = []
        predictionSnap.forEach(predictionDoc => {
          let prediction = predictionDoc.data()
          if (prediction.id != predictionId) {
            if (this.isTrader || this.isCreator || this.isReporter) {
              if (results.has(prediction.id)) {
                predictions.push(prediction)
              }
            } else {
              predictions.push(prediction)
            }
          }
        })
        this.items = []
        predictions.forEach(prediction => {
          this.$store.dispatch('registerCategory').then(category => {
            let result = null
            if (results.has(prediction.id)) {
              result = results.get(prediction.id)
            } else {
              result = {
                traderOrReporter: false,
                isCorrect: false,
                answerAmount: '-',
                rewardAmount: '-',
              }
            }
            const item = {
              title: {
                category: category[prediction.categoryId].icon,
                text: prediction.predictionTitle,
                url: `/${this.routeName}/${prediction.id}`
              },
              result: {
                traderOrReporter: result.traderOrReporter,
                isCorrect: result.isCorrect,
                text: result.traderOrReporter ? result.isCorrect ? '当たり' : 'はずれ' : '-',
              },
              answerAmount: result.answerAmount,
              reward: result.rewardAmount,
            }
            this.items.push(item)
          })
        })
      },
      getWatchListPredictions () {
        let watchListPredictionIds = []
        const watchListRef = this.$fb.collection(this.db, 'watch_list').withConverter(this.watchListConverter)
        this.$fb.getDocs(this.$fb.query(watchListRef,
          this.$fb.where('user_id', '==', this.eth.eoa)))
          .then(watchListSnap => {
            watchListSnap.forEach(watchListDoc => {
              let predictionId = watchListDoc.data().predictionId
              watchListPredictionIds.push(predictionId)
            })
            if (watchListPredictionIds.length === 0) {
              this.items = []
              return
            }
            const predictionRef = this.$fb.collection(this.db, 'prediction').withConverter(this.predictionConverter)
            const args = []
            args.push(this.$fb.where(this.$fb.documentId(), 'in', watchListPredictionIds))
            args.push(this.$fb.limit(1000))
            const predictionPromise = this.$fb.getDocs(this.$fb.query(predictionRef, ...args))
            const promiseArray = [
              this.predictionIdPromise,
              predictionPromise
            ]
            Promise.all(promiseArray).then(resultArray => {
              const predictionId = resultArray[0]
              const predictionSnap = resultArray[1]
              const predictions = []
              predictionSnap.forEach(predictionDoc => {
                let prediction = predictionDoc.data()
                if (prediction.id != predictionId) {
                  predictions.push(prediction)
                }
              })
              predictions.sort((a, b) => {
                return b.closeAt.getTime() - a.closeAt.getTime()
              })
              this.initNotFinalizedItems(predictions)
            }).catch(error => {
              console.error(error)
              this.items = []
            })
          })
      },
      getSearchPredictions () {
        const predictionRef = this.$fb.collection(this.db, 'prediction').withConverter(this.predictionConverter)
        const args = []
        for (const element of Object.keys(this.bigram(this.$route.query.q))) {
          args.push(this.$fb.where(`index.${element}`, '==', true))
        }
        args.push(this.$fb.where('is_invalid', '==', false))
        args.push(this.$fb.limit(1000))
        const predictionPromise = this.$fb.getDocs(this.$fb.query(predictionRef, ...args))
        const promiseArray = [
          this.predictionIdPromise,
          predictionPromise
        ]
        Promise.all(promiseArray).then(resultArray => {
          const predictionId = resultArray[0]
          const predictionSnap = resultArray[1]
          const predictions = []
          predictionSnap.forEach(predictionDoc => {
            let prediction = predictionDoc.data()
            if (!this.$route.params.id || prediction.id != predictionId) {
              predictions.push(prediction)
            }
          })
          predictions.sort((a, b) => {
            return b.closeAt.getTime() - a.closeAt.getTime()
          })
          this.initNotFinalizedItems(predictions)
        }).catch(error => {
          console.error(error)
          this.items = []
        })
      },
      getOpenPredictions () {
        const answerRef = this.$fb.collection(this.db, 'answer').withConverter(this.answerConverter)
        const answerPromise = this.$fb.getDocs(this.$fb.query(answerRef,
          this.$fb.where('user_id', '==', this.eth.eoa),
          this.$fb.where('is_finalized', '==', false)))
        const predictionRef = this.$fb.collection(this.db, 'prediction').withConverter(this.predictionConverter)
        const predictionPromise = this.$fb.getDocs(this.$fb.query(predictionRef,
          this.$fb.where('close_at', '>=', this.now),
          this.$fb.where('is_published', '==', true),
          this.$fb.where('is_invalid', '==', false),
          this.$fb.where('is_finalized', '==', false),
          this.$fb.orderBy('close_at'),
          this.$fb.limit(1000)))
        const promiseArray = [
          this.predictionIdPromise,
          answerPromise,
          predictionPromise
        ]
        Promise.all(promiseArray).then(resultArray => {
          const predictionId = resultArray[0]
          const answerSnap = resultArray[1]
          const predictionSnap = resultArray[2]
          const traderPredictionIds = []
          answerSnap.forEach(answerDoc => {
            let predictionId = answerDoc.data().predictionId
            traderPredictionIds.push(predictionId)
          })
          const predictions = []
          predictionSnap.forEach(predictionDoc => {
            let prediction = predictionDoc.data()
            if (prediction.openAt <= this.now && prediction.id != predictionId) {
              if (this.isTrader || this.isCreator || this.isReporter) {
                if (traderPredictionIds.indexOf(prediction.id) > -1) {
                  predictions.push(prediction)
                }
              } else {
                if (traderPredictionIds.indexOf(prediction.id) == -1) {
                  predictions.push(prediction)
                }
              }
            }
          })
          this.initNotFinalizedItems(predictions)
        }).catch(error => {
          console.error(error)
          this.items = []
        })
      },
      getReportingPredictions () {
        const answerRef = this.$fb.collection(this.db, 'answer').withConverter(this.answerConverter)
        const answerPromise = this.$fb.getDocs(this.$fb.query(answerRef,
          this.$fb.where('user_id', '==', this.eth.eoa),
          this.$fb.where('is_finalized', '==', false)))
        const reportRef = this.$fb.collection(this.db, 'report').withConverter(this.reportConverter)
        const reportPromise = this.$fb.getDocs(this.$fb.query(reportRef,
          this.$fb.where('report_type', '==', this.reportType.reporting),
          this.$fb.where('reporter_id', '==', this.eth.eoa),
          this.$fb.where('is_finalized', '==', false)))
        const predictionRef = this.$fb.collection(this.db, 'prediction').withConverter(this.predictionConverter)
        const predictionPromise = this.$fb.getDocs(this.$fb.query(predictionRef,
          this.$fb.where('reporting_to', '>=', this.now),
          this.$fb.where('is_published', '==', true),
          this.$fb.where('is_invalid', '==', false),
          this.$fb.where('is_finalized', '==', false),
          this.$fb.orderBy('reporting_to'),
          this.$fb.limit(1000)))
        const promiseArray = [
          this.predictionIdPromise,
          answerPromise,
          reportPromise,
          predictionPromise,
        ]
        Promise.all(promiseArray).then(resultArray => {
          const predictionId = resultArray[0]
          const answerSnap = resultArray[1]
          const reportSnap = resultArray[2]
          const predictionSnap = resultArray[3]
          const answerPredictionIds = []
          answerSnap.forEach(answerDoc => {
            let predictionId = answerDoc.data().predictionId
            answerPredictionIds.push(predictionId)
          })
          const reporterPredictionIds = []
          reportSnap.forEach(reportDoc => {
            let predictionId = reportDoc.data().predictionId
            reporterPredictionIds.push(predictionId)
          })
          const predictions = []
          predictionSnap.forEach(predictionDoc => {
            let prediction = predictionDoc.data()
            if (prediction.reportingFrom <= this.now && prediction.id != predictionId) {
              if (this.isTrader || this.isCreator || this.isReporter) {
                if (reporterPredictionIds.indexOf(prediction.id) > -1) {
                  predictions.push(prediction)
                }
              } else {
                if (answerPredictionIds.indexOf(prediction.id) == -1) {
                  if (reporterPredictionIds.indexOf(prediction.id) == -1) {
                    predictions.push(prediction)
                  }
                }
              }
            }
          })
          this.initNotFinalizedItems(predictions)
        }).catch(error => {
          console.error(error)
          this.items = []
        })
      },
      getSecondReportingPredictions () {
        const answerRef = this.$fb.collection(this.db, 'answer').withConverter(this.answerConverter)
        const answerPromise = this.$fb.getDocs(this.$fb.query(answerRef,
          this.$fb.where('user_id', '==', this.eth.eoa),
          this.$fb.where('is_finalized', '==', false)))
        const reportRef = this.$fb.collection(this.db, 'report').withConverter(this.reportConverter)
        const reportPromise = this.$fb.getDocs(this.$fb.query(reportRef,
          this.$fb.where('report_type', '==', this.reportType.secondReporting),
          this.$fb.where('reporter_id', '==', this.eth.eoa),
          this.$fb.where('is_finalized', '==', false)))
        const predictionRef = this.$fb.collection(this.db, 'prediction').withConverter(this.predictionConverter)
        const predictionPromise = this.$fb.getDocs(this.$fb.query(predictionRef,
            this.$fb.where('second_reporting_to', '>=', this.now),
            this.$fb.where('is_published', '==', true),
            this.$fb.where('is_invalid', '==', false),
            this.$fb.where('is_finalized', '==', false),
            this.$fb.orderBy('second_reporting_to'),
            this.$fb.limit(1000)))
        const promiseArray = [
          this.predictionIdPromise,
          answerPromise,
          reportPromise,
          predictionPromise,
        ]
        Promise.all(promiseArray).then(resultArray => {
          const predictionId = resultArray[0]
          const answerSnap = resultArray[1]
          const reportSnap = resultArray[2]
          const predictionSnap = resultArray[3]
          const answerPredictionIds = []
          answerSnap.forEach(answerDoc => {
            let predictionId = answerDoc.data().predictionId
            answerPredictionIds.push(predictionId)
          })
          const reporterPredictionIds = []
          reportSnap.forEach(reportDoc => {
            let predictionId = reportDoc.data().predictionId
            reporterPredictionIds.push(predictionId)
          })
          const predictions = []
          predictionSnap.forEach(predictionDoc => {
            let prediction = predictionDoc.data()
            if (prediction.secondReportingFrom <= this.now && prediction.id != predictionId) {
              if (this.isTrader || this.isCreator || this.isReporter) {
                if (reporterPredictionIds.indexOf(prediction.id) > -1) {
                  predictions.push(prediction)
                }
              } else {
                if (answerPredictionIds.indexOf(prediction.id) == -1) {
                  if (reporterPredictionIds.indexOf(prediction.id) == -1) {
                    predictions.push(prediction)
                  }
                }
              }
            }
          })
          this.initNotFinalizedItems(predictions)
        }).catch(error => {
          console.error(error)
          this.items = []
        })
      },
      getFinalizedPredictions () {
        const answerRef = this.$fb.collection(this.db, 'answer').withConverter(this.answerConverter)
        const answerPromise = this.$fb.getDocs(this.$fb.query(answerRef,
          this.$fb.where('user_id', '==', this.eth.eoa),
          this.$fb.where('is_finalized', '==', true),
          this.$fb.orderBy('close_at', 'desc')))
        const reportRef = this.$fb.collection(this.db, 'report').withConverter(this.reportConverter)
        const reportPromise = this.$fb.getDocs(this.$fb.query(reportRef,
          this.$fb.where('reporter_id', '==', this.eth.eoa),
          this.$fb.where('is_finalized', '==', true),
          this.$fb.orderBy('reporting_to', 'desc')))
        const creatorRef = this.$fb.collection(this.db, 'prediction').withConverter(this.predictionConverter)
        const creatorPromise = this.$fb.getDocs(this.$fb.query(creatorRef,
          this.$fb.where('creator_id', '==', this.eth.eoa),
          this.$fb.where('is_published', '==', true),
          this.$fb.where('is_invalid', '==', false),
          this.$fb.where('is_finalized', '==', true),
          this.$fb.orderBy('finalized_at', 'desc'),
          this.$fb.limit(1000)))
        const predictionRef = this.$fb.collection(this.db, 'prediction').withConverter(this.predictionConverter)
        const predictionPromise = this.$fb.getDocs(this.$fb.query(predictionRef,
          this.$fb.where('is_published', '==', true),
          this.$fb.where('is_invalid', '==', false),
          this.$fb.where('is_finalized', '==', true),
          this.$fb.orderBy('finalized_at', 'desc'),
          this.$fb.limit(1000)))
        const promiseArray = [
          this.predictionIdPromise,
          answerPromise,
          reportPromise,
          creatorPromise,
          predictionPromise,
        ]
        Promise.all(promiseArray).then(resultArray => {
          const predictionId = resultArray[0]
          const answerSnap = resultArray[1]
          const reportSnap = resultArray[2]
          const creatorSnap = resultArray[3]
          const predictionSnap = resultArray[4]
          const results = new Map()
          answerSnap.forEach(answerDoc => {
            const data = answerDoc.data()
            const result = {
              traderOrReporter: true,
              isCorrect: data.rewardAmount > 0,
              answerAmount: data.answerAmount,
              rewardAmount: data.rewardAmount,
            }
            results.set(data.predictionId, result)
          })
          reportSnap.forEach(reportDoc => {
            const data = reportDoc.data()
            let result = results.get(data.predictionId)
            if (result) {
              result.rewardAmount = result.rewardAmount + data.rewardAmount
            } else {
              result = {
                traderOrReporter: true,
                isCorrect: data.rewardAmount > 0,
                answerAmount: '-',
                rewardAmount: data.rewardAmount,
              }
            }
            results.set(data.predictionId, result)
          })
          creatorSnap.forEach(creatorDoc => {
            const data = creatorDoc.data()
            let result = results.get(data.id)
            if (result) {
              result.rewardAmount = result.rewardAmount + data.creatorRewardAmount
            } else {
              result = {
                traderOrReporter: false,
                isCorrect: false,
                answerAmount: '-',
                rewardAmount: data.creatorRewardAmount,
              }
            }
            results.set(data.id, result)
          })
          this.initFinalizedItems(predictionId, predictionSnap, results)
        }).catch(error => {
          console.error(error)
          this.items = []
        })
      },
      getAllPredictions () {
        const predictionRef = this.$fb.collection(this.db, 'prediction').withConverter(this.predictionConverter)
        const predictionPromise = this.$fb.getDocs(this.$fb.query(predictionRef,
          this.$fb.where('is_published', '==', true),
          this.$fb.where('is_invalid', '==', false),
          this.$fb.orderBy('open_at', 'desc'),
          this.$fb.limit(1000)))
        Promise.all([this.predictionIdPromise, predictionPromise])
        .then(resultArray => {
          const predictionId = resultArray[0]
          const predictionSnap = resultArray[1]
          const predictions = []
          predictionSnap.forEach(predictionDoc => {
            let prediction = predictionDoc.data()
            if (prediction.id != predictionId) {
              predictions.push(prediction)
            }
          })
          this.initNotFinalizedItems(predictions)
        }).catch(error => {
          console.error(error)
          this.items = []
        })
      },
      getTraderFinalizedPredictions () {
        const answerRef = this.$fb.collection(this.db, 'answer').withConverter(this.answerConverter)
        let answerPromise = this.$fb.getDocs(this.$fb.query(answerRef,
          this.$fb.where('user_id', '==', this.eth.eoa),
          this.$fb.where('is_finalized', '==', true)))
        const predictionRef = this.$fb.collection(this.db, 'prediction').withConverter(this.predictionConverter)
        let predictionPromise = this.$fb.getDocs(this.$fb.query(predictionRef,
          this.$fb.where('is_published', '==', true),
          this.$fb.where('is_invalid', '==', false),
          this.$fb.where('is_finalized', '==', true),
          this.$fb.orderBy('finalized_at', 'desc'),
          this.$fb.limit(1000)))
        Promise.all([this.predictionIdPromise, answerPromise, predictionPromise])
        .then(promiseArray => {
          const predictionId = promiseArray[0]
          const answerSnap = promiseArray[1]
          const predictionSnap = promiseArray[2]
          const results = new Map()
          answerSnap.forEach(answerDoc => {
            const data = answerDoc.data()
            const result = {
              traderOrReporter: true,
              isCorrect: data.rewardAmount > 0,
              answerAmount: data.answerAmount,
              rewardAmount: data.rewardAmount,
            }
            results.set(data.predictionId, result)
          })
          this.initFinalizedItems(predictionId, predictionSnap, results)
        }).catch(error => {
          console.error(error)
          this.items = []
        })
      },
      getCreatorFinalizedPredictions () {
        const predictionRef = this.$fb.collection(this.db, 'prediction').withConverter(this.predictionConverter)
        const predictionPromise = this.$fb.getDocs(this.$fb.query(predictionRef,
          this.$fb.where('creator_id', '==', this.eth.eoa),
          this.$fb.where('is_published', '==', true),
          this.$fb.where('is_invalid', '==', false),
          this.$fb.where('is_finalized', '==', true),
          this.$fb.orderBy('finalized_at', 'desc'),
          this.$fb.limit(1000)))
        Promise.all([this.predictionIdPromise, predictionPromise])
        .then(promiseArray => {
          const predictionId = promiseArray[0]
          const predictionSnap = promiseArray[1]
          const results = new Map()
          predictionSnap.forEach(predictionDoc => {
            const data = predictionDoc.data()
            const result = {
              traderOrReporter: false,
              isCorrect: false,
              answerAmount: '-',
              rewardAmount: data.creatorRewardAmount,
            }
            results.set(data.id, result)
          })
          this.initFinalizedItems(predictionId, predictionSnap, results)
        }).catch(error => {
          console.error(error)
          this.items = []
        })
      },
      getReporterFinalizedPredictions () {
        const reportRef = this.$fb.collection(this.db, 'report').withConverter(this.reportConverter)
        const reportPromise = this.$fb.getDocs(this.$fb.query(reportRef,
          this.$fb.where('reporter_id', '==', this.eth.eoa),
          this.$fb.where('is_finalized', '==', true)))
        const predictionRef = this.$fb.collection(this.db, 'prediction').withConverter(this.predictionConverter)
        const predictionPromise = this.$fb.getDocs(this.$fb.query(predictionRef,
          this.$fb.where('is_published', '==', true),
          this.$fb.where('is_invalid', '==', false),
          this.$fb.where('is_finalized', '==', true),
          this.$fb.orderBy('finalized_at', 'desc'),
          this.$fb.limit(1000)))
        const promiseArray = [
          this.predictionIdPromise, 
          reportPromise, 
          predictionPromise
        ]
        Promise.all(promiseArray)
        .then(resultArray => {
          const predictionId = resultArray[0]
          const reportSnap = resultArray[1]
          const predictionSnap = resultArray[2]
          const results = new Map()
          reportSnap.forEach(reportDoc => {
            const data = reportDoc.data()
            const result = {
              traderOrReporter: true,
              isCorrect: data.rewardAmount > 0,
              answerAmount: '-',
              rewardAmount: data.rewardAmount,
            }
            results.set(data.predictionId, result)
          })
          this.initFinalizedItems(predictionId, predictionSnap, results)
        }).catch(error => {
          console.error(error)
          this.items = []
        })
      },
      getTraderPredictions () {
        const answerRef = this.$fb.collection(this.db, 'answer').withConverter(this.answerConverter)
        const answerPromise = this.$fb.getDocs(this.$fb.query(answerRef,
          this.$fb.where('user_id', '==', this.eth.eoa),
          this.$fb.where('is_finalized', '==', false)))
        const predictionRef = this.$fb.collection(this.db, 'prediction').withConverter(this.predictionConverter)
        const predictionPromise = this.$fb.getDocs(this.$fb.query(predictionRef,
          this.$fb.where('is_published', '==', true),
          this.$fb.where('is_invalid', '==', false),
          this.$fb.where('is_finalized', '==', false),
          this.$fb.orderBy('close_at'),
          this.$fb.limit(1000)))
        const promiseArray = [
          this.predictionIdPromise,
          answerPromise,
          predictionPromise
        ]
        Promise.all(promiseArray).then(resultArray => {
          const predictionId = resultArray[0]
          const answerSnap = resultArray[1]
          const predictionSnap = resultArray[2]
          const traderPredictionIds = []
          answerSnap.forEach(answerDoc => {
            let predictionId = answerDoc.data().predictionId
            traderPredictionIds.push(predictionId)
          })
          const predictions = []
          predictionSnap.forEach(predictionDoc => {
            let prediction = predictionDoc.data()
            if (prediction.id != predictionId) {
              if (traderPredictionIds.indexOf(prediction.id) > -1) {
                predictions.push(prediction)
              }
            }
          })
          this.initNotFinalizedItems(predictions)
        }).catch(error => {
          console.error(error)
          this.items = []
        })
      },
      getReporterPredictions () {
        const reportRef = this.$fb.collection(this.db, 'report').withConverter(this.reportConverter)
        const reportPromise = this.$fb.getDocs(this.$fb.query(reportRef,
          this.$fb.where('report_type', '==', this.reportType.reporting),
          this.$fb.where('reporter_id', '==', this.eth.eoa),
          this.$fb.where('is_finalized', '==', false)))
        const predictionRef = this.$fb.collection(this.db, 'prediction').withConverter(this.predictionConverter)
        const predictionPromise = this.$fb.getDocs(this.$fb.query(predictionRef,
          this.$fb.where('is_published', '==', true),
          this.$fb.where('is_invalid', '==', false),
          this.$fb.where('is_finalized', '==', false),
          this.$fb.orderBy('reporting_to'),
          this.$fb.limit(1000)))
        const promiseArray = [
          this.predictionIdPromise,
          reportPromise,
          predictionPromise,
        ]
        Promise.all(promiseArray).then(resultArray => {
          const predictionId = resultArray[0]
          const reportSnap = resultArray[1]
          const predictionSnap = resultArray[2]
          const reporterPredictionIds = []
          reportSnap.forEach(reportDoc => {
            let predictionId = reportDoc.data().predictionId
            reporterPredictionIds.push(predictionId)
          })
          const predictions = []
          predictionSnap.forEach(predictionDoc => {
            let prediction = predictionDoc.data()
            if (prediction.id != predictionId) {
              if (reporterPredictionIds.indexOf(prediction.id) > -1) {
                predictions.push(prediction)
              }
            }
          })
          this.initNotFinalizedItems(predictions)
        }).catch(error => {
          console.error(error)
          this.items = []
        })
      },
      getSecondReporterPredictions () {
        const reportRef = this.$fb.collection(this.db, 'report').withConverter(this.reportConverter)
        const reportPromise = this.$fb.getDocs(this.$fb.query(reportRef,
            this.$fb.where('report_type', '==', this.reportType.secondReporting),
            this.$fb.where('reporter_id', '==', this.eth.eoa),
            this.$fb.where('is_finalized', '==', false)))
        const predictionRef = this.$fb.collection(this.db, 'prediction').withConverter(this.predictionConverter)
        const predictionPromise = this.$fb.getDocs(this.$fb.query(predictionRef,
            this.$fb.where('is_published', '==', true),
            this.$fb.where('is_invalid', '==', false),
            this.$fb.where('is_finalized', '==', false),
            this.$fb.orderBy('second_reporting_to'),
            this.$fb.limit(1000)))
        const promiseArray = [
          this.predictionIdPromise,
          reportPromise,
          predictionPromise,
        ]
        Promise.all(promiseArray).then(resultArray => {
          const predictionId = resultArray[0]
          const reportSnap = resultArray[1]
          const predictionSnap = resultArray[2]
          const reporterPredictionIds = []
          reportSnap.forEach(reportDoc => {
            let predictionId = reportDoc.data().predictionId
            reporterPredictionIds.push(predictionId)
          })
          const predictions = []
          predictionSnap.forEach(predictionDoc => {
            let prediction = predictionDoc.data()
            if (prediction.id != predictionId) {
              if (reporterPredictionIds.indexOf(prediction.id) > -1) {
                predictions.push(prediction)
              }
            }
          })
          this.initNotFinalizedItems(predictions)
        }).catch(error => {
          console.error(error)
          this.items = []
        })
      },
      getCreatorPredictions () {
        const predictionRef = this.$fb.collection(this.db, 'prediction').withConverter(this.predictionConverter)
        const predictionPromise = this.$fb.getDocs(this.$fb.query(predictionRef,
          this.$fb.where('creator_id', '==', this.eth.eoa),
          this.$fb.where('is_finalized', '==', false),
          this.$fb.orderBy('created_at', 'desc'),
          this.$fb.limit(1000)))
        const promiseArray = [
          this.predictionIdPromise, 
          predictionPromise
        ]
        Promise.all(promiseArray)
        .then(resultArray => {
          const predictionId = resultArray[0]
          const predictionSnap = resultArray[1]
          const predictions = []
          predictionSnap.forEach(predictionDoc => {
            let prediction = predictionDoc.data()
            if (prediction.id != predictionId) {
              predictions.push(prediction)
            }
          })
          this.initNotFinalizedItems(predictions)
        }).catch(error => {
          console.error(error)
          this.items = []
        })
      },
      push (path) {
        if (this.$route.query && this.$route.query.q) {
          path += `?q=${this.$route.query.q}`
        }
        this.$router.push({ path: path });
      },
    },
  }
</script>
