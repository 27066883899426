<template>
  <v-main class="background">
    <v-container fluid>
      <v-row justify="center" align="center">
        <v-col cols="12">
          <p>予想成績</p>
          <v-card>
            <v-card-title>
              チップランキング - 2020年5月31日(金)
            </v-card-title>
            <v-card-text>
              <v-data-table
                hide-default-footer
                disable-sort
                :headers="headers"
                :items="items"
              >
                <template v-slot:[`item.category`]="{ item }">
                  <v-icon class="mr-5">{{ item.category.icon }}</v-icon>{{ item.category.text }}
                </template>
                <template v-slot:[`item.prevRatio`]="{ item }">
                  <v-icon :color="item.prevRatio.color">{{ item.prevRatio.icon }}</v-icon>
                </template>
                <template v-slot:[`item.hittingRatio`]="{ item }">
                  {{ item.hittingRatio.hittingCount }} / {{ item.hittingRatio.predictionCount }} ({{ item.hittingRatio.value }})
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
          <br/>
          <Ads1/>
          <br/>
          <v-card>
            <v-card-title>
              デイリーランキング - 2020年5月31日(金)
            </v-card-title>
            <v-card-text>
              <v-data-table
                hide-default-footer
                disable-sort
                :headers="headers"
                :items="items"
              >
                <template v-slot:[`item.category`]="{ item }">
                  <v-icon class="mr-5">{{ item.category.icon }}</v-icon>{{ item.category.text }}
                </template>
                <template v-slot:[`item.prevRatio`]="{ item }">
                  <v-icon :color="item.prevRatio.color">{{ item.prevRatio.icon }}</v-icon>
                </template>
                <template v-slot:[`item.hittingRatio`]="{ item }">
                  {{ item.hittingRatio.hittingCount }} / {{ item.hittingRatio.predictionCount }} ({{ item.hittingRatio.value }})
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
          <br/>
          <v-card>
            <v-card-title>
              週間ランキング - 2020年5月第3週
            </v-card-title>
            <v-card-text>
              <v-data-table
                hide-default-footer
                disable-sort
                :headers="headers"
                :items="items"
              >
                <template v-slot:[`item.category`]="{ item }">
                  <v-icon class="mr-5">{{ item.category.icon }}</v-icon>{{ item.category.text }}
                </template>
                <template v-slot:[`item.prevRatio`]="{ item }">
                  <v-icon :color="item.prevRatio.color">{{ item.prevRatio.icon }}</v-icon>
                </template>
                <template v-slot:[`item.hittingRatio`]="{ item }">
                  {{ item.hittingRatio.hittingCount }} / {{ item.hittingRatio.predictionCount }} ({{ item.hittingRatio.value }})
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
          <br/>
          <v-card>
            <v-card-title>
              月間ランキング - 2020年4月
            </v-card-title>
            <v-card-text>
              <v-data-table
                hide-default-footer
                disable-sort
                :headers="headers"
                :items="items"
              >
                <template v-slot:[`item.category`]="{ item }">
                  <v-icon class="mr-5">{{ item.category.icon }}</v-icon>{{ item.category.text }}
                </template>
                <template v-slot:[`item.prevRatio`]="{ item }">
                  <v-icon :color="item.prevRatio.color">{{ item.prevRatio.icon }}</v-icon>
                </template>
                <template v-slot:[`item.hittingRatio`]="{ item }">
                  {{ item.hittingRatio.hittingCount }} / {{ item.hittingRatio.predictionCount }} ({{ item.hittingRatio.value }})
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
          <br/>
          <v-card>
            <v-card-title>
              年間ランキング - 2019年
            </v-card-title>
            <v-card-text>
              <v-data-table
                hide-default-footer
                disable-sort
                :headers="headers"
                :items="items"
              >
                <template v-slot:[`item.category`]="{ item }">
                  <v-icon class="mr-5">{{ item.category.icon }}</v-icon>{{ item.category.text }}
                </template>
                <template v-slot:[`item.prevRatio`]="{ item }">
                  <v-icon :color="item.prevRatio.color">{{ item.prevRatio.icon }}</v-icon>
                </template>
                <template v-slot:[`item.hittingRatio`]="{ item }">
                  {{ item.hittingRatio.hittingCount }} / {{ item.hittingRatio.predictionCount }} ({{ item.hittingRatio.value }})
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
          <br/>
          <Ads2/>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
  import Ads1 from '@/components/Ads1.vue'
  import Ads2 from '@/components/Ads2.vue'
  
  export default {
    components: {
      Ads1,
      Ads2,
    },
    data: () => ({
      headers: [
        { text: 'カテゴリ', value: 'category' },
        { text: '前回比', value: 'prevRatio' },
        { text: '的中率', value: 'hittingRatio' },
        { text: '獲得チップ', value: 'tip' },
        { text: 'ランク', value: 'rank' },
      ],
      items: [
        { category: { icon: 'mdi-calendar-multiple', text: '総合' }, prevRatio: { color: 'blue', icon: 'mdi-arrow-down-thick' }, hittingRatio: { hittingCount: 28, predictionCount: 81, value: '35%' }, tip: '+1,045', rank: '192位 / 5,144人中', },
        { category: { icon: 'mdi-baseball-bat', text: '野球' }, prevRatio: { color: 'red', icon: 'mdi-arrow-up-thick' }, hittingRatio: { hittingCount: 16, predictionCount: 44, value: '36%' }, tip: '+695', rank: '103位 / 3,913人中', },
        { category: { icon: 'mdi-soccer', text: 'サッカー' }, prevRatio: { color: 'blue', icon: 'mdi-arrow-down-thick' }, hittingRatio: { hittingCount: 0, predictionCount: 3, value: '0%' }, tip: '-250', rank: '1,311位 / 1,678人中', },
        { category: { icon: 'mdi-tennis', text: 'その他スポーツ' }, prevRatio: { color: 'red', icon: 'mdi-arrow-up-thick' }, hittingRatio: { hittingCount: 5, predictionCount: 15, value: '33%' }, tip: '+218', rank: '158位 / 1,969人中', },
        { category: { icon: 'mdi-emoticon-excited-outline', text: 'エンタメ' }, prevRatio: { color: 'blue', icon: 'mdi-arrow-down-thick' }, hittingRatio: { hittingCount: 6, predictionCount: 11, value: '55%' }, tip: '+661', rank: '78位 / 2,632人中', },
        { category: { icon: 'mdi-chart-line', text: '政治・経済' }, prevRatio: { color: 'green', icon: 'mdi-arrow-right-thick' }, hittingRatio: { hittingCount: 0, predictionCount: 0, value: '-' }, tip: '-', rank: '-位 / 619人中', },
        { category: { icon: 'mdi-shoe-print', text: 'その他' }, prevRatio: { color: 'blue', icon: 'mdi-arrow-down-thick' }, hittingRatio: { hittingCount: 1, predictionCount: 8, value: '13%' }, tip: '-279', rank: '502位 / 701人中', },
      ],
    }),
    mounted () {
      this.$vuetify.goTo(0)
    },
  }
</script>
