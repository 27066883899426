<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="rank"
    >
      <template v-slot:[`item.prevRatio`]="{ item }">
        <v-icon :color="item.prevRatio.color">{{ item.prevRatio.icon }}</v-icon>
      </template>
      <template v-slot:[`item.user`]="{ item }">
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-avatar
                size="32px"
                item
                class="mr-5"
              >
                <v-img
                  :src="item.user.avator"
                  alt="avatar"
                />
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title><a href="#">{{ item.user.name }}</a></v-list-item-title>
              <v-list-item-subtitle>{{ item.user.message }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
      <template v-slot:[`item.hittingRatio`]="{ item }">
        {{ item.hittingRatio.hittingCount }} / {{ item.hittingRatio.predictionCount }} ({{ item.hittingRatio.value }})
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  
  export default {
    data: () => ({
      headers: [
        { text: 'ランク', value: 'rank' },
        { text: '前回比', value: 'prevRatio' },
        { text: 'ユーザー', value: 'user' },
        { text: '的中率', value: 'hittingRatio' },
        { text: 'チップ', value: 'tip' },
      ],
      items: [
        { rank: 1, prevRatio: { color: 'red', icon: 'mdi-arrow-up-thick' }, user: { avator: 'https://ca.slack-edge.com/T5654U2GP-U56MK2UQM-e6a64af42f4c-512', name: 'マイケル', message: '頑張ります！' }, hittingRatio: { hittingCount: 45, predictionCount: 132, value: '34%' }, tip: 6014, },
        { rank: 2, prevRatio: { color: 'blue', icon: 'mdi-arrow-down-thick' }, user: { avator: 'https://ca.slack-edge.com/T5654U2GP-U56MK2UQM-e6a64af42f4c-512', name: 'ミハエル', message: '頑張るでぇ！'}, hittingRatio: { hittingCount: 51, predictionCount: 134, value: '37%' }, tip: 5591, },
        { rank: 3, prevRatio: { color: 'green', icon: 'mdi-arrow-right-thick' }, user: { avator: 'https://ca.slack-edge.com/T5654U2GP-U56MK2UQM-e6a64af42f4c-512', name: 'ミカエル', message: '頑張るぞ！'}, hittingRatio: { hittingCount: 31, predictionCount: 85, value: '41%' }, tip: 5407, },
        { rank: 4, prevRatio: { color: 'green', icon: 'mdi-arrow-right-thick' }, user: { avator: 'https://ca.slack-edge.com/T5654U2GP-U56MK2UQM-e6a64af42f4c-512', name: 'ミッシェル', message: '頑張りましょか！'}, hittingRatio: { hittingCount: 31, predictionCount: 85, value: '41%' }, tip: 5407, },
        { rank: 5, prevRatio: { color: 'green', icon: 'mdi-arrow-right-thick' }, user: { avator: 'https://ca.slack-edge.com/T5654U2GP-U56MK2UQM-e6a64af42f4c-512', name: 'シューマッハ', message: '頑張っていきましょう！'}, hittingRatio: { hittingCount: 31, predictionCount: 85, value: '41%' }, tip: 5407, },
        { rank: 6, prevRatio: { color: 'green', icon: 'mdi-arrow-right-thick' }, user: { avator: 'https://ca.slack-edge.com/T5654U2GP-U56MK2UQM-e6a64af42f4c-512', name: 'ジャクソン', message: '頑張っていきまっしょい！'}, hittingRatio: { hittingCount: 31, predictionCount: 85, value: '41%' }, tip: 5407, },
        { rank: 7, prevRatio: { color: 'green', icon: 'mdi-arrow-right-thick' }, user: { avator: 'https://ca.slack-edge.com/T5654U2GP-U56MK2UQM-e6a64af42f4c-512', name: 'ジョーダン', message: '頑張りんご！'}, hittingRatio: { hittingCount: 31, predictionCount: 85, value: '41%' }, tip: 5407, },
        { rank: 8, prevRatio: { color: 'green', icon: 'mdi-arrow-right-thick' }, user: { avator: 'https://ca.slack-edge.com/T5654U2GP-U56MK2UQM-e6a64af42f4c-512', name: 'ムーア', message: '頑張るんば！'}, hittingRatio: { hittingCount: 31, predictionCount: 85, value: '41%' }, tip: 5407, },
        { rank: 9, prevRatio: { color: 'green', icon: 'mdi-arrow-right-thick' }, user: { avator: 'https://ca.slack-edge.com/T5654U2GP-U56MK2UQM-e6a64af42f4c-512', name: '富岡', message: '頑張っていこかー！'}, hittingRatio: { hittingCount: 31, predictionCount: 85, value: '41%' }, tip: 5407, },
        { rank: 10, prevRatio: { color: 'green', icon: 'mdi-arrow-right-thick' }, user: { avator: 'https://ca.slack-edge.com/T5654U2GP-U56MK2UQM-e6a64af42f4c-512', name: 'ヤキソバン', message: '頑張ろー！'}, hittingRatio: { hittingCount: 31, predictionCount: 85, value: '41%' }, tip: 5407, },
        { rank: 11, prevRatio: { color: 'green', icon: 'mdi-arrow-right-thick' }, user: { avator: 'https://ca.slack-edge.com/T5654U2GP-U56MK2UQM-e6a64af42f4c-512', name: 'ケトラー', message: '頑張ってください？'}, hittingRatio: { hittingCount: 31, predictionCount: 85, value: '41%' }, tip: 5407, },
      ],
    }),
  }
</script>
