<template>
  <v-main class="background">
    <v-container fluid>
      <v-row justify="center" align="center">
        <v-col cols="12">
          <p>お知らせ</p>
          <v-card>
            <v-data-table
              :headers="headers"
              hide-default-header
              :items="items"
            >
              <template v-slot:[`item.message`]="{ item }">
                <v-card-title class="text-body-1">{{ item.title }}</v-card-title>
                <v-card-subtitle class="text-caption">{{ item.receiveDatetime }}</v-card-subtitle>
                <v-card-text><span v-html="item.body"></span></v-card-text>
              </template>
            </v-data-table>
          </v-card>
          <br/>
          <Ads2/>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
  import mixin from '@/util/mixin'
  import Ads2 from '@/components/Ads2.vue'
  
  export default {
    mixins: [mixin],
    components: {
      Ads2,
    },
    data: () => ({
      headers: [
        { text: 'メッセージ', value: 'message' }
      ],
      items: [
        { receiveDatetime: '2020-06-01 07:23', title: 'ログイン報酬', body: 'ログイン報酬として40チップが付与されました。' },
        { receiveDatetime: '2020-05-30 21:00', title: '結果投票不成立', body: '<a href="#">あなたが作成した予想問題</a>は他のユーザーから異議申し立てがあったため、信頼されたユーザーによる決選投票に持ち込まれました。' },
        { receiveDatetime: '2020-05-28 16:00', title: '予想問題不成立', body: '<a href="#">あなたが作成した予想問題</a>は他のユーザーからの通報に基づく運営の判断により取り下げられました。' },
        { receiveDatetime: '2020-05-28 11:00', title: '予想問題不成立', body: '<a href="#">あなたが作成した予想問題</a>は参加者数が基準を下回ったため、成立しませんでした。' },
        { receiveDatetime: '2020-05-25 23:12', title: '投げ銭受領', body: '<a href="#">アロンソ</a>さんから投げ銭のお礼に5チップが送られました。' },
        { receiveDatetime: '2020-05-20 12:05', title: '投げ銭受領', body: '<a href="#">ハミルトン</a>さんから<a href="#">あなたのコメント</a>に投げ銭が10チップ送られました。' },
        { receiveDatetime: '2020-05-03 13:00', title: '出題者報酬', body: '<a href="#">あなたが作成した予想問題</a>が確定しました。出題者報酬として374チップが付与されました。' },
        { receiveDatetime: '2020-05-01 19:41', title: 'フォロー', body: '<a href="#">ベッテル</a>さんからフォローされました。' },
        { receiveDatetime: '2020-04-20 7:00', title: 'ランキング報酬', body: 'おめでとうございます！2020年4月19日(日)のサッカーデイリーランキングで1位にランクインしました！<br/>ランキング報酬として881チップが付与されました。' },
        { receiveDatetime: '2020-04-20 7:00', title: 'ランキング報酬', body: 'おめでとうございます！2020年4月19日(日)の総合デイリーランキングで7位にランクインしました！<br/>ランキング報酬として1,355チップが付与されました。' },
        { receiveDatetime: '2020-04-13 7:00', title: 'ランキング報酬', body: 'おめでとうございます！2020年4月12日(日)のエンタメデイリーランキングで9位にランクインしました！<br/>ランキング報酬として219チップが付与されました。' },
      ],
    }),
    created () {
      this.db = this.$fb.firestore
      this.init()
    },
    mounted () {
      this.$vuetify.goTo(0)
    },
    methods: {
      push (path) {
        if (this.$route.fullPath !== path) {
          this.$router.push({ path: path });
        }
      },
      init () {
        const eoa = this.$store.state.eth.eoa
        const notificationRef = this.$fb.collection(this.db, 'notification').withConverter(this.notificationConverter)
        this.$fb.getDocs(this.$fb.query(notificationRef,
          this.$fb.where('user_id', '==', eoa),
          this.$fb.orderBy('created_at', 'desc'),
          this.$fb.limit(1000)))
          .then(notificationSnap => {
            const items = []
            notificationSnap.forEach(notificationDoc => {
              const notificationData = notificationDoc.data()
              const receiveDatetime = `${this.formatDate(notificationData.createdAt)} ${this.formatTime(notificationData.createdAt)}`
              items.push({
                receiveDatetime: receiveDatetime,
                title: notificationData.title,
                body: notificationData.content,
              })
            })
            this.items = items
          })
        this.$fb.getDoc(this.$fb.doc(this.db, 'users', eoa))
        .then(usersDoc => {
          const now = new Date()
          this.$fb.updateDoc(usersDoc.ref, {
            last_view_at: now
          })
          const user = {...this.$store.state.user}
          user.last_view_at = now
          this.$store.commit('doRegisterUser', user)
        })
      }
    }
  }
</script>
