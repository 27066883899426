<template>
  <v-main class="bg">
    <v-container fill-height style="max-width: 1140px;">
      <div class="d-block">
        <div class="text-h2">
          工事中・・・
        </div>
      </div>
    </v-container>
  </v-main>
</template>

<script>
  import mixin from '@/util/mixin'

  export default {
    mixins: [mixin],
    methods: {
    },
  }
</script>

<style scoped>
  .bg {
    background-image: url('~@/assets/background.jpg');
    background-repeat: repeat;
  }
</style>