<template>
  <v-main class="bg">
    <v-container fill-height style="max-width: 1140px;">
      <div class="d-block">
        <div class="text-h2 pb-12">
          安心・安全な予想プラットフォーム
        </div>
        <div class="text-h4 pb-12">
          スポーツやエンタメ、政治・経済などの未来を予想して楽しもう！
        </div>
        <v-btn x-large rounded color="primary" class="px-12" @click="$emit('show-login-dialog')">
          さっそく始める
        </v-btn>
      </div>
    </v-container>
  </v-main>
</template>

<script>
  import mixin from '@/util/mixin'

  export default {
    mixins: [mixin],
    methods: {
    },
  }
</script>

<style scoped>
  .bg {
    background-image: url('~@/assets/background.jpg');
    background-repeat: repeat;
  }
</style>