<template>
  <v-main class="background">
    <v-container fluid>
      <v-row justify="center" align="center">
        <v-col cols="12">
          <p>ウォッチリスト</p>
          <Prediction 
            :prediction-id-promise="predictionIdPromise" 
            :now="now"
          />
          <br/>
          <Ads1/>
          <br/>
          <OtherPredictions
            :is-watch-list="true" 
            :prediction-id-promise="predictionIdPromise" 
            :now="now"
          />
          <br/>
          <Ads2/>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
  import mixin from '@/util/mixin'
  import Prediction from '@/components/Prediction.vue'
  import OtherPredictions from '@/components/OtherPredictions.vue'
  import Ads1 from '@/components/Ads1.vue'
  import Ads2 from '@/components/Ads2.vue'
  
  export default {
    mixins: [mixin],
    components: {
      Prediction,
      OtherPredictions,
      Ads1,
      Ads2,
    },
    data: () => ({
      now: new Date(),
      db: null,
      predictionIdPromise: null,
    }),
    created () {
      this.db = this.$fb.firestore
      this.predictionIdPromise = this.getPredictionIdPromise()
    },
    mounted () {
      this.$vuetify.goTo(0)
    },
    methods: {
      getPredictionIdPromise () {
        let watchListPredictionIds = []
        let watchListPredictions = []
        let predictionId = this.$route.params.id
        if (predictionId) return Promise.resolve(predictionId)
        const watchListRef = this.$fb.collection(this.db, 'watch_list').withConverter(this.watchListConverter)
        return this.$fb.getDocs(this.$fb.query(watchListRef,
          this.$fb.where('user_id', '==', this.$store.state.eth.eoa)))
          .then(watchListSnap => {
            watchListSnap.forEach(watchListDoc => {
              let predictionId = watchListDoc.data().predictionId
              watchListPredictionIds.push(predictionId)
            })
            if (watchListPredictionIds.length === 0) {
              const message = 'ウォッチリストに登録された予想が見つかりませんでした。'
              return Promise.reject(new Error(message))
            }
            const predictionRef = this.$fb.collection(this.db, 'prediction').withConverter(this.predictionConverter)
            return this.$fb.getDocs(this.$fb.query(predictionRef,
              this.$fb.where(this.$fb.documentId(), 'in', watchListPredictionIds),
              this.$fb.limit(1000)))
          }).then(predictionSnap => {
            predictionSnap.forEach(predictionDoc => {
              watchListPredictions.push(predictionDoc.id)
            })
            const index = Math.floor(Math.random() * watchListPredictions.length)
            return Promise.resolve(watchListPredictions[index])
          }).catch(error => {
            return Promise.reject(error)
          })
      },
    },
  }
</script>
