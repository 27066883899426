<template>
  <v-main class="background">
    <v-container fluid>
      <v-row justify="center" align="center">
        <v-col cols="12">
          <p>結果投票中の予想</p>
          <Prediction 
            :prediction-id-promise="predictionIdPromise" 
            :now="now"
          />
          <br/>
          <Ads1/>
          <br/>
          <OtherPredictions 
            :is-reporting="true" 
            :prediction-id-promise="predictionIdPromise" 
            :now="now"
          />
          <br/>
          <Ads1/>
          <br/>
          <p>結果投票再受付中の予想</p>
          <OtherPredictions 
            :is-second-reporting="true" 
            :prediction-id-promise="predictionIdPromise" 
            :now="now"
          />
          <br/>
          <Ads2/>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
  import mixin from '@/util/mixin'
  import Prediction from '@/components/Prediction.vue'
  import OtherPredictions from '@/components/OtherPredictions.vue'
  import Ads1 from '@/components/Ads1.vue'
  import Ads2 from '@/components/Ads2.vue'
  
  export default {
    mixins: [mixin],
    components: {
      Prediction,
      OtherPredictions,
      Ads1,
      Ads2,
    },
    data: () => ({
      now: new Date(),
      db: null,
      predictionIdPromise: null,
    }),
    created () {
      this.db = this.$fb.firestore
      this.predictionIdPromise = this.getPredictionIdPromise()
    },
    mounted () {
      this.$vuetify.goTo(0)
    },
    methods: {
      getPredictionIdPromise () {
        let reportPredictionIds = []
        let reportingPredictions = []
        let predictionId = this.$route.params.id
        if (predictionId) return Promise.resolve(predictionId)
        const reportRef = this.$fb.collection(this.db, 'report').withConverter(this.reportConverter)
        return this.$fb.getDocs(this.$fb.query(reportRef,
          this.$fb.where('report_type', '==', this.reportType.reporting),
          this.$fb.where('reporter_id', '==', this.$store.state.eth.eoa),
          this.$fb.where('is_finalized', '==', false)))
          .then(reportSnap => {
            reportSnap.forEach(reportDoc => {
              let predictionId = reportDoc.data().predictionId
              reportPredictionIds.push(predictionId)
            })
            const predictionRef = this.$fb.collection(this.db, 'prediction').withConverter(this.predictionConverter)
            return this.$fb.getDocs(this.$fb.query(predictionRef,
              this.$fb.where('reporting_to', '>=', this.now),
              this.$fb.where('is_published', '==', true),
              this.$fb.where('is_invalid', '==', false),
              this.$fb.where('is_finalized', '==', false),
              this.$fb.orderBy('reporting_to'),
              this.$fb.limit(10)))
          }).then(predictionSnap => {
            predictionSnap.forEach(predictionDoc => {
              if (predictionDoc.data().reportingFrom <= this.now) {
                if (reportPredictionIds.indexOf(predictionDoc.id) == -1) {
                  reportingPredictions.push(predictionDoc.id)
                }
              }
            })
            if (reportingPredictions.length == 0) {
              const message = '結果投票中の予想が見つかりませんでした。'
              return Promise.reject(new Error(message))
            } else {
              const index = Math.floor(Math.random() * reportingPredictions.length)
              return Promise.resolve(reportingPredictions[index])
            }
          }).catch(error => {
            return Promise.reject(error)
          })
      },
    },
  }
</script>
