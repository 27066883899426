<template>
  <v-main class="background">
    <v-snackbar color="success" v-model="snackbarSuccess">
      <v-list-item-title>
        <v-icon class="mr-2">mdi-check-circle-outline</v-icon>
        {{ snackbarSuccessText }}
      </v-list-item-title>
    </v-snackbar>
    <v-container fluid>
      <v-row justify="center" align="center">
        <v-col cols="12">
          <p>プロフィール</p>
          <v-card>
            <template v-if="isEdit">
              <v-card-title>
                <v-avatar
                  size="48px"
                  item
                  class="mr-4"
                >
                  <v-img
                    :src="user.newAvatarDownloadUrl"
                    alt="avatar"
                    :key="componentKey"
                  />
                </v-avatar>
                <v-btn icon @click="deleteImage" v-show="!!user.newAvatarUrl">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-file-input v-model="user.newAvatar" label="プロフィール画像" :clearable="false" @change="imageChanged"></v-file-input>
                <v-text-field v-model="user.newNickname" label="ニックネーム"></v-text-field>
                <v-text-field v-model="user.newMessage" label="一言メッセージ"></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-spacer/>
                <v-btn outlined class="mr-2" @click="isEdit=false">キャンセル</v-btn>
                <v-btn color="primary" @click="save">保存</v-btn>
              </v-card-actions>
            </template>
            <template v-else>
              <v-card-title>
                <v-avatar
                  size="48px"
                  item
                  class="mr-4"
                >
                  <v-img
                    :src="user.avatarDownloadUrl"
                    alt="avatar"
                  />
                </v-avatar>
                {{user.nickname}}
              </v-card-title>
              <v-card-subtitle>{{user.message}}</v-card-subtitle>
              <v-card-text>
                <span class="text-h5 font-weight-bold">{{user.tokenBalance}}</span>&nbsp;&nbsp;TIP
                <span class="ml-4">最終ログイン：{{user.lastLoginAt ? getPassed(user.lastLoginAt) : ''}}</span>
              </v-card-text>
              <v-card-actions>
                <v-spacer/>
                <template v-if="isSelf">
                  <v-btn color="primary" @click="edit">編集する</v-btn>
                </template>
                <template v-else>
                  <template v-if="isFollowing">
                    <v-btn color="primary" @click="unfollow">フォロー解除</v-btn>
                  </template>
                  <template v-else>
                    <v-btn color="primary" @click="follow">フォロー</v-btn>
                  </template>
                </template>
              </v-card-actions>
            </template>
          </v-card>
          <br/>
          <Ads1/>
          <br/>
          <v-card>
            <v-card-title>出題記録</v-card-title>
            <v-card-text>
              <v-data-table
                hide-default-footer
                disable-sort
                :headers="headers"
                :items="items"
              >
                <template v-slot:[`item.category`]="{ item }">
                  <v-icon class="mr-5">{{ item.category.icon }}</v-icon>{{ item.category.text }}
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
          <br/>
          <v-card>
            <v-card-title>結果投票記録</v-card-title>
            <v-card-text>
              <v-data-table
                hide-default-footer
                disable-sort
                :headers="headers2"
                :items="items2"
              >
                <template v-slot:[`item.category`]="{ item }">
                  <v-icon class="mr-5">{{ item.category.icon }}</v-icon>{{ item.category.text }}
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
          <br/>
          <Ads2/>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
  import mixin from '@/util/mixin'
  import Ads1 from '@/components/Ads1.vue'
  import Ads2 from '@/components/Ads2.vue'
  import MyPrediction from '@/views/MyPrediction.vue'
  
  export default {
    mixins: [mixin],
    components: {
      Ads1,
      Ads2,
      MyPrediction,
    },
    data: () => ({
      componentKey: 0,
      dialog: false,
      snackbarSuccess: false,
      snackbarSuccessText: null,
      isEdit: false,
      now: new Date(),
      db: null,
      eth: null,
      user: {},
      defaultAvatar: require('@/assets/user_icon.png'),
      headers: [
        { text: 'カテゴリ', value: 'category' },
        { text: '出題数', value: 'createCount' },
        { text: '確定数', value: 'finalizedCount' },
        { text: '獲得チップ', value: 'tip' },
      ],
      items: [
        { category: { icon: 'mdi-calendar-multiple', text: '総合' }, createCount: 28, finalizedCount: 15, tip: '+1,045', },
        { category: { icon: 'mdi-baseball-bat', text: '野球' }, createCount: 16, finalizedCount: 10, tip: '+695', },
        { category: { icon: 'mdi-soccer', text: 'サッカー' }, createCount: 0, finalizedCount: 0, tip: '0', },
        { category: { icon: 'mdi-tennis', text: 'その他スポーツ' }, createCount: 5, finalizedCount: 3, tip: '+218', },
        { category: { icon: 'mdi-emoticon-excited-outline', text: 'エンタメ' }, createCount: 6, finalizedCount: 2, tip: '+132', },
        { category: { icon: 'mdi-chart-line', text: '政治・経済' }, createCount: 0, finalizedCount: 0, tip: '0', },
        { category: { icon: 'mdi-shoe-print', text: 'その他' }, createCount: 1, finalizedCount: 0, tip: '0', },
      ],
      headers2: [
        { text: 'カテゴリ', value: 'category' },
        { text: '結果投票数', value: 'reportingCount' },
        { text: '採択数', value: 'adoptionCount' },
        { text: '獲得チップ', value: 'tip' },
        { text: '信用ポイント', value: 'trustPoint' },
      ],
      items2: [
        { category: { icon: 'mdi-calendar-multiple', text: '総合' }, reportingCount: 28, adoptionCount: 27, tip: '+1,045', trustPoint: '+17', },
        { category: { icon: 'mdi-baseball-bat', text: '野球' }, reportingCount: 16, adoptionCount: 16, tip: '+695', trustPoint: '+16', },
        { category: { icon: 'mdi-soccer', text: 'サッカー' }, reportingCount: 0, adoptionCount: 0, tip: '0', trustPoint: '0', },
        { category: { icon: 'mdi-tennis', text: 'その他スポーツ' }, reportingCount: 5, adoptionCount: 5, tip: '+218', trustPoint: '+5', },
        { category: { icon: 'mdi-emoticon-excited-outline', text: 'エンタメ' }, reportingCount: 6, adoptionCount: 6, tip: '+132', trustPoint: '+6', },
        { category: { icon: 'mdi-chart-line', text: '政治・経済' }, reportingCount: 0, adoptionCount: 0, tip: '0', trustPoint: '0', },
        { category: { icon: 'mdi-shoe-print', text: 'その他' }, reportingCount: 1, adoptionCount: 0, tip: '0', trustPoint: '-10', },
      ],
      isFollowing: false,
    }),
    computed: {
      isSelf () {
        return !this.$route.params.id || this.$route.params.id === this.eth.eoa
      },
    },
    created () {
      this.db = this.$fb.firestore
      this.eth = this.$store.state.eth
      this.init()
    },
    mounted () {
      this.$vuetify.goTo(0)
    },
    methods: {
      push () {
        console.log(this.$route, this.$router)
        this.$router.push({ path: `${this.$route.path}/predictions` });
      },
      async init () {
        let userId = this.$route.params.id
        if (!userId) {
          userId = this.eth.eoa
        }
        const userPromise = this.$fb.getDoc(this.$fb.doc(this.db, 'users', userId).withConverter(this.userConverter))
        const instance = await window.token.deployed()
        const ethPromise = instance.balanceOf(userId)
        Promise.all([userPromise, ethPromise])
        .then(resultArray => {
          const usersDoc = resultArray[0]
          const tokenBalances = resultArray[1]
          const userData = usersDoc.data()
          this.user = {
            id: userData.id,
            avatarUrl: userData.avatarUrl ? userData.avatarUrl : '',
            avatarDownloadUrl: '',
            lastLoginAt: userData.lastLoginAt,
            lastViewAt: userData.lastViewAt,
            message: userData.message,
            nickname: userData.nickname,
            tokenBalance: tokenBalances.words[0] / 10
          }
          this.setDownloadUrl(this.user.avatarUrl)
        }).catch(error => {
          console.error(error)
        })
        if (!this.isSelf) {
          const followRef = this.$fb.collection(this.db, 'follow').withConverter(this.followConverter)
          this.$fb.getDocs(this.$fb.query(followRef,
            this.$fb.where('user_id', '==', this.$route.params.id),
            this.$fb.where('follower_id', '==', this.eth.eoa)))
            .then(followSnap => {
              this.isFollowing = followSnap.size > 0
            })
        }
      },
      setDownloadUrl (path) {
        if (!path) return this.user.avatarDownloadUrl = this.defaultAvatar
        const imageRef = this.$fb.ref(this.$fb.storage, path)
        this.$fb.getDownloadURL(imageRef)
          .then(url => { 
            this.user.avatarDownloadUrl = url 
          })
      },
      edit () {
        this.user.newAvatarUrl = this.user.avatarUrl
        this.user.newAvatarDownloadUrl = this.user.avatarDownloadUrl
        this.user.newNickname = this.user.nickname
        this.user.newMessage = this.user.message
        this.isEdit = true
      },
      save () {
        if (this.user.newAvatar) {
          const extension = this.user.newAvatar.name.substring(this.user.newAvatar.name.indexOf('.'))
          const path = `profile_images/${this.eth.eoa}${extension}`
          const imageRef = this.$fb.ref(this.$fb.storage, path)
          this.$fb.uploadBytesResumable(imageRef, this.user.newAvatar)
          this.user.newAvatarUrl = path
        } else if (!this.user.newAvatarUrl) {
          const extension = this.user.avatarUrl.substring(this.user.avatarUrl.lastIndexOf('.'))
          const path = `profile_images/${this.eth.eoa}${extension}`
          const imageRef = this.$fb.ref(this.$fb.storage, path)
          this.$fb.deleteObject(imageRef).catch(() => {})
        }

        const usersRef = this.$fb.doc(this.db, 'users', this.user.id)
        const now = new Date()
        this.$fb.updateDoc(usersRef, {
          avatar_url: this.user.newAvatarUrl,
          nickname: this.user.newNickname,
          message: this.user.newMessage,
          updated_at: now,
        }).then(() => {
          this.isEdit = false
          this.snackbarSuccessText = 'プロフィールを保存しました。'
          this.snackbarSuccess = true
          this.$store.dispatch('registerUser', this.eth.eoa)
          this.init()
        })
      },
      imageChanged (selectedFile) {
        const extension = selectedFile.name.substring(selectedFile.name.indexOf('.'))
        const path = `profile_images/temp/${this.eth.eoa}${extension}`
        const imageRef = this.$fb.ref(this.$fb.storage, path)
        this.$fb.uploadBytesResumable(imageRef, selectedFile)
        .then(snapshot => {
          this.user.newAvatarUrl = path
          this.$fb.getDownloadURL(snapshot.ref)
          .then(url => {
            this.user.newAvatarDownloadUrl = url
            this.componentKey++
          })
        })
      },
      deleteImage () {
        const extension = this.user.newAvatarUrl.substring(this.user.newAvatarUrl.lastIndexOf('.'))
        const path = `profile_images/temp/${this.eth.eoa}${extension}`
        const imageRef = this.$fb.ref(this.$fb.storage, path)
        this.$fb.deleteObject(imageRef)
        .then(() => {
          this.user.newAvatarUrl = ''
          this.user.newAvatarDownloadUrl = this.defaultAvatar
          this.componentKey++
        }).catch(() => {
          this.user.newAvatarUrl = ''
          this.user.newAvatarDownloadUrl = this.defaultAvatar
          this.componentKey++
        })
      },
      follow () {
        this.$fb.addDoc(this.$fb.collection(this.db, 'follow'), {
          user_id: this.$route.params.id,
          follower_id: this.eth.eoa,
        }).then(() => {
          this.isFollowing = true
          this.snackbarSuccessText = 'フォローしました。'
          this.snackbarSuccess = true
        })
      },
      unfollow () {
        const followRef = this.$fb.collection(this.db, 'follow').withConverter(this.followConverter)
        this.$fb.getDocs(this.$fb.query(followRef,
          this.$fb.where('user_id', '==', this.$route.params.id),
          this.$fb.where('follower_id', '==', this.eth.eoa)))
          .then(followSnap => {
            followSnap.forEach(followDoc => {
              this.$fb.deleteDoc(followDoc.ref).then(() => {
                this.isFollowing = false
                this.snackbarSuccessText = 'フォローを解除しました。'
                this.snackbarSuccess = true
              })
            })
          })
      },
    },
  }
</script>
