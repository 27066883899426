import Vue from 'vue'
import VueRouter from 'vue-router'
import OpenPrediction from '@/views/OpenPrediction.vue'
import ReportingPrediction from '@/views/ReportingPrediction.vue'
import FinalizedPrediction from '@/views/FinalizedPrediction.vue'
import AllPrediction from '@/views/AllPrediction.vue'
import TipRanking from '@/views/TipRanking.vue'
import DailyRanking from '@/views/DailyRanking.vue'
import WeeklyRanking from '@/views/WeeklyRanking.vue'
import MonthlyRanking from '@/views/MonthlyRanking.vue'
import YearlyRanking from '@/views/YearlyRanking.vue'
import Inbox from '@/views/Inbox.vue'
import WatchList from '@/views/WatchList.vue'
import MyPrediction from '@/views/MyPrediction.vue'
import PredictionScore from '@/views/PredictionScore.vue'
import FollowingList from '@/views/FollowingList.vue'
import FollowerList from '@/views/FollowerList.vue'
import Profile from '@/views/Profile.vue'
import Home from '@/views/Home.vue'
import HowToPlay from '@/views/HowToPlay.vue'
import PrivacyPolicy from '@/views/PrivacyPolicy.vue'
import TermsOfUse from '@/views/TermsOfUse.vue'
import Inquiry from '@/views/Inquiry.vue'
import InquiryComplete from '@/views/InquiryComplete.vue'
import Login from '@/views/Login.vue'
import Help from '@/views/Help.vue'
import SearchResult from '@/views/SearchResult.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/how-to-play',
    name: 'howToPlay',
    component: HowToPlay
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/terms-of-use',
    name: 'termsOfUse',
    component: TermsOfUse
  },
  {
    path: '/inquiry',
    name: 'inquiry',
    component: Inquiry
  },
  {
    path: '/inquiry-complete',
    name: 'inquiryComplete',
    component: InquiryComplete
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/help',
    name: 'help',
    component: Help
  },
  {
    path: '/search-result',
    name: 'search-result',
    component: SearchResult
  },
  {
    path: '/search-result/:id',
    name: 'search-result/id',
    component: SearchResult
  },
  {
    path: '/open-prediction',
    name: 'open-prediction',
    component: OpenPrediction
  },
  {
    path: '/open-prediction/:id',
    name: 'open-prediction/id',
    component: OpenPrediction
  },
  {
    path: '/reporting-prediction',
    name: 'reporting-prediction',
    component: ReportingPrediction
  },
  {
    path: '/reporting-prediction/:id',
    name: 'reporting-prediction/id',
    component: ReportingPrediction
  },
  {
    path: '/finalized-prediction',
    name: 'finalized-prediction',
    component: FinalizedPrediction
  },
  {
    path: '/finalized-prediction/:id',
    name: 'finalized-prediction/id',
    component: FinalizedPrediction
  },
  {
    path: '/all-prediction',
    name: 'all-prediction',
    component: AllPrediction
  },
  {
    path: '/all-prediction/:id',
    name: 'all-prediction/id',
    component: AllPrediction
  },
  {
    path: '/tip-ranking',
    name: 'tip-ranking',
    component: TipRanking
  },
  {
    path: '/daily-ranking',
    name: 'daily-ranking',
    component: DailyRanking
  },
  {
    path: '/weekly-ranking',
    name: 'weekly-ranking',
    component: WeeklyRanking
  },
  {
    path: '/monthly-ranking',
    name: 'monthly-ranking',
    component: MonthlyRanking
  },
  {
    path: '/yearly-ranking',
    name: 'yearly-ranking',
    component: YearlyRanking
  },
  {
    path: '/watch-list',
    name: 'watch-list',
    component: WatchList
  },
  {
    path: '/watch-list/:id',
    name: 'watch-list/id',
    component: WatchList
  },
  {
    path: '/inbox',
    name: 'inbox',
    component: Inbox
  },
  {
    path: '/my-prediction',
    name: 'my-prediction',
    component: MyPrediction
  },
  {
    path: '/my-prediction/:id',
    name: 'my-prediction/id',
    component: MyPrediction
  },
  {
    path: '/prediction-score',
    name: 'prediction-score',
    component: PredictionScore
  },
  {
    path: '/following-list',
    name: 'following-list',
    component: FollowingList
  },
  {
    path: '/follower-list',
    name: 'follower-list',
    component: FollowerList
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile
  },
  {
    path: '/profile/:id',
    name: 'profile/id',
    component: Profile
  },
  {
    path: '/profile/:userId/predictions',
    name: 'profile/userId/predictions',
    component: MyPrediction
  },
  {
    path: '/profile/:userId/predictions/:id',
    name: 'profile/userId/predictions/id',
    component: MyPrediction
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
