<template>
  <v-main class="background">
    <v-snackbar color="success" v-model="snackbarSuccess">
      <v-list-item-title>
        <v-icon class="mr-2">mdi-check-circle-outline</v-icon>
        {{ snackbarSuccessText }}
      </v-list-item-title>
    </v-snackbar>
    <v-dialog v-model="reportDialog" width="400">
      <v-card>
        <v-card-title>ユーザーの報告</v-card-title>
        <v-card-text>悪質なユーザーとして報告しますか？</v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text color="indigo" @click="reportDialog=false">キャンセル</v-btn>
          <v-btn text color="indigo" @click="reportUser">報告</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container fluid>
      <v-row justify="center" align="center">
        <v-col cols="12">
          <p>フォロワーリスト</p>
          <v-card>
            <v-data-table
              hide-default-header=""
              :headers="headers"
              :items="items"
            >
              <template v-slot:[`item.following`]="{ item }">
                <v-list>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-avatar
                        size="32px"
                        item
                      >
                        <v-img
                          :src="item.avatarDownloadUrl"
                          alt="avatar"
                        />
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-menu offset-y>
                          <template v-slot:activator="{on, attrs}">
                            <a v-bind="attrs" v-on="on">{{ item.nickname }}</a>
                          </template>
                          <UserContextMenu :user-id="item.id" @showReportDialog="reportDialog=true"/>
                        </v-menu>
                      </v-list-item-title>
                      <v-list-item-subtitle>{{ item.message }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </template>
              <template v-slot:[`item.isFollowing`]="{ item }">
                <template v-if="item.isFollowing">
                  <v-btn @click="unfollow(item)">フォロー解除</v-btn>
                </template>
                <template v-else>
                  <v-btn color="primary" @click="follow(item)">フォロー</v-btn>
                </template>
              </template>
            </v-data-table>
          </v-card>
          <br/>
          <Ads2/>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
  import mixin from '@/util/mixin'
  import Ads2 from '@/components/Ads2.vue'
  import UserContextMenu from '@/components/UserContextMenu'

  export default {
    mixins: [mixin],
    components: {
      Ads2,
      UserContextMenu,
    },
    data: () => ({
      headers: [
        { text: 'フォローしているユーザー', value: 'following' },
        { text: 'アクション', value: 'isFollowing' },
      ],
      items: [],
      snackbarSuccess: false,
      snackbarSuccessText: null,
      db: null,
      eth: null,
      reportDialog: false,
    }),
    created () {
      this.db = this.$fb.firestore
      this.eth = this.$store.state.eth
      this.init()
    },
    mounted () {
      this.$vuetify.goTo(0)
    },
    methods: {
      reportUser () {},
      async init () {
        const followRef = this.$fb.collection(this.db, 'follow').withConverter(this.followConverter)
        const followSnap = await this.$fb.getDocs(this.$fb.query(followRef,
          this.$fb.where('follower_id', '==', this.eth.eoa)))
        const follows = []
        followSnap.forEach(followDoc => {
          const followData = followDoc.data()
          follows.push(followData.userId)
        })
        const followerSnap = await this.$fb.getDocs(this.$fb.query(followRef,
          this.$fb.where('user_id', '==', this.eth.eoa)))
        const followers = []
        followerSnap.forEach(followerDoc => {
          const followerData = followerDoc.data()
          followers.push(followerData)
        })
        const items = []
        for (const follower of followers) {
          let userData = await this.getUser(follower.followerId, follows)
          userData = await this.addDownloadUrl(userData)
          items.push(userData)
        }
        this.items = items
      },
      async getUser (userId, follows) {
        return this.$fb.getDoc(this.$fb.doc(this.db, 'users', userId).withConverter(this.userConverter))
          .then(userDoc => {
            const userData = userDoc.data()
            userData.isFollowing = follows.includes(userData.id)
            return userData
          })
      },
      async addDownloadUrl (userData) {
        if (!userData.avatarUrl) return userData.avatarDownloadUrl = this.defaultAvatar
        const imageRef = this.$fb.ref(this.$fb.storage, userData.avatarUrl)
        return this.$fb.getDownloadURL(imageRef)
          .then(url => { 
            userData.avatarDownloadUrl = url 
            return userData
          })
      },
      follow (item) {
        this.$fb.addDoc(this.$fb.collection(this.db, 'follow'), {
          user_id: item.id,
          follower_id: this.eth.eoa,
        }).then(() => {
          item.isFollowing = true
          this.snackbarSuccessText = 'フォローしました。'
          this.snackbarSuccess = true
        })
      },
      unfollow (item) {
        const followRef = this.$fb.collection(this.db, 'follow').withConverter(this.followConverter)
        this.$fb.getDocs(this.$fb.query(followRef,
          this.$fb.where('user_id', '==', item.id),
          this.$fb.where('follower_id', '==', this.eth.eoa)))
          .then(followSnap => {
            followSnap.forEach(followDoc => {
              this.$fb.deleteDoc(followDoc.ref).then(() => {
                item.isFollowing = false
                this.snackbarSuccessText = 'フォローを解除しました。'
                this.snackbarSuccess = true
              })
            })
          })
      },
    },
  }
</script>
