<template>
  <div>
    <v-snackbar color="success" v-model="snackbarSuccess">
      <v-list-item-title>
        <v-icon class="mr-2">mdi-check-circle-outline</v-icon>
        {{ snackbarSuccessText }}
      </v-list-item-title>
    </v-snackbar>
    <v-snackbar v-model="snackbar">
      {{ snackbarText }}
      <v-btn text @click="snackbar = false">
        <v-icon>mdi-close-circle-outline</v-icon>
      </v-btn>
    </v-snackbar>
    <v-dialog v-model="deleteReplyDialog" width="300">
      <v-card>
        <v-card-title>返信の削除</v-card-title>
        <v-card-text>返信を完全に削除しますか？</v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text color="indigo" @click="cancelDeleteReply">キャンセル</v-btn>
          <v-btn text color="indigo" @click="deleteReply">削除</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="reportReplyDialog" width="400">
      <v-card>
        <v-card-title>返信の報告</v-card-title>
        <v-card-text>不適切な返信として報告しますか？</v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text color="indigo" @click="cancelReportReply">キャンセル</v-btn>
          <v-btn text color="indigo" @click="reportReply">報告</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-menu offset-y :close-on-content-click="false" :close-on-click="closeOnClick">
      <template v-slot:activator="{on, attrs}">
        <a @click="showReply(comment)" v-bind="attrs" v-on="on">
          {{comment.replyCount}}件の返信
        </a>
      </template>
      <template v-if="comment.reply">
        <v-list three-line :key="componentKey">
          <template v-for="reply in comment.replyList">
            <v-hover v-slot="{ hover }" v-bind:key="reply.id">
              <v-list-item class="align-start">
                <v-list-item-avatar size="30">
                  <v-img class="align-start" :src="reply.avatarUrl"></v-img>
                </v-list-item-avatar>
                <template v-if="reply.isEdit">
                  <v-list-item-content>
                    <v-textarea auto-grow rows="1" v-model="reply.newContent"></v-textarea>
                    <v-container>
                      <v-row>
                        <v-menu offset-y>
                          <template v-slot:activator="{on, attrs}">
                            <v-btn text icon v-bind="attrs" v-on="on"><v-icon>mdi-emoticon-excited-outline</v-icon></v-btn>
                          </template>
                          <div class="emojiRow justify-start">
                            <Picker :data="emojiIndex" set="twitter" @select="showEmojiReply($event, reply)" />
                          </div>
                        </v-menu>
                        <v-spacer />
                        <v-btn small outlined class="mr-4" @click="cancelEditReply(reply)">キャンセル</v-btn>
                        <v-btn small color="primary" @click="updateReply(comment, reply)">保存</v-btn>
                      </v-row>
                    </v-container>
                  </v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      <span class="font-weight-bold">{{reply.nickname}}</span>
                      <span class="ml-2 text-caption">{{reply.dispTime}}</span>
                    </v-list-item-subtitle>
                    {{reply.content}}
                    <v-list-item-subtitle>
                      <v-btn text icon color="grey" @click="likeReply(comment, reply)">
                        <template v-if="reply.like && reply.like.indexOf(eth.eoa) > -1">
                          <v-icon small color="indigo">mdi-thumb-up</v-icon>
                        </template>
                        <template v-else>
                          <v-icon size="18">mdi-thumb-up-outline</v-icon>
                        </template>
                      </v-btn>
                      <template v-if="reply.like && reply.like.length > 0">
                        {{reply.like.length}}
                      </template>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-menu offset-y>
                      <template v-slot:activator="{on, attrs}">
                        <v-btn text icon v-show="hover" v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list dense>
                        <template v-if="reply.userId===eth.eoa">
                          <v-list-item>
                            <v-list-item-action-text>
                              <v-btn text @click="editReply(reply)"><v-icon class="mr-2">mdi-comment-edit-outline</v-icon>編集</v-btn>
                            </v-list-item-action-text>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-action-text>
                              <v-btn text @click="showDeleteReplyDialog(comment, reply)"><v-icon class="mr-2">mdi-comment-remove-outline</v-icon>削除</v-btn>
                            </v-list-item-action-text>
                          </v-list-item>
                        </template>
                        <template v-else>
                          <v-list-item>
                            <v-list-item-action-text>
                              <v-btn text @click="showReportReplyDialog(comment, reply)"><v-icon class="mr-2">mdi-flag-outline</v-icon>報告</v-btn>
                            </v-list-item-action-text>
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-menu>
                  </v-list-item-action>
                </template>
              </v-list-item>
            </v-hover>
          </template>
        </v-list>
      </template>
      <template v-else>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                Loading...
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-menu>
  </div>
</template>

<script>
  import mixin from '@/util/mixin'
  import data from 'emoji-mart-vue-fast/data/all.json'
  import 'emoji-mart-vue-fast/css/emoji-mart.css'
  import {Picker, EmojiIndex} from 'emoji-mart-vue-fast'

  let emojiIndex = new EmojiIndex(data)

  export default {
    mixins: [mixin],
    components: {
      Picker,
    },
    props: {
      predictionIdPromise: Promise,
      now: Date,
      parentComment: Object,
    },
    data: () => ({
      componentKey: 0,
      closeOnClick: true,
      snackbar: false,
      snackbarText: null,
      snackbarSuccess: false,
      snackbarSuccessText: null,
      isLiked: false,
      editComment: false,
      newComment: '',
      emojiIndex: emojiIndex,
      isGoodPrediction: false,
      loginUser: {
        avatarUrl: 'https://ca.slack-edge.com/T5654U2GP-U56MK2UQM-e6a64af42f4c-512',
      },
      comment: {},
      db: null,
      eth: null,
      defaultAvatar: require('@/assets/user_icon.png'),
      deleteDialog: false,
      deleteReplyDialog: false,
      reportDialog: false,
      reportReplyDialog: false,
      selectedComment: null,
      selectedReply: null,
    }),
    methods: {
      showDeleteReplyDialog (comment, reply) {
        this.selectedComment = comment
        this.selectedReply = reply
        this.closeOnClick = false
        this.deleteReplyDialog = true
      },
      showReportReplyDialog (comment, reply) {
        this.selectedComment = comment
        this.selectedReply = reply
        this.closeOnClick = false
        this.reportReplyDialog = true
      },
      cancelDeleteReply () {
        this.closeOnClick = true
        this.deleteReplyDialog=false
      },
      cancelReportReply () {
        this.closeOnClick = true
        this.reportReplyDialog=false
      },
      deleteReply () {
        this.closeOnClick = true
        this.deleteReplyDialog = false
        this.predictionIdPromise
        .then(predictionId => {
          const predictionRef = this.$fb.collection(this.db, 'prediction')
          const commentRef = this.$fb.doc(predictionRef, predictionId, 'comments', this.selectedComment.id)
          const replyRef = this.$fb.doc(predictionRef, predictionId, 'comments', this.selectedComment.id, 'reply', this.selectedReply.id)
          const commentPromise = this.$fb.updateDoc(commentRef, {
            reply_count: this.selectedComment.replyCount ? this.selectedComment.replyCount - 1 : 0
          })
          const replyPromise = this.$fb.deleteDoc(replyRef)
          return Promise.all([commentPromise, replyPromise])
        }).then(() => {
          this.selectedComment.replyCount = this.selectedComment.replyCount ? this.selectedComment.replyCount - 1 : 0
          this.snackbarSuccessText = '削除しました。'
          this.snackbarSuccess = true
          this.showReply(this.selectedComment)
        })
      },
      reportReply () {
        this.closeOnClick = true
        this.reportReplyDialog = false
        this.predictionIdPromise
        .then(predictionId => {
          const predictionRef = this.$fb.collection(this.db, 'prediction')
          const replyRef = this.$fb.doc(predictionRef, predictionId, 'comments', this.selectedComment.id, 'reply', this.selectedReply.id)
          const bad = this.selectedReply.bad ? this.selectedReply.bad : []
          bad.push(this.eth.eoa)
          this.$fb.updateDoc(replyRef, {
            bad: bad,
          }).then(() => {
            this.snackbarSuccessText = '報告しました。'
            this.snackbarSuccess = true
          })
        })
      },
      editReply (reply) {
        reply.newContent = reply.content
        reply.isEdit = true
        this.componentKey += 1
      },
      showEmojiReply (emoji, reply) {
        reply.newContent += emoji.native
      },
      cancelEditReply (reply) {
        reply.isEdit = false
        this.componentKey += 1
      },
      updateReply (comment, reply) {
        this.predictionIdPromise
        .then(predictionId => {
          const predictionRef = this.$fb.collection(this.db, 'prediction')
          const replyRef = this.$fb.doc(predictionRef, predictionId, 'comments', comment.id, 'reply', reply.id)
          const now = new Date()
          this.$fb.updateDoc(replyRef, {
            content: reply.newContent,
            updated_at: now,
          }).then(() => {
            reply.newContent = ''
            this.showReply(comment)
            this.cancelEditReply(reply)
          })
        })
      },
      likeReply (comment, reply) {
        this.predictionIdPromise
        .then(predictionId => {
          const predictionRef = this.$fb.collection(this.db, 'prediction')
          const replyRef = this.$fb.doc(predictionRef, predictionId, 'comments', comment.id, 'reply', reply.id)
          let like = reply.like ? reply.like : []
          if (like.indexOf(this.eth.eoa) > -1) {
            like.splice(like.indexOf(this.eth.eoa), 1)
          } else {
            like.push(this.eth.eoa)
          }
          this.$fb.updateDoc(replyRef, {
            like: like
          }).then(() => {
            reply.like = like
            this.componentKey++
          })
        })
      },
      showReply (comment) {
        comment.reply = false
        this.getReplySnapPromise(comment)
        .then(replySnapshot => {
          const replies = []
          replySnapshot.forEach(replyDoc => {
            replies.push(replyDoc.data())
          })
          replies.sort((a, b) => {
            return b.createdAt.getTime() - a.createdAt.getTime()
          })
          this.setReply(comment, replies)
        })
      },
      async getReplySnapPromise (comment) {
        const predictionId = await this.predictionIdPromise
        const predictionRef = this.$fb.collection(this.db, 'prediction')
        const replyRef = this.$fb.collection(predictionRef, predictionId, 'comments', comment.id, 'reply').withConverter(this.replyConverter)
        return this.$fb.getDocs(replyRef)
          .then(replySnap => {
            return Promise.resolve(replySnap)
          })
          .catch(error => {
            this.snackbarText = '返信の取得に失敗しました。'
            this.snackbar = true
            return Promise.reject(error)
          })
      },
      async setReply (comment, replies) {
        const replyList = []
        for (const replyData of replies) {
          const usersDoc = await this.$fb.getDoc(this.$fb.doc(this.db, 'users', replyData.userId).withConverter(this.userConverter))
          const usersData = usersDoc.data()
          let avatarUrl = this.defaultAvatar
          if (usersData.avatarUrl) {
            const imageRef = this.$fb.ref(this.$fb.storage, usersData.avatarUrl)
            const url = await this.$fb.getDownloadURL(imageRef)
            avatarUrl = url 
          }
          replyList.push({
            id: replyData.id,
            avatarUrl: avatarUrl,
            userId: replyData.userId,
            nickname: usersData.nickname,
            dispTime: this.getPassed(replyData.createdAt),
            content: replyData.content,
            like: replyData.like,
            newReply: '',
            isEdit: false,
          })            
        }
        comment.replyList = replyList
        comment.reply = true
      },
    },
    watch: {
      parentComment () {
        this.comment = this.parentComment
      }
    },
    created () {
      this.db = this.$fb.firestore
      this.eth = this.$store.state.eth
      this.comment = this.parentComment
    }, 
  }
</script>
<style scoped>
  .emojiRow {
    display: flex;
  }
</style>