<template>
  <v-main class="background">
    <v-container fluid>
      <v-row justify="center" align="center">
        <v-col cols="12">
          <p>開催中の予想</p>
          <Prediction 
            :prediction-id-promise="predictionIdPromise" 
            :now="now"
          />
          <br/>
          <Ads1/>
          <br/>
          <OtherPredictions 
            :is-open="true" 
            :prediction-id-promise="predictionIdPromise" 
            :now="now"
          />
          <br/>
          <Ads2/>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
  import mixin from '@/util/mixin'
  import Prediction from '@/components/Prediction.vue'
  import OtherPredictions from '@/components/OtherPredictions.vue'
  import Ads1 from '@/components/Ads1.vue'
  import Ads2 from '@/components/Ads2.vue'

  export default {
    mixins: [mixin],
    components: {
      Prediction,
      OtherPredictions,
      Ads1,
      Ads2,
    },
    data: () => ({
      now: new Date(),
      db: null,
      predictionIdPromise: null,
    }),
    created () {
      this.db = this.$fb.firestore
      this.predictionIdPromise = this.getPreditionIdPromise()
    },
    mounted () {
      this.$vuetify.goTo(0)
    },
    methods: {
      getPreditionIdPromise () {
        let tradingPredictionIds = []
        let openPredictions = []
        const eoa = this.$store.state.eth.eoa
        let predictionId = this.$route.params.id
        if (predictionId) return Promise.resolve(predictionId)
        const answerRef = this.$fb.collection(this.db, 'answer').withConverter(this.answerConverter)
        return this.$fb.getDocs(this.$fb.query(answerRef,
          this.$fb.where('user_id', '==', eoa),
          this.$fb.where('is_finalized', '==', false)))
          .then(answerSnap => {
            answerSnap.forEach(answerDoc => {
              tradingPredictionIds.push(answerDoc.data().predictionId)
            })
            const predictionRef = this.$fb.collection(this.db, 'prediction').withConverter(this.predictionConverter)
            return this.$fb.getDocs(this.$fb.query(predictionRef,
              this.$fb.where('close_at', '>=', this.now),
              this.$fb.where('is_published', '==', true),
              this.$fb.where('is_invalid', '==', false),
              this.$fb.where('is_finalized', '==', false),
              this.$fb.orderBy('close_at'),
              this.$fb.limit(10)))
          }).then(predictionSnap => {
            predictionSnap.forEach(predictionDoc => {
              if (predictionDoc.data().openAt <= this.now) {
                if (tradingPredictionIds.indexOf(predictionDoc.id) == -1) {
                  openPredictions.push(predictionDoc.id)
                }
              }
            })
            if (openPredictions.length == 0) {
              return Promise.reject(new Error('開催中の予想が見つかりませんでした。'))
            } else {
              const index = Math.floor(Math.random() * openPredictions.length)
              return Promise.resolve(openPredictions[index])
            }
          }).catch(error => {
            return Promise.reject(error)
          })
      },
    },
  }
</script>
