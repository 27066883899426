<template>
  <v-app id="inspire">
    <template v-if="['/how-to-play', '/privacy-policy', '/terms-of-use', '/inquiry', '/inquiry-complete'].includes($route.fullPath)">
    </template>
    <template v-else-if="$route.fullPath === '/' || $route.fullPath === '/login' || $route.fullPath === '/help'">
      <LoginMenu/>
    </template>
    <template v-else>
      <Menu/>
    </template>
    <router-view :key="$route.fullPath" @show-login-dialog="loginDialog = true"/>
    <LoginDialog :dialog="loginDialog" @close-dialog="loginDialog = false"/>
  </v-app>
</template>

<script>
  import Menu from '@/components/Menu.vue'
  import LoginMenu from '@/components/LoginMenu.vue'
  import LoginDialog from '@/components/LoginDialog.vue'
  import HowToPlay from '@/views/HowToPlay.vue'

  export default {
    components: {
      Menu,
      LoginMenu,
      LoginDialog,
      HowToPlay
    },
    data: () => ({
      loginDialog: false,
    }),
  }
</script>
