import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import state from '@/plugins/state'
import fb from '@/util/firebase'
import TruffleContract from '@truffle/contract'
import TipToken from '@/assets/TipToken'
import TipFaucet from '@/assets/TipFaucet'
import mixin from '@/util/mixin'

Vue.use(Vuex)

const categoryPromise = new Promise(function (resolve, reject) {
  fb.onAuthStateChanged(fb.auth, function(user) {
    if (user) {
      fb.getDocs(fb.collection(fb.firestore, 'category'))
      .then(categorySnap => {
        let category = []
        categorySnap.forEach(categoryDoc => {
          category[categoryDoc.id] = categoryDoc.data()
        })
        resolve(category)
      })
    } else {
      reject(new Error('Not logged in.'))
    }
  })
})

window.token = TruffleContract(TipToken)
window.faucet = TruffleContract(TipFaucet)

const fetchBalance = function (eoa) {
  return new Promise(function (resolve, reject) {
    window.token.setProvider(window.web3.currentProvider)
    return window.token.deployed()
      .then(instance => {
        return instance.balanceOf(eoa)
          .then(tokenBalances => {
            var eth = {}
            eth.eoa = eoa
            eth.tokenBalance = tokenBalances.words[0] / 10
            return resolve(eth)
          }).catch(err => {
            return reject(err)
          })
      }).catch(err => {
        return reject(err)
      })
  })
}

const store = new Vuex.Store({
  strict: true,
  state: state,
  mutations: {
    doRegisterCategory (state, payload) {
      state.category = payload
    },
    doRegisterWallet (state, payload) {
      state.wallet = payload
    },
    doRegisterEth (state, payload) {
      state.eth = payload
    },
    doRegisterUser (state, payload) {
      state.user = payload
    }
  },
  actions: {
    registerUser ({commit}, eoa) {
      return fetchBalance(eoa)
      .then(eth => {
        commit('doRegisterEth', eth)
        return fb.getDoc(fb.doc(fb.firestore, 'users', eth.eoa))
        .then(usersDoc => {
          let user = usersDoc.data()
          const now = new Date()
          if (user) {
            user.last_login_at = now
            fb.updateDoc(usersDoc.ref, {
              last_login_at: now
            })
            if (user.last_view_at) {
              user.last_view_at = new Date(user.last_view_at.seconds * 1000)
            } else {
              user.last_view_at = now
            }
          } else {
            console.log('new user')
            user = {
              avatar_url: '',
              message: '',
              nickname: `ユーザー${eth.eoa.substring(2, 6)}`,
              last_login_at: now,
              last_view_at: now,
            }
            fb.setDoc(usersDoc.ref, user)
          }
          if (user.avatar_url) {
            const imageRef = fb.ref(fb.storage, user.avatar_url)
            return fb.getDownloadURL(imageRef)
            .then(url => { 
              user.avatarDownloadUrl = url 
              commit('doRegisterUser', user)
              return Promise.resolve(eth)
            })
          } else {
            user.avatarDownloadUrl = require('@/assets/user_icon.png')
            commit('doRegisterUser', user)
            return Promise.resolve(eth)
          }
        })
        .catch(error => {
          return Promise.reject(error)
        })
      })
    },
    updateBalance ({commit}, eoa) {
      return fetchBalance(eoa)
        .then(eth => {
          commit('doRegisterEth', eth)
          return Promise.resolve(eth)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },
    registerCategory ({commit}) {
      return categoryPromise
        .then(category => {
          commit('doRegisterCategory', category)
          return Promise.resolve(category)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    }
  },
  plugins: [createPersistedState({storage: window.sessionStorage})]
})

export default store
