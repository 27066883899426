<template>
  <v-main>
    <v-container style="max-width: 1140px;">
      <h3 class="py-4" ref="top">遊び方</h3>
      <ul>
        <li><a @click="scrollToAnchorPoint('about')">神様のサイコロとは</a></li>
        <li><a @click="scrollToAnchorPoint('lifecycle')">予想の流れ</a></li>
        <li><a @click="scrollToAnchorPoint('profile')">プロフィールを作成しよう！</a></li>
        <li><a @click="scrollToAnchorPoint('bonus')">デイリーボーナスをもらおう！</a></li>
        <li><a @click="scrollToAnchorPoint('trade')">予想に参加しよう！</a></li>
        <li><a @click="scrollToAnchorPoint('report')">投票に参加しよう！</a></li>
        <li><a @click="scrollToAnchorPoint('comment')">コメント欄で交流しよう！</a></li>
        <li><a @click="scrollToAnchorPoint('prediction')">予想問題を作ってみよう！</a></li>
        <li><a @click="scrollToAnchorPoint('event')">イベントに参加しよう！</a></li>
        <li><a @click="scrollToAnchorPoint('odds')">オッズはどうやって決まるの？</a></li>
        <li><a @click="scrollToAnchorPoint('bad')">不快な予想問題やコメントを見つけたらどうすれば良い？</a></li>
        <li><a @click="scrollToAnchorPoint('origin')">アプリ名の由来は？</a></li>
        <li><a @click="scrollToAnchorPoint('delete')">アカウントを削除するには？</a></li>
        <li><a @click="scrollToAnchorPoint('inquiry')">他に分からないことがあったら？</a></li>
      </ul>
      <h3 class="pt-8 pb-4" ref="about">神様のサイコロとは</h3>
      <p>
        神様のサイコロとはスポーツやエンタメ、政治・経済などの未来を予想して楽しむ安心・安全な予想プラットフォームです。
        <br>
        ユーザーは予想問題を作成したり、答えを予想したり、正解を決めるために投票に参加したりすることができます。
        <br>
        それぞれの行動にはポイントが付与され、獲得したポイントによってランキングが決まるので、上位を目指して頑張りましょう！
      </p>
      <div class="d-flex flex-row justify-end">
        <a @click="scrollToAnchorPoint('top')">TOPに戻る</a>
      </div>
      <h3 class="pt-8 pb-4" ref="lifecycle">予想の流れ</h3>
      <p>
        予想問題を作成してから結果が確定するまでの流れは次の通りです。
      </p>
      <p>
        <v-card>
          <v-card-text>
            予想問題作成
            <br>
            &nbsp;&nbsp;↓
            <br>
            予想開始
            <br>
            &nbsp;&nbsp;↓ 開催中
            <br>
            予想終了＆結果投票開始
            <br>
            &nbsp;&nbsp;↓ 投票中
            <br>
            結果の事実が確定
            <br>
            &nbsp;&nbsp;↓ 投票中
            <br>
            結果投票終了
            <br>
            &nbsp;&nbsp;↓
            <br>
            異議申し立て終了
            <br>
            <br>
            1. 異議なしの場合
            <br>
            結果確定
            <br>
            &nbsp;&nbsp;↓ 確定済
            <br>
            <br>
            2. 異議ありの場合
            <br>
            再投票開始
            <br>
            &nbsp;&nbsp;↓ 投票中
            <br>
            再投票終了
            <br>
            &nbsp;&nbsp;↓
            <br>
            異議申し立て終了
            <br>
            <br>
            2-1. 異議なしの場合
            <br>
            結果確定
            <br>
            &nbsp;&nbsp;↓ 確定済
            <br>
            <br>
            2-2. 異議ありの場合
            <br>
            予想問題不成立で終了
          </v-card-text>
        </v-card>
      </p>
      <p>
        予想開始・終了、結果投票終了時間は出題者が決めます。予想の結果を投票で決めるため、結果に不服がある場合は異議申し立てを行うことができます。
        異議申し立ては投票終了の1時間後まで受け付けています。異議申し立てが回答者数の10%を超えると再投票となります。
        再投票に参加できるのは正解に投票することによって得られる信用ポイントが上位50%のユーザーです。
        再投票の結果にも回答者数の10%を超える異議が申し立てられた場合は予想問題不成立となります。
      </p>
      <div class="d-flex flex-row justify-end">
        <a @click="scrollToAnchorPoint('top')">TOPに戻る</a>
      </div>
      <h3 class="pt-8 pb-4" ref="profile">プロフィールを作成しよう！</h3>
      <p>
        プロフィールを作成するには画面左上の「<v-icon class="mr-1">mdi-menu</v-icon>」ボタンをタップして「プロフィール」を選択します。
        <br>
        「編集」ボタンをタップすると、アイコンとユーザー名、一言メッセージを編集することができます。
        <br>
        他のユーザーは予想問題のコメント欄やランキングからあなたのプロフィールを閲覧することができます。
      </p>
      <p>
        <v-row>
          <!-- <v-col><v-img src="@/assets/how-to-play/profile1.png" width="300"/></v-col> -->
          <!-- <v-col><v-img src="@/assets/how-to-play/profile2.png" width="300"/></v-col> -->
          <v-col><v-img src="@/assets/how-to-play/profile3.png" width="300"/></v-col>
        </v-row>
      </p>
      <div class="d-flex flex-row justify-end">
        <a @click="scrollToAnchorPoint('top')">TOPに戻る</a>
      </div>
      <h3 class="pt-8 pb-4" ref="bonus">デイリーボーナスをもらおう！</h3>
      <p>
        ボーナスをもらうには画面右上のポイント表示部分をタップしてから「広告を見てボーナスを2倍ゲット」または「ボーナスをゲット」ボタンをタップします。
        <br>
        ボーナスが受け取れるのは1日1回までです。
        <br>
        ※ボーナスが受け取れるようになるとウォレットアイコンに「！」マークが表示されます。
      </p>
      <p>
        <v-row>
          <!-- <v-col><v-img src="@/assets/how-to-play/bonus1.png" width="300"/></v-col> -->
          <v-col><v-img src="@/assets/how-to-play/bonus2.png" width="300"/></v-col>
          <!-- <v-col><v-img src="@/assets/how-to-play/bonus3.png" width="300"/></v-col> -->
        </v-row>
      </p>
      <div class="d-flex flex-row justify-end">
        <a @click="scrollToAnchorPoint('top')">TOPに戻る</a>
      </div>
      <h3 class="pt-8 pb-4" ref="trade">予想に参加しよう！</h3>
      <p>
        予想に参加するには画面下のメニューから「予想問題」をタップした後、「開催中」タブの中から参加したい予想問題を選択します。
        <br>
        次に表示された選択肢の中から正解だと思うものにチェックをつけて使用するポイントを入力します。
        <br>
        予想が的中すると「使用ポイント × オッズ」分のポイントが付与され、外れると使用ポイントは没収されます。
        <br>
        ※オッズは参加者数によって変動し、予想が締め切られた時点で確定します。
        <br>
        最後に「予想問題に回答する」ボタンをタップして回答内容に問題がなければ「OK」をタップします。
        <br>
        予想が締め切られるまでの間は何度でも予想を変更したり取り下げたりすることができます。
      </p>
      <p>
        <v-row>
          <!-- <v-col><v-img src="@/assets/how-to-play/trade1.png" width="300"/></v-col> -->
          <!-- <v-col><v-img src="@/assets/how-to-play/trade2.png" width="300"/></v-col> -->
          <v-col><v-img src="@/assets/how-to-play/trade3.png" width="300"/></v-col>
        </v-row>
      </p>
      <div class="d-flex flex-row justify-end">
        <a @click="scrollToAnchorPoint('top')">TOPに戻る</a>
      </div>
      <h3 class="pt-8 pb-4" ref="report">投票に参加しよう！</h3>
      <p>
        投票に参加するには画面下のメニューから「予想問題」をタップした後、「投票中」タブの中から参加したい予想問題を選択します。
        <br>
        次に表示された選択肢の中から正解だと思うものにチェックをつけます。
        <br>
        最後に「予想結果を投票する」ボタンをタップして投票先に問題がなければ「OK」をタップします。
        <br>
        投票は正解を決めるために行うので、必ず正しい選択肢に投票してください。
        <br>
        あなたの投票先が正解として確定すると、投票者報酬と信用ポイントが付与されます。
        <br>
        もし正解以外の選択肢に投票すると信用ポイントが半分になります。
        <br>
        信用ポイントは予想問題の結果に異議が申し立てられた場合に再投票に参加できるユーザーを決めるために使用されます。
        <br>
        投票はポイントを持っていなくても参加できるので、ポイントを使い果たしてしまったら投票でコツコツ稼ぎましょう！
      </p>
      <p>
        <v-row>
          <!-- <v-col><v-img src="@/assets/how-to-play/report1.png" width="300"/></v-col> -->
          <!-- <v-col><v-img src="@/assets/how-to-play/report2.png" width="300"/></v-col> -->
          <v-col><v-img src="@/assets/how-to-play/report3.png" width="300"/></v-col>
        </v-row>
      </p>
      <div class="d-flex flex-row justify-end">
        <a @click="scrollToAnchorPoint('top')">TOPに戻る</a>
      </div>
      <h3 class="pt-8 pb-4" ref="comment">コメント欄で交流しよう！</h3>
      <p>
        各予想問題の下にはコメント欄があるので、他のユーザーと意見交換をしたりすることができます。
        <br>
        予想をするための参考として読んでみるのも良いですし、自分の予想の根拠を書いて他のユーザーから返信をもらうのも良いですね！
        <br>
        また、予想が締め切られた後も結果について実況中継をしたり、感想を語り合ったりと使い方はあなた次第です！
      </p>
      <div class="d-flex flex-row justify-end">
        <a @click="scrollToAnchorPoint('top')">TOPに戻る</a>
      </div>
      <h3 class="pt-8 pb-4" ref="prediction">予想問題を作ってみよう！</h3>
      <p>
        予想問題を作るには画面下のメニューから「新規作成」をタップした後、画面の指示に従って作成します。
        <br>
        作成した予想問題は「ホーム」メニューの「出題中」タブに表示されます。
        <br>
        予想が始まるまでは何度でも編集できますが、予想が始まると編集ができなくなり、補足を追記できるのみになるので注意してください。
        <br>
        作成した予想問題の結果が確定すると、出題者報酬が付与されます。
        <!-- <br>
        回答数と投票数が一定の基準に満たなかった場合、予想問題は不成立となります。
        <br>
        不成立となった場合、回答者が使用したポイントは払い戻されますが、出題者が使用したポイントは没収されます。 -->
      </p>
      <p>
        <v-row>
          <v-col><v-img src="@/assets/how-to-play/creator1.png" width="300"/></v-col>
          <!-- <v-col><v-img src="@/assets/how-to-play/creator2.png" width="300"/></v-col> -->
        </v-row>
      </p>
      <div class="d-flex flex-row justify-end">
        <a @click="scrollToAnchorPoint('top')">TOPに戻る</a>
      </div>
      <h3 class="pt-8 pb-4" ref="event">イベントに参加しよう！</h3>
      <p>
        イベントに参加するには画面下のメニューから「予想問題」をタップした後、「イベント」タブの中から参加したいイベントを選択します。
        <br>
        イベントページにはイベントの開催概要が表示されるので、内容を確認したら「イベント参加中の予想」をタップして表示された予想問題の中から参加したい予想問題を選択します。
        <br>
        イベントで入賞すると報酬としてイベント作成者からポイントが付与されるので、上位を目指して予想しましょう！
      </p>
      <div class="d-flex flex-row justify-end">
        <a @click="scrollToAnchorPoint('top')">TOPに戻る</a>
      </div>
      <h3 class="pt-8 pb-4" ref="odds">オッズはどうやって決まるの？</h3>
      <p>
        オッズの計算は以下のように行われます。
        <br>
        オッズ ＝ 全回答者の使用ポイントの合計 ÷ その選択肢の回答者の使用ポイントの合計
      </p>
      <div class="d-flex flex-row justify-end">
        <a @click="scrollToAnchorPoint('top')">TOPに戻る</a>
      </div>
      <h3 class="pt-8 pb-4" ref="bad">不快な予想問題やコメントを見つけたらどうすれば良い？</h3>
      <p>
        神様のサイコロでは予想問題とコメントについて通報を行うことができます。
        <br>
        通報を受けると、運営の判断で予想問題やコメントの削除、悪質と判断した場合は該当ユーザーの強制退会等の対処を行います。
        <br>
        また、予想問題の作成者やコメントをしたユーザーのアイコンをタップすると、そのユーザーをブロックすることもできます。
        <br>
        ユーザーをブロックすると、そのユーザーが作成した予想問題やコメントが表示されなくなります。
        <br>
        また、そのユーザーはランキングにも表示されなくなります。
        <br>
        尚、ブロックしたユーザーは、自分のプロフィール画面でブロックを解除できます。
        <br>
        botや生成AIによる出題が邪魔と思われる場合もブロックを活用してみてください。
      </p>
      <div class="d-flex flex-row justify-end">
        <a @click="scrollToAnchorPoint('top')">TOPに戻る</a>
      </div>
      <h3 class="pt-8 pb-4" ref="origin">アプリ名の由来は？</h3>
      <p>
        その昔、かのアインシュタインは「神はサイコロを振らない。」と言ったそうです。
        <br>
        でも、未来に起こることがもう全て決まっていると思うと、今の努力が無駄に思えてきて空しくなってしまいます。
        <br>
        なので、真実はどうあれ「神はサイコロを振る。」と考えた方が賢明ですよね。
        <br>
        神様が振るサイコロの目を予想して楽しむアプリという事で「神様のサイコロ」と名付けました。
      </p>
      <div class="d-flex flex-row justify-end">
        <a @click="scrollToAnchorPoint('top')">TOPに戻る</a>
      </div>
      <h3 class="pt-8 pb-4" ref="delete">アカウントを削除するには？</h3>
      <p>
        アカウントを削除するには画面左上の「<v-icon class="mr-1">mdi-menu</v-icon>」ボタンをタップして「設定」を選択します。
        <br>
        次にアカウント管理の「アカウントを削除」をタップします。
        <br>
        すると、削除確認のダイアログが表示されるので、「はい」をタップすると、アカウントと関連データが削除されます。
      </p>
      <div class="d-flex flex-row justify-end">
        <a @click="scrollToAnchorPoint('top')">TOPに戻る</a>
      </div>
      <h3 class="pt-8 pb-4" ref="inquiry">他に分からないことがあったら？</h3>
      <p>
        ここに書いてあること以外でもし何か分からないことがあれば、<a href="#" @click.prevent="inquiry">問い合わせ画面</a>からお問い合わせをお願いします。
      </p>
      <div class="d-flex flex-row justify-end">
        <a @click="scrollToAnchorPoint('top')">TOPに戻る</a>
      </div>
      <br>
      <br>
      <br>
      <br>
      <br>
    </v-container>
  </v-main>
</template>

<script>
  import mixin from '@/util/mixin'

  export default {
    mixins: [mixin],
    methods: {
      inquiry() {
        this.$router.push({ path: '/inquiry' })
      },
      scrollToAnchorPoint(refName) {
          const el = this.$refs[refName]
          el.scrollIntoView({ behavior: 'smooth'})
      }
    },
  }
</script>

<style scoped>
</style>