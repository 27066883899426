<template>
  <div>
    <v-snackbar color="success" v-model="snackbarSuccess">
      <v-list-item-title>
        <v-icon class="mr-2">mdi-check-circle-outline</v-icon>
        {{ snackbarSuccessText }}
      </v-list-item-title>
    </v-snackbar>
    <v-snackbar v-model="snackbar">
      {{ snackbarText }}
      <v-btn text @click="snackbar = false">
        <v-icon>mdi-close-circle-outline</v-icon>
      </v-btn>
    </v-snackbar>
    <v-dialog
      v-model="dialog"
      width="800px"
      persistent
    >
      <v-card>
        <v-card-title class="primary white--text">
          選択確認
        </v-card-title>
        <v-card-subtitle class="primary white--text">
          {{ confirmSubtitle }}
        </v-card-subtitle>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col col="12">
                <v-data-table
                  hide-default-header
                  hide-default-footer
                  disable-sort
                  :headers="selectedChoiceHeader"
                  :items="selectedChoice"
                >
                </v-data-table>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer />
              <v-btn
                color="secondary"
                @click="dialog = false"
              >キャンセル</v-btn>
              <v-btn 
                color="primary" 
                class="ml-5"
                @click="submit"
              >OK</v-btn>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="cancelDialog"
      width="800px"
      persistent
    >
      <v-card>
        <v-card-title class="primary white--text">
          キャンセル確認
        </v-card-title>
        <v-card-subtitle class="primary white--text">
          以下の内容を取り消します。よろしいですか？
        </v-card-subtitle>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col col="12">
                <v-data-table
                  hide-default-header
                  hide-default-footer
                  disable-sort
                  :headers="selectedChoiceHeader"
                  :items="selectedChoice"
                >
                </v-data-table>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer />
              <v-btn
                color="secondary"
                @click="cancelDialog = false"
              >キャンセル</v-btn>
              <v-btn 
                color="primary" 
                class="ml-5"
                @click="submitCancel"
              >OK</v-btn>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="oppositionDialog"
      width="800px"
      persistent
    >
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col col="12">
                <p class="mb-5">
                  <template v-if="requiredOppositionCount > 0">
                    この予想の結果に異議を申し立てます。<br/>
                    異議申し立てが成立すると、信頼されたユーザーだけで結果投票をやり直すことができます。<br/>
                    異議申し立てが成立するには、あと{{ requiredOppositionCount }}人の異議が必要です。
                  </template>
                  <template v-else>
                    この予想の結果に異議を申し立てます。<br/>
                    異議申し立てが成立すると、信頼されたユーザーだけで結果投票をやり直すことができます。<br/>
                    この予想に対する異議申し立ては既に成立しています。<br/>
                  </template>
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer />
              <v-btn outlined color="secondary" @click="oppositionDialog = false">キャンセル</v-btn>
              <template v-if="!isOpposing">
                <v-btn color="primary" class="ml-5" @click="submitOpposition">異議申し立て</v-btn>
              </template>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="reportDialog" width="400">
      <v-card>
        <v-card-title>ユーザーの報告</v-card-title>
        <v-card-text>悪質なユーザーとして報告しますか？</v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text color="indigo" @click="reportDialog=false">キャンセル</v-btn>
          <v-btn text color="indigo" @click="reportUser">報告</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <template v-if="isNotFound">
      予想が見つかりませんでした。
    </template>
    <template v-else>
      <v-card>
        <v-container>
          <v-row>
            <v-col cols="12" lg="6" mg="12" sm="12">
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-chip color="primary" outlined v-if="isOpen">
                      <v-icon class="mr-2">mdi-timer-outline</v-icon>
                      残り<span class="ml-1 text-h6">{{ getRemaining(this.prediction.closeAt) }}</span>
                    </v-chip>
                    <v-chip color="primary" outlined v-if="isReporting">
                      <v-icon class="mr-2">mdi-timer-outline</v-icon>
                      残り<span class="ml-1 text-h6">{{ getRemaining(this.prediction.reportingTo) }}</span>
                    </v-chip>
                    <v-spacer/>
                    <v-badge overlap :content="goodCount" :color="isGoodPrediction ? 'indigo' : 'grey'">
                      <v-btn text icon color="grey" @click="goodForPredictionClicked()">
                        <template v-if="isGoodPrediction">
                          <v-icon color="indigo">mdi-thumb-up</v-icon>
                        </template>
                        <template v-else>
                          <v-icon>mdi-thumb-up-outline</v-icon>
                        </template>
                      </v-btn>
                    </v-badge>
                    <v-btn class="ml-3" text icon color="grey" @click="watchListClicked()">
                      <template v-if="isWatching">
                        <v-icon color="yellow darken-1">mdi-star</v-icon>
                      </template>
                      <template v-else>
                        <v-icon>mdi-star-outline</v-icon>
                      </template>
                    </v-btn>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-title class="pt-0" style="display: inline-block;">
                <v-icon class="mr-2">{{ prediction.categoryIcon }}</v-icon>
                {{ prediction.predictionTitle }}
              </v-card-title>
              <v-card-subtitle>
                <!-- <span style="white-space: pre;" v-text="prediction.predictionBody"></span> -->
                <span class="prediction-body" v-html="prediction.predictionBody"/>
              </v-card-subtitle>
              <v-card-text>
                <v-row no-gutters class="mb-2">
                  <v-col cols="12" lg="6" md="6" sm="6">
                    開催期間：{{ formatPeriod(this.prediction.openAt, this.prediction.closeAt) }}
                  </v-col>
                  <v-col cols="12" lg="6" md="6" sm="6">
                    結果投票期間：{{ formatPeriod(this.prediction.reportingFrom, this.prediction.reportingTo) }}
                  </v-col>
                  <v-col cols="6" lg="6" md="6" sm="6">
                    出題者報酬率：{{ prediction.creatorRewardRate }}%
                  </v-col>
                  <v-col cols="6" lg="6" md="6" sm="6">
                    結果投票報酬率：{{ prediction.reporterRewardRate }}%
                  </v-col>
                  <v-col cols="6" lg="6" md="6" sm="6">
                    参加人数：{{ prediction.answerCount }}人
                  </v-col>
                </v-row>
                <v-menu offset-y>
                  <template v-slot:activator="{on, attrs}">
                    <!-- <v-chip outlined @click="creatorClicked"> -->
                    <v-chip outlined v-bind="attrs" v-on="on">
                      <v-avatar
                        size="32px"
                        item
                      >
                        <v-img
                          :src="creator.avatarDownloadUrl"
                          alt="avatar"
                        />
                      </v-avatar>
                      <span class="ml-1">{{ creator.nickname }}</span>
                    </v-chip>
                    出題者：
                  </template>
                  <UserContextMenu :user-id="creator.id" @showReportDialog="reportDialog=true"/>
                </v-menu>
                <template v-if="isOpen">
                  <a class="ml-4" @click="showOppositionDialog">通報する</a>
                </template>
                <template v-else-if="isReporting || isSecondReporting || isFinalizing">
                  <a class="ml-4" @click="showOppositionDialog">結果に異議を申し立てる。</a>
                </template>
              </v-card-text>
            </v-col>
            <v-col cols="12" lg="6" mg="12" sm="12">          
              <Comments
                :prediction-id-promise="predictionIdPromise"
                :now="now"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-card-text>
          <v-data-iterator
            item-key="choice.no"
            :items="items"
            :single-select="isOpen || isReporting || isSecondReporting || isFinalized"
            :value="selected"
            hide-default-footer
          >
            <template v-slot:default="{ items, isSelected }">
              <v-row>
                <v-col
                  v-for="item in items"
                  :key="item.choice.no"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                >
                  <v-card outlined>
                    <v-list desnse :class="tableRowClass(item, isSelected(item))">
                      <v-list-item>
                        <v-list-item-action v-if="isOpen || isReporting || isSecondReporting || isFinalized">
                          <v-simple-checkbox :value="isSelected(item)" @input="toggleRow(item)" />
                        </v-list-item-action>
                        <v-list-item-content>
                          <h4>{{ item.choice.no + '. ' + item.choice.text }}</h4>
                          <template v-if="isReporting || isSecondReporting || isFinalized">
                            {{ item.reportCount + '人 / ' }}
                          </template>
                          {{ item.odds }}倍
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-card-text>
        <v-card-text>
          <template v-if="isOpen || isTrader">
            <v-text-field
              v-model="answerAmount"
              label="使用チップ"
              :hint="ownedTip"
              type="number"
              :rules="rules"
              :readonly="isTrader || isCreator"
              style="width: 150px;"
            ></v-text-field>
          </template>
          <v-card-actions>
            <template v-if="isReporting || isSecondReporting">
              <template v-if="isReporter">
                <v-spacer />
                <v-btn right outlined color="secondary" @click="cancelReport">取消</v-btn>
                <v-btn right color="primary" class="ml-5" @click="confirmReport">変更</v-btn>
              </template>
              <template v-else>
                <v-spacer />
                <v-btn right :disabled="isTrader" color="primary" @click="confirmReport">投票</v-btn>
              </template>
            </template>
            <template v-else>
              <template v-if="isCreator">
                <v-spacer />
                <v-btn right :disabled="!isEditable" color="primary" @click="editPredictionDialog = true">編集</v-btn>
              </template>
              <template v-else-if="isOpen">
                <template v-if="isTrader">
                  <v-spacer />
                  <v-btn right outlined color="secondary" @click="cancelTrade">取消</v-btn>
                  <v-btn right color="primary" class="ml-5" @click="confirm">変更</v-btn>
                </template>
                <template v-else>
                  <v-spacer />
                  <v-btn right color="primary" @click="confirm">確認</v-btn>
                </template>
              </template>
            </template>
          </v-card-actions>
          <template v-if="isFinalized && (isTrader || isCreator || isReporter)">
            <v-row align="start">
              <template v-if="isTrader">
                <template v-if="rewardAmount > 0">
                  <v-col cols="12" lg="4" md="4" sm="6">
                    <v-chip large color="red darken-4" class="white--text px-10">
                      おめでとうございます！<br/>
                      あなたの予想が当たりました！
                    </v-chip>
                  </v-col>
                  <v-col cols="12" lg="8" md="8" sm="6">
                    <span class="ml-5 mr-3">獲得チップ：</span>
                    <strong class="red--text title">+{{ rewardAmount }}</strong>
                  </v-col>
                </template>
                <template v-else>
                  <v-col cols="12">
                    <v-chip large color="blue darken-4" class="white--text px-10">
                      残念！<br/>
                      あなたの予想は外れました。
                    </v-chip>
                  </v-col>
                </template>
              </template>
              <template v-else-if="isCreator">
                <v-col cols="12" lg="4" md="4" sm="6">
                  <v-chip large color="red darken-4" class="white--text px-10">
                    おめでとうございます！<br/>
                    あなたの予想が確定しました！
                  </v-chip>
                </v-col>
                <v-col cols="12" lg="8" md="8" sm="6">
                  <span class="ml-5 mr-3">作成者報酬：</span>
                  <strong class="red--text title">+{{ creatorRewardAmount }}</strong>
                  <template v-if="reporterRewardAmount > 0">
                    <span class="ml-5 mr-3">投票者報酬：</span>
                    <strong class="red--text title">+{{ reporterRewardAmount }}</strong>
                  </template>
                </v-col>
              </template>
              <template v-else-if="isReporter">
                <template v-if="reporterRewardAmount > 0">
                  <v-col cols="12" lg="4" md="4" sm="6">
                    <v-chip large color="red darken-4" class="white--text px-10">
                      おめでとうございます！<br/>
                      あなたの投票が採用されました！
                    </v-chip>
                  </v-col>
                  <v-col cols="12" lg="8" md="8" sm="6">
                    <span class="ml-5 mr-3">投票者報酬：</span>
                    <strong class="red--text title">+{{ reporterRewardAmount }}</strong>
                  </v-col>
                </template>
                <template v-else>
                  <v-col cols="12" lg="12" md="12" sm="6">
                    <v-chip large color="blue darken-4" class="white--text px-10">
                      残念！<br/>
                      あなたの投票は棄却されました。
                    </v-chip>
                  </v-col>
                </template>
              </template>
            </v-row>
          </template>
        </v-card-text>
      </v-card>
      <EditPrediction :prediction-doc-promise="predictionDocPromise" 
                      :choice-snap-promise="choiceSnapPromise"
                      :dialog="editPredictionDialog" 
                      v-on:close-dialog="closeDialog"/>
    </template>
  </div>
</template>

<style>
  .right-input input {
    text-align: right
  }
</style>

<script>
  import mixin from '@/util/mixin'
  import Comments from '@/components/Comments'
  import EditPrediction from '@/components/EditPrediction'
  import UserContextMenu from '@/components/UserContextMenu'

  export default {
    mixins: [mixin],
    components: {
      Comments,
      EditPrediction,
      UserContextMenu,
    },
    props: {
      predictionIdPromise: Promise,
      now: Date,
    },
    data: () => ({
      isNotFound: false,
      isOpen: false,
      isReporting: false,
      isSecondReporting: false,
      isFinalizing: false,
      isFinalized: false,
      isTrader: false,
      isCreator: false,
      isReporter: false,
      isEditable: false,
      isSelectable: false,
      isOpposing: false,
      predictionDocPromise: null,
      choiceSnapPromise: null,
      selected: [],
      items: [],
      rules: [v => (!v || v.length <= 3) || '使用できるのは999チップまでです。'],
      isGoodPrediction: false,
      isWatching: false,
      prediction: {
        predictionId: '',
        createdAt: null,
        updatedAt: null,
        creatorId: '',
        categoryId: '',
        categoryName: '',
        categoryIcon: '',
        predictionTitle: '',
        predictionBody: '',
        openAt: null,
        closeAt: null,
        reportingFrom: null,
        reportingTo: null,
        creatorRewardRate: 0,
        reporterRewardRate: 0,
        isPublished: false,
        answerCount: 0,
        commentCount: 0,
        isFinalized: false,
        isInvalid: false,
      },
      goodCount: '0',
      answerAmount: 0,
      rewardAmount: 0,
      creatorRewardAmount: 0,
      reporterRewardAmount: 0,
      creator: {
        avatarUrl: '',
        nickname: '',
      },
      snackbar: false,
      snackbarText: null,
      snackbarSuccess: false,
      snackbarSuccessText: null,
      dialog: false,
      selectedChoiceHeader: [
        { text: 'ラベル', value: 'label' },
        { text: '値', value: 'value' },
      ],
      selectedChoice: [],
      editPredictionDialog: false,
      oppositionDialog: false,
      requiredOppositionCount: 0,
      cancelDialog: false,
      db: null,
      eth: null,
      confirmSubtitle: '以下の内容で予想します。よろしいですか？',
      reportDialog: false,
    }),
    computed: {
      selectedNo () {
        return this.selected.length == 0 ? '' : this.selected[0].choice.no
      },
      selectedText () {
        return this.selected.length == 0 ? '' : this.selected[0].choice.text
      },
      ownedTip () {
        const formatter = new Intl.NumberFormat('ja-JP');
        const tokenBalance = formatter.format(this.$store.state.eth.tokenBalance)
        return `保有チップ：${tokenBalance}`
      },
    },
    watch: {
      predictionIdPromise () {
        if (this.predictionIdPromise) {
          this.init()
        }
      }
    },
    created () {
      this.db = this.$fb.firestore
      this.eth = {...this.$store.state.eth}
      if (this.$route.params.userId) {
        this.eth.eoa = this.$route.params.userId
      }
      if (this.predictionIdPromise) {
        this.init()
      }
    }, 
    methods: {
      reportUser () {},
      init () {
        this.predictionIdPromise
        .then(predictionId => {
          if (!predictionId) throw new Error('予想が見つかりませんでした。')
          this.predictionDocPromise = this.getPredictionDocPromise(predictionId)
          return this.predictionDocPromise
        }).then(predictionDoc => {
          this.prediction = predictionDoc.data()
          this.initCategory(this.prediction.categoryId)
          this.initFlags(this.prediction)
          this.initCreator(this.prediction.creatorId)
          return Promise.resolve(this.prediction.id) 
        }).then(predictionId => {
          this.initGoodForPrediction(predictionId)
          this.initWatchList(predictionId)
          this.choiceSnapPromise = this.getChoiceSnapPromise(predictionId)
          return Promise.all([Promise.resolve(predictionId), this.choiceSnapPromise])
        }).then(resultArray => {
          this.initItems(resultArray[1])
          this.initMyAnswer(resultArray[0])
          if (this.isReporting || this.isSecondReporting || this.isFinalized) {
            this.initMyReport(resultArray[0])
          }
        }).catch(error => {
          if (error && error.message !== 'Unable to retrieve coinbase') {
            this.isNotFound = true
          }
          console.error(error)
        })
      },
      getPredictionDocPromise (predictionId) {
        return this.$fb.getDoc(this.$fb.doc(this.db, 'prediction', predictionId)
          .withConverter(this.predictionConverter))
          .then(predictionDoc => {
            return Promise.resolve(predictionDoc)
          })
          .catch(error => {
            this.isNotFound = true
            return Promise.reject(error)
          })
      },
      initCategory (categoryId) {
        this.$store.dispatch('registerCategory').then(() => {
          const category = this.$store.state.category
          this.prediction.categoryName = category[categoryId].label
          this.prediction.categoryIcon = category[categoryId].icon
        })
      },
      initFlags (prediction) {
        if (prediction.creatorId == this.eth.eoa) {
          this.isCreator = true
          if (prediction.openAt > this.now) {
            this.isEditable = true
          }
          if (prediction.isFinalized) {
            this.creatorRewardAmount = prediction.creatorRewardAmount
          }
        }
        if (prediction.openAt <= this.now) {
          if (prediction.closeAt >= this.now) {
            this.isOpen = true
            if (!this.isCreator) {
              this.isSelectable = true
            }
          } else if (prediction.reportingFrom <= this.now) {
            if (prediction.reportingTo >= this.now) {
              this.isReporting = true
            } else if (!prediction.isFinalized) {
              this.isFinalizing = true
            } else {
              this.isFinalized = true
            }
            if (prediction.secondReportingFrom) {
              if (prediction.secondReportingFrom <= this.now) {
                if (prediction.secondReportingTo >= this.now) {
                  this.isSecondReporting = true
                }
              }
            }
          }
        }
      },
      initCreator (creatorId) {
        this.$fb.getDoc(this.$fb.doc(this.db, 'users', creatorId)
          .withConverter(this.userConverter))
          .then(usersDoc => {
            const creator = usersDoc.data()
            if (creator.avatarUrl) {
              const imageRef = this.$fb.ref(this.$fb.storage, creator.avatarUrl)
              return this.$fb.getDownloadURL(imageRef)
              .then(url => { 
                creator.avatarDownloadUrl = url 
                this.creator = creator
              })
            } else {
              creator.avatarDownloadUrl = require('@/assets/user_icon.png')
              this.creator = creator
            }
          })
      },
      initGoodForPrediction (predictionId) {
        this.$fb.getDocs(this.$fb.query(this.$fb.collection(this.db, 'good_for_prediction'),
          this.$fb.where('prediction_id', '==', predictionId),
          this.$fb.where('user_id', '==', this.eth.eoa)))
          .then(goodForPredictionSnap => {
            this.isGoodPrediction = !goodForPredictionSnap.empty
          })
          .catch(error => {
            console.log(error)
            this.snackbarText = '「いいね」の取得に失敗しました。'
            this.snackbar = true
          })
        this.$fb.getDocs(this.$fb.query(this.$fb.collection(this.db, 'good_for_prediction'),
          this.$fb.where('prediction_id', '==', predictionId)))
          .then(goodForPredictionSnap => {
            this.goodCount = String(goodForPredictionSnap.size)
          })
          .catch(error => {
            console.log(error)
            this.snackbarText = '「いいね」の取得に失敗しました。'
            this.snackbar = true
          })
      },
      initWatchList (predictionId) {
        this.$fb.getDocs(this.$fb.query(this.$fb.collection(this.db, 'watch_list'),
          this.$fb.where('user_id', '==', this.eth.eoa),
          this.$fb.where('prediction_id', '==', predictionId)))
          .then(watchListSnap => {
            watchListSnap.forEach(() => {
              this.isWatching = true
            })
          })
          .catch(error => {
            console.log(error)
            this.snackbarText = 'ウォッチリストの取得に失敗しました。'
            this.snackbar = true
          })
      },
      getChoiceSnapPromise (predictionId) {
        const predictionRef = this.$fb.collection(this.db, 'prediction')
        const choiceRef = this.$fb.collection(predictionRef, predictionId, 'choice').withConverter(this.choiceConverter)
        return this.$fb.getDocs(choiceRef)
          .then(choiceSnap => {
            return Promise.resolve(choiceSnap)
          })
          .catch(error => {
            this.snackbarText = '選択肢の取得に失敗しました。'
            this.snackbar = true
            return Promise.reject(error)
          })
      },
      initItems (choiceSnap) {
        const items = []
        choiceSnap.forEach(choiceDoc => {
          const data = choiceDoc.data()
          const item = {
            isSelectable: true,
            choice: { no: data.id, text: data.choice},
            answerCount: data.answerCount ? data.answerCount : 0,
            odds: data.odds ? data.odds : 0,
            reportCount: data.reportCount ? data.reportCount : 0,
          }
          if (this.isOpen || this.isReporting || this.isSecondReporting || this.isFinalized) {
            item.selected = false
          }
          if (this.isSecondReporting) {
            item.reportCount = data.secondReportCount ? data.secondReportCount : 0
          }
          items.push(item)
        })
        this.items = items
      },
      initMyReport (predictionId) {
        let reportPromise = null
        if (this.isFinalized) {
          const reportRef = this.$fb.collection(this.db, 'report').withConverter(this.reportConverter)
          reportPromise = this.$fb.getDocs(this.$fb.query(reportRef,
            this.$fb.where('is_finalized', '==', true),
            this.$fb.where('reporter_id', '==', this.eth.eoa),
            this.$fb.where('prediction_id', '==', predictionId)))
        } else {
          let reportType = null
          if (this.isReporting) {
            reportType = this.reportType.reporting
          } else {
            reportType = this.reportType.secondReporting
          } 
          const reportRef = this.$fb.collection(this.db, 'report').withConverter(this.reportConverter)
          reportPromise = this.$fb.getDocs(this.$fb.query(reportRef,
            this.$fb.where('report_type', '==', reportType),
            this.$fb.where('reporter_id', '==', this.eth.eoa),
            this.$fb.where('prediction_id', '==', predictionId)))
        }
        reportPromise.then(reportSnap => {
          reportSnap.forEach((reportDoc) => {
            let data = reportDoc.data()
            this.items.forEach(item => {
              if (item.choice.no == data.choiceNo) {
                this.selected = [item]
              }
            })
            this.isReporter = true
            if (this.isFinalized) {
              this.reporterRewardAmount = data.rewardAmount
            }
          })
        })
      },
      initMyAnswer(predictionId) {
        this.$fb.getDocs(this.$fb.query(this.$fb.collection(this.db, 'answer')
          .withConverter(this.answerConverter),
          this.$fb.where('user_id', '==', this.eth.eoa),
          this.$fb.where('prediction_id', '==', predictionId)))
          .then(answerSnap => {
            answerSnap.forEach((answerDoc) => {
              let data = answerDoc.data()
              this.items.forEach(item => {
                if (item.choice.no == data.choiceNo) {
                  this.selected = [item]
                }
              })
              this.answerAmount = String(data.answerAmount)
              this.isTrader = true
              if (this.isFinalized) {
                this.rewardAmount = data.rewardAmount
              }
            })
            if (!this.isTrader && (this.isReporting || this.isSecondReporting)) {
              this.isSelectable = true
            }
          })
      },
      push (path) {
        this.$router.push({ path: path });
      },
      confirm () {
        if (this.selected.length == 0) {
          this.snackbarText = '予想を選択してください。'
          this.snackbar = !this.snackbar
        } else if (this.answerAmount == 0) {
          this.snackbarText = 'チップを使用してください。'
          this.snackbar = !this.snackbar
        } else {
          this.selectedChoice = [
            { label: '選択した予想', value: this.selected[0].choice.no + '. ' + this.selected[0].choice.text },
            { label: 'オッズ', value: this.selected[0].odds + '倍' },
            { label: '使用チップ', value: this.answerAmount + 'TIP' },
          ]
          this.confirmSubtitle = '以下の内容で予想します。よろしいですか？'
          this.dialog = !this.dialog
        }
      },
      confirmReport () {
        if (this.selected.length == 0) {
          this.snackbarText = '結果を選択してください。'
          this.snackbar = !this.snackbar
        } else {
          const selectedChoice = this.selected[0].choice.no + '. ' + this.selected[0].choice.text
          if (this.answerChoice === selectedChoice) {
            this.snackbarText = '予想と同じ選択肢は投票できません。'
            this.snackbar = !this.snackbar
          } else {
            this.selectedChoice = [
              { label: '選択した結果', value: selectedChoice },
            ]
            this.confirmSubtitle = '以下の内容で投票します。よろしいですか？'
            this.dialog = !this.dialog
          }
        }
      },
      cancelReport () {
        this.selectedChoice = [
          { label: '選択した結果', value: this.selected[0].choice.no + '. ' + this.selected[0].choice.text },
        ]
        this.cancelDialog = true
      },
      cancelTrade () {
        this.selectedChoice = [
          { label: '選択した予想', value: this.selected[0].choice.no + '. ' + this.selected[0].choice.text },
        ]
        this.cancelDialog = true
      },
      submit () {
        if (this.isOpen) {
          if (this.isTrader) {
            this.changeAnswer()
          } else {
            this.submitAnswer()
          }
        } else if (this.isReporting || this.isSecondReporting) {
          this.submitReport()
        }
      },
      changeAnswer () {
        const batch = this.$fb.writeBatch(this.db)
        this.$fb.getDocs(this.$fb.query(this.$fb.collection(this.db, 'answer'),
          this.$fb.where('user_id', '==', this.eth.eoa),
          this.$fb.where('prediction_id', '==', this.prediction.id)))
          .then(answerSnap => {
            answerSnap.forEach(answerDoc => {
              batch.delete(answerDoc.ref)
            })
            return this.$fb.addDoc(this.$fb.collection(this.db, 'answer'), {
              user_id: this.eth.eoa              
            })
          }).then(docRef => {
            batch.set(docRef, {
              answer_at: new Date(),
              user_id: this.eth.eoa,
              prediction_id: this.prediction.id,
              category_id: this.prediction.categoryId,
              close_at: this.prediction.closeAt,
              choice_no: this.selected[0].choice.no,
              answer_amount: this.answerAmount,
              is_finalized: false,
            })
            return batch.commit()
          }).then(() => {
            this.dialog = false
            this.snackbarSuccessText = '予想の登録に成功しました。'
            this.snackbarSuccess = true
          }).catch(error => {
            console.log(error)
            this.snackbarText = '予想の登録に失敗しました。'
            this.snackbar = true
          })
      },
      submitAnswer () {
        let token = window.token
        token.setProvider(window.web3.currentProvider)
        token.autoGas = false
        token.defaults({
          from: this.eth.eoa,
          gasLimit: 750000,
        })
        const batch = this.$fb.writeBatch(this.db)
        token.deployed().then(tokenInstance => {
          return tokenInstance.transfer('0x9da5D673091576A7Cfd7fD5204b28d2DC8378929', this.answerAmount)
        }).then(() => {
          this.$store.dispatch('updateBalance', this.eth.eoa)
          return this.$fb.getDocs(this.$fb.query(this.$fb.collection(this.db, 'answer'),
            this.$fb.where('user_id', '==', this.eth.eoa),
            this.$fb.where('prediction_id', '==', this.prediction.id)))
        }).then(answerSnap => {
          answerSnap.forEach(answerDoc => {
            batch.delete(answerDoc.ref)
          })
          return this.$fb.addDoc(this.$fb.collection(this.db, 'answer'), {
            user_id: this.eth.eoa              
          })
        }).then(docRef => {
          batch.set(docRef, {
            answer_at: new Date(),
            user_id: this.eth.eoa,
            prediction_id: this.prediction.id,
            category_id: this.prediction.categoryId,
            close_at: this.prediction.closeAt,
            choice_no: this.selected[0].choice.no,
            answer_amount: this.answerAmount,
            is_finalized: false,
          })
          return batch.commit()
        }).then(() => {
          this.snackbarSuccessText = '予想の登録に成功しました。'
          this.snackbarSuccess = true
        }).catch(error => {
          console.log(error)
          this.snackbarText = '予想の登録に失敗しました。'
          this.snackbar = true
        })
        // マイニングに時間がかかるため、先にダイアログを閉じる
        this.dialog = false
      },
      submitReport () {
        let reportType = null
        if (this.isReporting) {
          reportType = this.reportType.reporting
        } else {
          reportType = this.reportType.secondReporting
        } 
        const batch = this.$fb.writeBatch(this.db)
        this.$fb.getDocs(this.$fb.query(this.$fb.collection(this.db, 'report'),
          this.$fb.where('report_type', '==', reportType),
          this.$fb.where('reporter_id', '==', this.eth.eoa),
          this.$fb.where('prediction_id', '==', this.prediction.id)))
          .then(reportSnap => {
            reportSnap.forEach(reportDoc => {
              batch.delete(reportDoc.ref)
            })
            return this.$fb.addDoc(this.$fb.collection(this.db, 'report'), {
              reporter_id: this.eth.eoa
            })
          }).then(reportDoc => {
            batch.set(reportDoc, {
              report_type: reportType,
              report_at: new Date(),
              reporter_id: this.eth.eoa,
              prediction_id: this.prediction.id,
              category_id: this.prediction.categoryId,
              reporting_to: this.prediction.reportingTo,
              choice_no: this.selected[0].choice.no,
              is_finalized: false,
            })
            return batch.commit()
          }).then(() => {
            this.dialog = false
            this.snackbarSuccessText = '結果の登録に成功しました。'
            this.snackbarSuccess = true
          }).catch(error => {
            console.log(error)
            this.snackbarText = '結果の登録に失敗しました。'
            this.snackbar = true
          })
      },
      submitCancel () {
        const reportType = this.isReporting ? this.reportType.reporting : this.reportType.secondReporting
        this.$fb.getDocs(this.$fb.query(this.$fb.collection(this.db, 'report'),
        this.$fb.where('report_type', '==', reportType),
        this.$fb.where('reporter_id', '==', this.eth.eoa),
        this.$fb.where('prediction_id', '==', this.prediction.id)))
        .then(reportSnap => {
          reportSnap.forEach(reportDoc => {
            this.$fb.deleteDoc(reportDoc.ref)
            .then(() => {
              this.cancelDialog = false
              this.snackbarSuccessText = '予想の取り消しに成功しました。'
              this.snackbarSuccess = true
            })
            .catch(error => {
              console.log(error)
              this.snackbarText = '予想の取り消しに失敗しました。'
              this.snackbar = true
            })
          })
        })
      },
      goodForPredictionClicked () {
        this.predictionIdPromise.then((predictionId) => {
          if (this.isGoodPrediction) {
            this.deleteGoodForPrediction(predictionId)
          } else {
            this.addGoodForPrediction(predictionId)
          }
        })
      },
      deleteGoodForPrediction (predictionId) {
        this.$fb.getDocs(this.$fb.query(this.$fb.collection(this.db, 'good_for_prediction'),
          this.$fb.where('prediction_id', '==', predictionId),
          this.$fb.where('user_id', '==', this.eth.eoa)))
          .then(goodForPredictionSnap => {
            goodForPredictionSnap.forEach(goodForPredictionDoc => {
              this.$fb.deleteDoc(goodForPredictionDoc.ref)
              .then(() => {
                this.isGoodPrediction = false
                this.goodCount = String(parseInt(this.goodCount, 10) - 1)
              })
              .catch(error => {
                console.log(error)
                this.snackbarText = '「いいね」の削除に失敗しました。'
                this.snackbar = true
              })
            })
          })
      },
      addGoodForPrediction (predictionId) {
        this.$fb.addDoc(this.$fb.collection(this.db, 'good_for_prediction'), {
          prediction_id: predictionId,
          user_id: this.eth.eoa,
        })
        .then(() => {
          this.isGoodPrediction = true
          this.goodCount = String(parseInt(this.goodCount, 10) + 1)
        })
        .catch(error => {
          console.log(error)
          this.snackbarText = '「いいね」の登録に失敗しました。'
          this.snackbar = true
        })
      },
      watchListClicked () {
        this.predictionIdPromise.then((predictionId) => {
          if (this.isWatching) {
            this.deleteWatchList(predictionId)
          } else {
            this.addWatchList(predictionId)
          }
        })
      },
      deleteWatchList (predictionId) {
        this.$fb.getDocs(this.$fb.query(this.$fb.collection(this.db, 'watch_list'),
        this.$fb.where('user_id', '==', this.eth.eoa),
        this.$fb.where('prediction_id', '==', predictionId)))
        .then(watchListSnap => {
          watchListSnap.forEach(watchListDoc => {
            this.$fb.deleteDoc(watchListDoc.ref)
            .then(() => {
              this.isWatching = false
            })
            .catch(error => {
              console.log(error)
              this.snackbarText = 'ウォッチリストの削除に失敗しました。'
              this.snackbar = true
            })
          })
        })
      },
      addWatchList (predictionId) {
        this.$fb.addDoc(this.$fb.collection(this.db, 'watch_list'), {
          user_id: this.eth.eoa,
          prediction_id: predictionId,
        })
        .then(() => {
          this.isWatching = true
        })
        .catch(error => {
          console.log(error)
          this.snackbarText = 'ウォッチリストの登録に失敗しました。'
          this.snackbar = true
        })
      },
      closeDialog () {
        this.editPredictionDialog = false
      },
      showOppositionDialog () {
        if (this.isReporter) {
          this.predictionIdPromise.then((predictionId) => {
            this.$fb.getDocs(this.$fb.query(this.$fb.collection(this.db, 'report'),
            this.$fb.where('report_type', '==', this.reportType.reporting),
            this.$fb.where('prediction_id', '==', predictionId)))
            .then(reportSnap => {
              let oppositionCount = 0
              reportSnap.forEach(reportDoc => {
                if (reportDoc.data().isOpposing) {
                  oppositionCount = oppositionCount + 1
                }
              })
              this.requiredOppositionCount = Math.ceil(this.prediction.answerCount / 10) - oppositionCount
              this.oppositionDialog = true
            })
          })
        } else {
          this.snackbarText = '結果投票をせずに異議申し立てすることはできません。'
          this.snackbar = true
        }
      },
      submitOpposition () {
        this.predictionIdPromise.then((predictionId) => {
          this.$fb.getDocs(this.$fb.query(this.$fb.collection(this.db, 'report'),
          this.$fb.where('report_type', '==', this.reportType.reporting),
          this.$fb.where('reporter_id', '==', this.eth.eoa),
          this.$fb.where('prediction_id', '==', predictionId)))
          .then(reportSnap => {
            reportSnap.forEach(reportDoc => {
              this.$fb.updateDoc(reportDoc.ref, {
                is_opposed: true,
              })
              .then(() => {
                this.oppositionDialog = false
                this.snackbarSuccessText = '異議申し立てを受け付けました。'
                this.snackbarSuccess = true
              })
              .catch(error => {
                console.log(error)
                this.snackbarText = '異議申し立てできませんでした。しばらく時間を空けてからもう一度お試しください。'
                this.snackbar = true
              })
            })
          })
        })
      },
      toggleRow (item) {
        if (this.isSelectable) {
          if (this.selected.includes(item)) {
            // item が含まれていたら除去する
            this.selected = this.selected.filter(v => v !== item)
          } else {
            // item を追加する
            this.selected = []
            this.selected.push(item)
          }
        }
      },
      tableRowClass (item, isSelected) {
        let klasses = []
        if (item.choice.no === this.prediction.correctChoiceNo) klasses.push('accent') // status が active だったら tr に grey クラスを追加する
        if (isSelected) klasses.push('v-data-table__selected') // checkbox が選択されていたら tr に v-data-table__selected クラスを追加する
        return klasses.join(' ')
      }
    },
  }
</script>
<style scoped>
  .prediction-body >>> p {
    margin-bottom: 0;
  }
</style>
