<template>
  <v-dialog v-model="dialog" max-width="600" persistent>
    <v-snackbar v-model="snackbar" color="error">
      {{ snackbarText }}
      <v-btn text @click="snackbar = false">
        <v-icon>mdi-close-circle-outline</v-icon>
      </v-btn>
    </v-snackbar>
    <v-card class="rounded-xl">
      <div class="text-right pt-3 pr-3">
        <v-btn icon @click="$emit('close-dialog')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-title class="justify-center">
        ウォレットを接続してサインインしてください
      </v-card-title>
      <v-card-subtitle class="my-1 text-center">
        まだウォレットをお持ちでない方は<a href="#" class="text-decoration-none" @click.prevent="move">こちら</a>
      </v-card-subtitle>
      <v-card-text>
        <v-container style="max-width: 400px;">
          <v-btn class="mb-8" x-large block rounded @click="metaMaskLogin">
            MetaMaskで接続
          </v-btn>
          <v-btn class="mb-8" x-large block rounded @click="fortmaticLogin">
            Fortmaticで接続
          </v-btn>
          <v-btn class="mb-8" x-large block rounded @click="walletConnectLogin">
            WalletConnectで接続
          </v-btn>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import Fortmatic from 'fortmatic'
  import WalletConnect from '@walletconnect/client'
  import QRCodeModal from '@walletconnect/qrcode-modal'
  import WalletConnectProvider from '@walletconnect/web3-provider'
  import Web3 from 'web3'
  import mixin from '@/util/mixin'

  export default {
    mixins: [mixin],
    props: {
      dialog: Boolean,
    },
    data: () => ({
      snackbar: false,
      snackbarText: '',
    }),
    methods: {
      move () {
        this.$emit('close-dialog')
        this.$router.push({ path: '/help' })
      },
      metaMaskLogin () {
        const self = this
        if (window.ethereum && window.ethereum.isMetaMask) {
          return window.ethereum.request({ method: 'eth_accounts' })
            .then((accounts) => {
              if (accounts.length == 0) {
                return window.ethereum.request({ method: 'eth_requestAccounts'})
                  .then((requestAccounts) => {
                    if (requestAccounts.length == 0) {
                      self.snackbarText = 'MetaMaskにログインしてください。'
                      self.snackbar = !self.snackbar
                      console.log('MetaMaskログインエラー')
                    } else {
                      if (window.ethereum.chainId == 0xaa36a7) {
                        self.login(requestAccounts[0])
                      } else {
                        window.ethereum.on('chainChanged', (_chainId) => {
                          if (_chainId == 0xaa36a7) {
                            self.login(requestAccounts[0])
                          } else {
                            self.logout()
                          }
                        })
                        console.log(window.ethereum.chainId)
                        self.snackbarText = 'Sepoliaテストネットに接続していません。ネットワークを切り替えてください。'
                        self.snackbar = !self.snackbar
                      }
                    }
                  }).catch((error) => {
                    console.log(error)
                  })
              } else {
                window.ethereum.on('accountsChanged', (changedAccounts) => {
                  if (changedAccounts.length == 0 || changedAccounts[0] != self.$store.state.eth.eoa) {
                    self.logout()
                  } else {
                    self.login(changedAccounts[0])
                  }
                })
                if (window.ethereum.chainId == 0xaa36a7) {
                  window.ethereum.on('chainChanged', (_chainId) => {
                    if (_chainId == 0xaa36a7) {
                      self.login(accounts[0])
                    } else {
                      self.logout()
                    }
                  })
                  self.login(accounts[0])
                } else {
                  window.ethereum.on('chainChanged', (_chainId) => {
                    if (_chainId == 0xaa36a7) {
                      self.login(accounts[0])
                    } else {
                      self.logout()
                    }
                  })
                  console.log(window.ethereum.chainId)
                  self.snackbarText = 'Sepoliaテストネットに接続していません。ネットワークを切り替えてください。'
                  self.snackbar = !self.snackbar
                }
              }
            }).catch((error) => {
              console.log(error)
            })
        } else {
          self.snackbarText = 'MetaMaskがインストールされていません。MetaMaskをインストールしてください。'
          self.snackbar = !self.snackbar
          console.log('MetaMask未インストール')
        }
      },
      fortmaticLogin () {
        const fm = new Fortmatic('pk_test_80A024EED2ABD1BA', 'ropsten');
        window.web3 = new Web3(fm.getProvider());
        if (fm.user.isLoggedIn()) {
          window.web3.eth.getAccounts()
          .then(accounts => {
            this.login(accounts[0], 'fortmatic')
          })
        } else {
          fm.user.login().then(() => {
            window.web3.eth.getAccounts()
            .then(accounts => {
              this.login(accounts[0], 'fortmatic')
            })
          });
        }
      },
      walletConnectLogin () {
        const provider = new WalletConnectProvider({
          infuraId: '24d82d84987243c5ada6ab58d75acd4b'
        })
        provider.enable().then(() => {
          window.web3 = new Web3(provider)
          
          const connector = new WalletConnect({
            bridge: 'https://bridge.walletconnect.org',
            qrcodeModal: QRCodeModal,
          })

          if (connector.connected) {
            this.login(connector.accounts[0], 'walletconnect')
          } else {
            connector.createSession()
          }

          connector.on('connect', (error, payload) => {
            if (!error) {
              const { accounts, chainId } = payload.params[0]
              if (chainId == 0x3) {
                this.login(accounts[0], 'walletconnect')
              }
            }
          })

          connector.on('disconnect', () => {
            this.logout()
          })
        })
      },
      login (eoa, wallet = 'metamask') {
        this.$emit('close-dialog')
        this.$store.commit('doRegisterWallet', wallet)
        this.$store.dispatch('registerUser', eoa.toLowerCase()).then(() => {
          this.$router.push({ path: '/open-prediction' })
        })
      },
      logout () {
        this.$router.push({ path: '/' }).catch(() => {})
      }
    },
  }
</script>
