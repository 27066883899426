<template>
  <div>
    <v-snackbar color="success" v-model="snackbarSuccess">
      <v-list-item-title>
        <v-icon class="mr-2">mdi-check-circle-outline</v-icon>
        {{ snackbarSuccessText }}
      </v-list-item-title>
    </v-snackbar>
    <v-snackbar v-model="snackbar">
      {{ snackbarText }}
      <v-btn text @click="snackbar = false">
        <v-icon>mdi-close-circle-outline</v-icon>
      </v-btn>
    </v-snackbar>
    <v-dialog v-model="deleteDialog" width="300">
      <v-card>
        <v-card-title>コメントの削除</v-card-title>
        <v-card-text>コメントを完全に削除しますか？</v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text color="indigo" @click="deleteDialog=false">キャンセル</v-btn>
          <v-btn text color="indigo" @click="deleteComment">削除</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="reportDialog" width="400">
      <v-card>
        <v-card-title>コメントの報告</v-card-title>
        <v-card-text>不適切なコメントとして報告しますか？</v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text color="indigo" @click="reportDialog=false">キャンセル</v-btn>
          <v-btn text color="indigo" @click="reportComment">報告</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-list three-line dense>
      <v-list-item dense>
        <v-list-item-title class="text-h6">
          {{comments.length}}件のコメント
        </v-list-item-title>
      </v-list-item>
      <v-list-item>
        <v-list-item-avatar size="30">
          <v-img :src="$store.state.user.avatarDownloadUrl"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-textarea auto-grow rows="1" v-model="newComment" @click="editComment=true"></v-textarea>
          <v-container v-show="editComment">
            <v-row>
              <v-menu offset-y>
                <template v-slot:activator="{on, attrs}">
                  <v-btn text icon v-bind="attrs" v-on="on"><v-icon>mdi-emoticon-excited-outline</v-icon></v-btn>
                </template>
                <div class="emojiRow justify-start">
                  <Picker :data="emojiIndex" set="twitter" @select="showEmoji" />
                </div>
              </v-menu>
              <v-spacer />
              <v-btn small outlined class="mr-4" @click="cancel">キャンセル</v-btn>
              <v-btn small color="primary" @click="add">コメント</v-btn>
            </v-row>
          </v-container>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list three-line max-height="400" class="overflow-auto">
      <template v-for="comment in comments">
        <v-hover v-slot="{ hover }" v-bind:key="comment.id">
          <v-list-item class="align-start">
            <v-list-item-avatar size="30">
              <v-img class="align-start" :src="comment.avatarUrl"></v-img>
            </v-list-item-avatar>
            <template v-if="comment.isEdit">
              <v-list-item-content>
                <v-textarea auto-grow rows="1" v-model="comment.newContent"></v-textarea>
                <v-container>
                  <v-row>
                    <v-menu offset-y>
                      <template v-slot:activator="{on, attrs}">
                        <v-btn text icon v-bind="attrs" v-on="on"><v-icon>mdi-emoticon-excited-outline</v-icon></v-btn>
                      </template>
                      <div class="emojiRow justify-start">
                        <Picker :data="emojiIndex" set="twitter" @select="showEmojiEdit($event, comment)" />
                      </div>
                    </v-menu>
                    <v-spacer />
                    <v-btn small outlined class="mr-4" @click="cancelEdit(comment)">キャンセル</v-btn>
                    <v-btn small color="primary" @click="update(comment)">保存</v-btn>
                  </v-row>
                </v-container>
              </v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-content>
                <v-list-item-subtitle>
                  <span class="font-weight-bold">{{comment.nickname}}</span>
                  <span class="ml-2 text-caption">{{comment.dispTime}}</span>
                </v-list-item-subtitle>
                {{comment.content}}
                <v-list-item-subtitle>
                  <v-btn text icon color="grey" @click="like(comment)">
                    <template v-if="comment.like && comment.like.indexOf(eth.eoa) > -1">
                      <v-icon small color="indigo">mdi-thumb-up</v-icon>
                    </template>
                    <template v-else>
                      <v-icon size="18">mdi-thumb-up-outline</v-icon>
                    </template>
                  </v-btn>
                  <template v-if="comment.like && comment.like.length > 0">
                    {{comment.like.length}}
                  </template>
                  <v-btn text><span class="text-caption" @click="comment.editReply=true">返信</span></v-btn>
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="comment.editReply">
                  <v-list three-line dense>
                    <v-list-item>
                      <v-list-item-avatar size="30">
                        <v-img :src="loginUser.avatarUrl"></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-textarea auto-grow rows="1" v-model="comment.newReply"></v-textarea>
                        <v-container>
                          <v-row>
                            <v-menu offset-y>
                              <template v-slot:activator="{on, attrs}">
                                <v-btn text icon v-bind="attrs" v-on="on"><v-icon>mdi-emoticon-excited-outline</v-icon></v-btn>
                              </template>
                              <div class="emojiRow justify-start">
                                <Picker :data="emojiIndex" set="twitter" @select="showEmojiReply($event, comment)" />
                              </div>
                            </v-menu>
                            <v-spacer />
                            <v-btn small outlined class="mr-4" @click="cancelReply(comment)">キャンセル</v-btn>
                            <v-btn small color="primary" @click="addReply(comment)">返信</v-btn>
                          </v-row>
                        </v-container>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="comment.replyCount && comment.replyCount > 0">
                  <Reply
                    :prediction-id-promise="predictionIdPromise"
                    :now="now"
                    :parent-comment="comment"
                  />
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-menu offset-y>
                  <template v-slot:activator="{on, attrs}">
                    <v-btn text icon v-show="hover" v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <template v-if="comment.userId===eth.eoa">
                      <v-list-item>
                        <v-list-item-action-text>
                          <v-btn text @click="edit(comment)"><v-icon class="mr-2">mdi-comment-edit-outline</v-icon>編集</v-btn>
                        </v-list-item-action-text>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-action-text>
                          <v-btn text @click="showDeleteDialog(comment)"><v-icon class="mr-2">mdi-comment-remove-outline</v-icon>削除</v-btn>
                        </v-list-item-action-text>
                      </v-list-item>
                    </template>
                    <template v-else>
                      <v-list-item>
                        <v-list-item-action-text>
                          <v-btn text @click="showReportDialog(comment)"><v-icon class="mr-2">mdi-flag-outline</v-icon>報告</v-btn>
                        </v-list-item-action-text>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
              </v-list-item-action>
            </template>
          </v-list-item>
        </v-hover>
      </template>
    </v-list>
  </div>
</template>

<script>
  import mixin from '@/util/mixin'
  import data from 'emoji-mart-vue-fast/data/all.json'
  import 'emoji-mart-vue-fast/css/emoji-mart.css'
  import {Picker, EmojiIndex} from 'emoji-mart-vue-fast'
  import Reply from '@/components/Reply'

  let emojiIndex = new EmojiIndex(data)

  export default {
    mixins: [mixin],
    components: {
      Picker,
      Reply,
    },
    props: {
      predictionIdPromise: Promise,
      now: Date,
    },
    data: () => ({
      componentKey: 0,
      snackbar: false,
      snackbarText: null,
      snackbarSuccess: false,
      snackbarSuccessText: null,
      isLiked: false,
      editComment: false,
      newComment: '',
      emojiIndex: emojiIndex,
      isGoodPrediction: false,
      loginUser: {
        avatarUrl: 'https://ca.slack-edge.com/T5654U2GP-U56MK2UQM-e6a64af42f4c-512',
      },
      comments: [],
      db: null,
      eth: null,
      defaultAvatar: require('@/assets/user_icon.png'),
      deleteDialog: false,
      deleteReplyDialog: false,
      reportDialog: false,
      reportReplyDialog: false,
      selectedComment: null,
      selectedReply: null,
    }),
    created () {
      this.db = this.$fb.firestore
      this.eth = this.$store.state.eth
      if (this.predictionIdPromise) {
        this.init()
      }
    }, 
    methods: {
      deleteComment () {
        this.deleteDialog = false
        this.predictionIdPromise
        .then(predictionId => {
          const predictionRef = this.$fb.collection(this.db, 'prediction')
          const commentsRef = this.$fb.doc(predictionRef, predictionId, 'comments', this.selectedComment.id)
          this.$fb.deleteDoc(commentsRef)
          .then(() => {
            this.snackbarSuccessText = '削除しました。'
            this.snackbarSuccess = true
            this.init()
          })
        })
      },
      reportComment () {
        this.reportDialog = false
        this.predictionIdPromise
        .then(predictionId => {
          const predictionRef = this.$fb.collection(this.db, 'prediction')
          const commentsRef = this.$fb.doc(predictionRef, predictionId, 'comments', this.selectedComment.id)
          const bad = this.selectedComment.bad ? this.selectedComment.bad : []
          bad.push(this.eth.eoa)
          this.$fb.updateDoc(commentsRef, {
            bad: bad,
          }).then(() => {
            this.snackbarSuccessText = '報告しました。'
            this.snackbarSuccess = true
          })
        })
      },
      showDeleteDialog (comment) {
        this.selectedComment = comment
        this.deleteDialog = true
      },
      showReportDialog (comment) {
        this.selectedComment = comment
        this.reportDialog = true
      },
      edit (comment) {
        comment.newContent = comment.content
        comment.isEdit = true
      },
      like (comment) {
        this.predictionIdPromise
        .then(predictionId => {
          const predictionRef = this.$fb.collection(this.db, 'prediction')
          const commentsRef = this.$fb.doc(predictionRef, predictionId, 'comments', comment.id)
          let like = comment.like ? comment.like : []
          if (like.indexOf(this.eth.eoa) > -1) {
            like.splice(like.indexOf(this.eth.eoa), 1)
          } else {
            like.push(this.eth.eoa)
          }
          this.$fb.updateDoc(commentsRef, {
            like: like
          }).then(() => {
            comment.like = like
          })
        })
      },
      showEmoji (emoji) {
        this.newComment += emoji.native
      },
      showEmojiEdit (emoji, comment) {
        comment.newContent += emoji.native
      },
      showEmojiReply (emoji, comment) {
        comment.newReply += emoji.native
      },
      cancelEdit (comment) {
        comment.isEdit = false
        this.componentKey++
      },
      cancelReply (comment) {
        comment.editReply = false
      },
      addReply (comment) {
        this.predictionIdPromise
        .then(predictionId => {
          const predictionRef = this.$fb.collection(this.db, 'prediction')
          const commentRef = this.$fb.doc(predictionRef, predictionId, 'comments', comment.id)
          const replyRef = this.$fb.collection(predictionRef, predictionId, 'comments', comment.id, 'reply')
          const now = new Date()
          const commentPromise = this.$fb.updateDoc(commentRef, {
            reply_count: comment.replyCount ? comment.replyCount + 1 : 1
          })
          const replyPromise = this.$fb.addDoc(replyRef, {
            content: comment.newReply,
            user_id: this.eth.eoa,
            created_at: now,
            updated_at: now,
          })
          return Promise.all([commentPromise, replyPromise])
        }).then(() => {
          this.init()
        })
      },
      init () {
        this.predictionIdPromise
        .then(predictionId => {
          if (!predictionId) throw new Error('予想が見つかりませんでした。')
          return this.getCommentsSnapPromise(predictionId)
        }).then(commentsSnapshot => {
          const commentList = []
          commentsSnapshot.forEach(commentsDoc => {
            commentList.push(commentsDoc.data())
          })
          commentList.sort((a, b) => {
            return b.createdAt.getTime() - a.createdAt.getTime()
          })
          this.setComments(commentList)
        }).catch(error => {
          console.error(error)
        })
      },
      getCommentsSnapPromise (predictionId) {
        const predictionRef = this.$fb.collection(this.db, 'prediction')
        const commentsRef = this.$fb.collection(predictionRef, predictionId, 'comments').withConverter(this.commentsConverter)
        return this.$fb.getDocs(commentsRef)
          .then(commentsSnap => {
            return Promise.resolve(commentsSnap)
          })
          .catch(error => {
            this.snackbarText = 'コメントの取得に失敗しました。'
            this.snackbar = true
            return Promise.reject(error)
          })
      },
      async setComments (commentsList) {
        const comments = []
        for (const commentsData of commentsList) {
          const usersDoc = await this.$fb.getDoc(this.$fb.doc(this.db, 'users', commentsData.userId).withConverter(this.userConverter))
          const usersData = usersDoc.data()
          let avatarUrl = this.defaultAvatar
          if (usersData.avatarUrl) {
            const imageRef = this.$fb.ref(this.$fb.storage, usersData.avatarUrl)
            const url = await this.$fb.getDownloadURL(imageRef)
            avatarUrl = url 
          }
          comments.push({
            id: commentsData.id,
            avatarUrl: avatarUrl,
            userId: commentsData.userId,
            nickname: usersData.nickname,
            dispTime: this.getPassed(commentsData.createdAt),
            content: commentsData.content,
            like: commentsData.like,
            editReply: false,
            newReply: '',
            replyCount: commentsData.replyCount,
            reply: false,
            isEdit: false,
            newContent: '',
          })            
        }
        this.comments = comments
      },
      add () {
        this.predictionIdPromise
        .then(predictionId => {
          const predictionRef = this.$fb.collection(this.db, 'prediction')
          const commentsRef = this.$fb.collection(predictionRef, predictionId, 'comments')
          const now = new Date()
          this.$fb.addDoc(commentsRef, {
            content: this.newComment,
            user_id: this.eth.eoa,
            created_at: now,
            updated_at: now,
            reply_count: 0,
          }).then(() => {
            this.newComment = ''
            this.init()
            this.cancel()
          })
        })
      },
      update (comment) {
        this.predictionIdPromise
        .then(predictionId => {
          const predictionRef = this.$fb.collection(this.db, 'prediction')
          const commentsRef = this.$fb.doc(predictionRef, predictionId, 'comments', comment.id)
          const now = new Date()
          this.$fb.updateDoc(commentsRef, {
            content: comment.newContent,
            updated_at: now,
          }).then(() => {
            comment.newContent = ''
            this.init()
            this.cancelEdit(comment)
          })
        })
      },
      cancel () {
        this.picker = false
        this.editComment = false
      },
    }
  }
</script>
<style scoped>
  .emojiRow {
    display: flex;
  }
</style>